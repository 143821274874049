function SpecialFeature() {
  function onDownloadApp() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.learnfromnative.apps",
      "_blank"
    );
  }

  const specialFeaturesItems = [
    {
      featureName: "feature-1",
      featureHeading: "AI Homework Help",
      featureHeadingBackgroundColor: "#117800",
      featureContetBackgroundColor: "#E8FFE4",
      featureImage: "/assest/images/specialFeature-1.png",
      redirectURL: "#",
      featureContent:
        "Instantly scan, upload, and receive step-by-step solutions to homework problems, powered by advanced AI technology for quick and accurate assistance.",
    },
    {
      featureName: "feature-2",
      featureHeading: "AI Homework Help",
      featureHeadingBackgroundColor: "#FF8B00",
      featureContetBackgroundColor: "#FFECD4",
      featureImage: "/assest/images/specialFeature-2.png",
      redirectURL: "#",
      featureContent:
        "Practice unlimited math questions with our AI-powered assistant, tailored to your skill level and providing personalized feedback to help you become proficient in math.",
    },
  ];
  return (
    <div className="specialFeatures">
      <h1 className="heading mb-3 text-center text-md-start ms-md-4">
        Special Features:
      </h1>

      <div className="d-none d-md-block">
        <div className="row gx-1 text-center">
          {specialFeaturesItems.map((obj) => {
            return (
              <div
                className="col-12 col-md-6 feature px-0 py-0 specialFeatureBox"
                style={{
                  backgroundColor: obj.featureContetBackgroundColor,
                }}
              >
                <div className="text-center">
                  <h5
                    className="p-3 p-md-4 feature-heading fw-bold "
                    style={{
                      backgroundColor: obj.featureHeadingBackgroundColor,
                    }}
                  >
                    {obj.featureHeading}
                  </h5>
                  <div className="ms-md-5 me-md-5 mt-md-4 mb-md-4 ms-3 me-3 mt-3 mb-3">
                    <img src={obj.featureImage} className="mb-2"></img>
                    <p>{obj.featureContent}</p>
                    <button className="btn-howLFNWorks" onClick={onDownloadApp}>
                      Know More
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Mobile Device */}
      <div className="d-block d-md-none">
        <div className="row">
          <div className="col-md-12">
            <div
              id="testimonial-carousel"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {specialFeaturesItems.map((obj, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <div className="">
                      <div className="row gx-1 text-center">
                        <div
                          className="col-12 col-md-6 feature px-0 py-0 specialFeatureBox"
                          style={{
                            backgroundColor: obj.featureContetBackgroundColor,
                          }}
                        >
                          <div className="text-center">
                            <h5
                              className="p-3 p-md-4 feature-heading fw-bold "
                              style={{
                                backgroundColor:
                                  obj.featureHeadingBackgroundColor,
                              }}
                            >
                              {obj.featureHeading}
                            </h5>
                            <div className="ms-md-5 me-md-5 mt-md-4 mb-md-4 ms-3 me-3 mt-3 mb-3">
                              <img
                                src={obj.featureImage}
                                className="mb-2"
                              ></img>
                              <p>{obj.featureContent}</p>
                              <button
                                className="btn-howLFNWorks"
                                onClick={onDownloadApp}
                              >
                                Know More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
              <br></br>
              <ol className="carousel-indicators ">
                {specialFeaturesItems.map((obj, index) => (
                  <li
                    data-bs-target="#testimonial-carousel"
                    data-bs-slide-to={index}
                    className={`${index === 0 ? "active" : ""}`}
                    key={index}
                  ></li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialFeature;

function Dailogue(props) {
  return (
    <>
      <div
        className="modal fade"
        id={props.id}
        role="dialog"
        aria-labelledby={`${props.id}CenterTitle`}
        aria-hidden="true"
        data-bs-backdrop={props.staticBackdrop ? "static" : true}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 pt-0 pb-0">
              {props.title ? (
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {props.title}
                </h5>
              ) : null}
              {props.isClose && (
                <button
                  type="button"
                  className="close ms-auto border-0 bg-transparent"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
            <div className="modal-body">{props.body()}</div>
            {props.footer ? (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default Dailogue;

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface IAppData {
  countryOptions: [];
}

const initialState: IAppData = {
    countryOptions: []
};

export const countryOptionsSlice = createSlice({
  name: "countryOptions",
  initialState,
  reducers: {
    setCountryOptions: (state, action) => 
    {
      return {
        countryOptions: action.payload.payload.countryOptions
      };
    },
  },
});

export const selectCountryOptions = (state: RootState) =>
  state;

export const { setCountryOptions } = countryOptionsSlice.actions;
export default countryOptionsSlice.reducer;
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Execute } from "../Services/APIService";
import Dailogue from "../Controls/Modal";

const CheckoutForm = (props) => {
  // Styles
  const styles = {
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      padding: "3px 30px",
      cursor: "pointer",
      color: "white",
    },
    buttonDisabled: {
      padding: "3px 30px",
      cursor: "not-allowed",
      color: "white",
    },
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  var webAppName = "WebsitePortal";
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  var userID;
  var sessionID;
  var userName;
  var emailID;
  var MobileNo;
  var teachingLanguageID = 1;
  var appPlatform = "Web";

  if (sessionDetails != null || sessionDetails != undefined) {
    userID = sessionDetails.UserID;

    userName = sessionDetails.UserName;
    emailID = sessionDetails.EmailID;
    MobileNo = sessionDetails.MobileNo;

    if (webAppName === "LearnerPortal") {
      sessionID = sessionDetails.SessionID;
      teachingLanguageID = sessionDetails.TeachingLanguageID;
    } else {
      sessionID = 3;
      teachingLanguageID = 1;
    }
  } else {
    alert("Please login and try again");
    return;
  }

  const prefillemailIDDetails = () => {
    if (emailID != null) {
      return emailID;
    } else {
      return "connect@learnfromnative.com";
    }
  };

  const prefillMobileNoDetails = () => {
    if (MobileNo != null) {
      return MobileNo;
    } else {
      return "8058058089";
    }
  };

  var planTypeDetailsString = "";
  var planTypeDetailsParseData = [];

  var utilizeCoins = JSON.stringify(JSON.stringify(props.utilizeCoinsJson));
  var paymentMethod = "Stripe";

  const handleSubmit = async (event) => {
    setIsDisabled(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const StripeResponse = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://learner.learnfromnative.com/HomePage/",
      },
      redirect: "if_required",
    });

    var PaymentGatewayUserDetail = {
      UserEmailID: prefillemailIDDetails(),
      UserContact: prefillMobileNoDetails(),
    };

    var PaymentGatewayUserDetailJSON = JSON.stringify(PaymentGatewayUserDetail);
    var PaymentGatewayPaymentDetail = StripeResponse.paymentIntent;
    var PaymentGatewayPaymentDetailJSON = JSON.stringify(
      PaymentGatewayPaymentDetail
    );

    var PaymentGatewayPaymentResponse = StripeResponse.paymentIntent;
    var PaymentGatewayPaymentResponseJSON = JSON.stringify(
      PaymentGatewayPaymentResponse
    );

    if (StripeResponse.error) {
      const oUpdatePaymentTrackingForPaymentGatewayResponse = {
        ActionName: "LFN.GTra_UpdatePaymentTrackingForPaymentGatewayResponse",
        ParameterJSON:
          '{"PaymentTrackingID":"' +
          props.paymentDetailsParseData[0].PaymentTrackingID +
          '","PurchaseID":"' +
          props.paymentDetailsParseData[0].PurchaseID +
          ' ","PlanPricingID":"' +
          props.obj.PlanPricingID +
          ' ","UtilizeCoinsJson":' +
          utilizeCoins +
          ',"PaymentGatewayUserDetailJSON  ":' +
          PaymentGatewayUserDetailJSON +
          ',"PaymentGatewayPaymentDetailJSON  ":' +
          PaymentGatewayPaymentDetailJSON +
          ',"PaymentGatewayPaymentResponseJSON  ":' +
          PaymentGatewayPaymentResponseJSON +
          ',"PaymentMethod":"' +
          paymentMethod +
          '","RowsAffecting":"1"}',
        SessionDataJSON:
          '{"UserID":"' +
          userID +
          '","SessionID":"' +
          sessionID +
          '","OnBehalfOfUserID":"' +
          userID +
          '","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
          appPlatform +
          '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
        OperationName: "Command",
      };
      Execute(oUpdatePaymentTrackingForPaymentGatewayResponse).then(
        (response) => {
          if (!response.DataIsLoaded)
            return (
              <div>
                <h1> Please wait some time.... </h1>{" "}
              </div>
            );
          else {
            alert(StripeResponse.error.message);
            setIsDisabled(false);
          }
        }
      );
    } else if (StripeResponse.paymentIntent.status === "succeeded") {
      const oUpdatePaymentTrackingForPaymentGatewayResponse = {
        ActionName: "LFN.GTra_UpdatePaymentTrackingForPaymentGatewayResponse",
        ParameterJSON:
          '{"PaymentTrackingID":"' +
          props.paymentDetailsParseData[0].PaymentTrackingID +
          '","PurchaseID":"' +
          props.paymentDetailsParseData[0].PurchaseID +
          ' ","PlanPricingID":"' +
          props.obj.PlanPricingID +
          ' ","UtilizeCoinsJson":' +
          utilizeCoins +
          ',"PaymentGatewayUserDetailJSON  ":' +
          PaymentGatewayUserDetailJSON +
          ',"PaymentGatewayPaymentDetailJSON  ":' +
          PaymentGatewayPaymentDetailJSON +
          ',"PaymentGatewayPaymentResponseJSON  ":' +
          PaymentGatewayPaymentResponseJSON +
          ',"PaymentMethod":"' +
          paymentMethod +
          '","RowsAffecting":"1"}',
        SessionDataJSON:
          '{"UserID":"' +
          userID +
          '","SessionID":"' +
          sessionID +
          '","OnBehalfOfUserID":"' +
          userID +
          '","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
          appPlatform +
          '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
        OperationName: "Command",
      };

      Execute(oUpdatePaymentTrackingForPaymentGatewayResponse).then(
        (response) => {
          if (!response.DataIsLoaded) {
            return (
              <div>
                <h1> Please wait some time.... </h1>{" "}
              </div>
            );
          } else {
            if (response.message === "Successfull") {
              planTypeDetailsString = JSON.stringify(response.items);
              planTypeDetailsParseData = JSON.parse(
                JSON.parse(planTypeDetailsString)
              );

              if (planTypeDetailsParseData[0].PlanType == "Individual") {
                const oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForIndividual =
                  {
                    ActionName:
                      "LFN.GTra_SelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForIndividual",
                    ParameterJSON:
                      '{"PurchaseID":"' +
                      props.paymentDetailsParseData[0].PurchaseID +
                      '","PurchasePaymentID":"' +
                      props.paymentDetailsParseData[0].PurchasePaymentID +
                      '"}',
                    SessionDataJSON:
                      '{"UserID":"' +
                      userID +
                      '","SessionID":"' +
                      sessionID +
                      '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
                      appPlatform +
                      '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
                    OperationName: "Query",
                  };

                Execute(
                  oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForIndividual
                ).then((response) => {
                  if (!response.DataIsLoaded)
                    return (
                      <div>
                        <h1> Please wait some time.... </h1>{" "}
                      </div>
                    );
                  else {
                    $("#paymentModalCenter").modal("show");
                  }
                });
              } else {
                const oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForGroup =
                  {
                    ActionName:
                      "LFN.GTra_SelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForGroup",
                    ParameterJSON:
                      '{"PurchasePaymentID":"' +
                      props.paymentDetailsParseData[0].PurchasePaymentID +
                      '"}',
                    SessionDataJSON:
                      '{"UserID":"' +
                      userID +
                      '","SessionID":"' +
                      sessionID +
                      '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
                      appPlatform +
                      '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
                    OperationName: "Query",
                  };

                Execute(
                  oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForGroup
                ).then((response) => {
                  if (!response.DataIsLoaded) {
                    return (
                      <div>
                        <h1> Pleses wait some time.... </h1>{" "}
                      </div>
                    );
                  } else {
                    $("#paymentModalCenter").modal("show");
                  }
                });
              }
            }
          }
        }
      );
    } else {
      setIsDisabled(false);
    }
  };

  const onOkayButtonClick = () => {
    if (webAppName === "LearnerPortal") {
      window.location.href = "/HomePage";
    } else {
      window.location.href = "/";
    }
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content">
        <img src="/core/lfn-logo.png" className="m-3"></img>
        <h3 className="mb-5">
          We appreciate your payment! Our team will be in touch with you
          shortly. If you have any inquiries in the meantime, please don't
          hesitate to reach out to us at +91 8058058087 or send us an email at
          connect@learnfromnative.com.
        </h3>
        <button
          className="CTA-btn"
          style={{
            color: "white",
            backgroundColor: "#117800",
            borderColor: "#117800",
            width: "100px",
            height: "50px",
          }}
          onClick={() => onOkayButtonClick()}
        >
          Okay
        </button>
      </div>
    );
  };

  return (
    <>
      <form style={{ margin: "30px" }}>
        <PaymentElement />
        <br />
        <div className="text-center">
          <button
            className="CTA-btn"
            style={isDisabled ? styles.buttonDisabled : styles.button}
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            Pay Now
          </button>
        </div>
      </form>
      <Dailogue id={"paymentModalCenter"} body={getDailogueBody} title="" />
    </>
  );
};

export default CheckoutForm;

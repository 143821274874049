import "./style/main.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import { initializeIcons } from "@fluentui/react";

import { render } from "react-dom";
import TutorProfile from "./pages/TutorProfile";
import PlanPricingTutorList from "./pages/PlanPricingTutorList";
import BillingInvoice from "./pages/BillingInvoice";
import Login from "./component/Login";
import Blogs from "./pages/Blogs";
import Kids from "./pages/Kids";
import FreeBillingInvoice from "./pages/FreeBillingInvoice";
import TestAI from "./pages/TestAI";
import TestAIApp from "./pages/TestAIApp";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/store";

initializeIcons();

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Checkbox } from '@fluentui/react';
import React from 'react'

function MyCheckbox(props)
{
    return (
     
        <div>
            <Checkbox 
             id={props.id}
             checked={props.checked}
             className={props.className}
             label={props.label}
             onChange={props.onChange} 
             styles={props.styles}/>
        </div>
    );
}
export default MyCheckbox;
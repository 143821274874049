import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./component/Header";
import BestTutors from "./component/BestTutors";
import LanguageOffered from "./component/LanguageOffered";
import WhatMakesLearnFromNativeUnique from "./component/WhatMakesLearnFromNativeUnique";
import DoMoreWithLFN from "./component/DoMoreWithLFN";
import PlanPricing from "./component/PlanPricing";
import Catalogue from "./component/Catalogue";
import StudentTestimonial from "./component/StudentTestimonial";
import FrequentlyAskQuestion from "./component/FrequentlyAskQuestion";
import Footer from "./component/Footer";
import BookAFreeDemo from "./component/BookAFreeDemo";
import { Execute } from "./Services/APIService";
import { css } from "@emotion/react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import TutorProfile from "./pages/TutorProfile";
import PlanPricingTutorList from "./pages/PlanPricingTutorList";
import BillingInvoice from "./pages/BillingInvoice";
import FreeBillingInvoice from "./pages/FreeBillingInvoice";
import Login from "./component/Login";
import Blogs from "./pages/Blogs";
import Kids from "./pages/Kids";
import TestAIApp from "./pages/TestAIApp";
import TestAI from "./pages/TestAI";
import { useDispatch } from "react-redux";

import SpokenEnglishForKidsInIndia from "./pages/the-role-of-culture-in-learning-spoken-english-for-kids-in-india-insights-and-tips";
import SpokenEnglishKidsConfidence from "./pages/SpokenEnglishKidsConfidence";
import TipsSpokenEnglishKids from "./pages/TipsSpokenEnglishKids";
import PronunciationSpokenEnglishKids from "./pages/PronunciationSpokenEnglishKids";
import SpokenEnglishKids from "./pages/SpokenEnglishKids";
import OnlineEnglishIeltsToefl from "./pages/OnlineEnglishIeltsToefl";
import NativeEnglishTeachers from "./pages/NativeEnglishTeachers";
import FreeDemoClassBooking from "./pages/FreeDemoClassBooking";
import HowLearnFromNativeWorks from "./component/HowLearnFromNativeWorksNew";
import Stripe from "./pages/Stripe";
// import EnglishLearningForKids from './pages/EnglishLearningForKids';
import FreeDemoClassBookingCalendarView from "./pages/FreeDemoClassBookingCalendar";
import FreeDemoClassBokkingConfirmation from "./pages/FreeDemoClassBokkingConfirmation";
import HowLearnFromNativeISUnique from "./component/HowLearnFromNativeISUnique";
import HighImpactTutoring from "./component/HighImpactTutoring";
import TutorEnrollment from "./pages/TutorEnrollment";
import AIPOC from "./pages/AIPOC";
import USAStudentTestimonial from "./component/USAStudentTestimonial";
import { compose } from "@reduxjs/toolkit";
import HighImpactTutoringBanner from "./component/HighImpactTutoringBanner";
import SpecialFeature from "./component/SpecialFeatures";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function App() {
  const [countryDetails, setCountryDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  var countryName;
  var countryID = "";
  var oSelectCountryIDFromCountryWhereCountryNameAndNonDeleted;
  const dispatch = useDispatch();
  const maxRetries = 5;

  const pathName = window.location.pathname;

  function fetchGeolocationDataWithRetry(retries = 0) {
    fetch(
      "https://api.ipgeolocation.io/ipgeo?apiKey=f260f07a0c964e4d9d5e79fb4a8fd97d"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (
          data.country_name === "United States" ||
          data.country_name === "India"
        ) {
          countryName = data.country_name;
        } else {
          countryName = "United States";
        }

        if (pathName == "/en-us" || pathName == "/Kids/en-us") {
          countryName = "United States";
        }

        if (pathName == "/en-in" || pathName == "/Kids/en-in") {
          countryName = "India";
        }

        //countryName = "United States";
        oSelectCountryIDFromCountryWhereCountryNameAndNonDeleted = {
          ActionName:
            "Core.GMst_SelectCountryIDFromCountryWhereCountryNameAndNonDeleted",
          ParameterJSON: '{"CountryName":"' + countryName + '"}',
          SessionDataJSON:
            '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":"web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
          OperationName: "Query",
        };
        Execute(oSelectCountryIDFromCountryWhereCountryNameAndNonDeleted).then(
          (response) => {
            countryID = response.items[0].CountryID;
            const obj = {
              ...sessionDetails,
              CountryID: countryID,
              CountryName: countryName,
              Latitude: data.latitude,
              Longitude: data.longitude,
            };
            localStorage.setItem("items", JSON.stringify({ ...obj }));
            setIsLoading(false);
          }
        );
      })
      .catch((error) => {
        console.error("Fetch error:", error);

        //Check if retries are within the allowed limit
        if (retries < maxRetries) {
          setTimeout(() => {
            fetchGeolocationDataWithRetry(retries + 1);
          }, 500); // Wait for 1/2 second before retrying (adjust the delay as needed)
        } else {
          // Handle the case when maximum retries are reached
          console.error("Maximum retries reached. Unable to fetch data.");
          setIsLoading(true); // fail
        }
        // setTimeout(() =>
        //   {
        //     fetchGeolocationDataWithRetry(retries + 1);
        //   }, 1000); // Wait for 1 second before retrying (adjust the delay as needed)
      });
  }

  useEffect(() => {
    // Call the function to initiate the fetch with retries
    fetchGeolocationDataWithRetry();
  }, []);

  useLayoutEffect(() => {
    if (!isLoading && window.location.hash === "#planPricingSection") {
      const planPricingSection = document.getElementById("planPricingSection");
      if (planPricingSection) {
        planPricingSection.scrollIntoView();
      }
    }
  }, [isLoading]);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {
                  <>
                    <Header />
                    <BestTutors />

                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <PlanPricing countryDetails={countryDetails} />
                    )}
                    <WhatMakesLearnFromNativeUnique />
                    {countryName != "India" ? <SpecialFeature /> : null}
                    <DoMoreWithLFN />
                    {countryName != "India" ? (
                      <HighImpactTutoringBanner />
                    ) : null}
                    {/* {countryName != "India" ? (
                      <HowLearnFromNativeISUnique />
                    ) : null} */}

                    {countryName === "India" ? <Catalogue /> : null}

                    {countryName === "India" ? (
                      <StudentTestimonial />
                    ) : (
                      <USAStudentTestimonial />
                    )}
                    <BookAFreeDemo Route="HomePage" />
                    <FrequentlyAskQuestion />
                    <Footer />
                  </>
                }
              </>
            }
          />
          <Route
            path="/en-us"
            element={
              <>
                {
                  <>
                    <Header />
                    <BestTutors />

                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <PlanPricing countryDetails={countryDetails} />
                    )}
                    <WhatMakesLearnFromNativeUnique />
                    {countryName != "India" ? <SpecialFeature /> : null}
                    <DoMoreWithLFN />
                    {countryName != "India" ? (
                      <HighImpactTutoringBanner />
                    ) : null}
                    {/* {countryName != "India" ? (
                      <HowLearnFromNativeISUnique />
                    ) : null} */}

                    {countryName === "India" ? <Catalogue /> : null}

                    {countryName === "India" ? (
                      <StudentTestimonial />
                    ) : (
                      <USAStudentTestimonial />
                    )}
                    <BookAFreeDemo Route="HomePage" />
                    <FrequentlyAskQuestion />
                    <Footer />
                  </>
                }
              </>
            }
          />
          <Route
            path="/en-in"
            element={
              <>
                {
                  <>
                    <Header />
                    <BestTutors />

                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <PlanPricing countryDetails={countryDetails} />
                    )}
                    <WhatMakesLearnFromNativeUnique />
                    {countryName != "India" ? <SpecialFeature /> : null}
                    <DoMoreWithLFN />
                    {countryName != "India" ? (
                      <HighImpactTutoringBanner />
                    ) : null}
                    {/* {countryName != "India" ? (
                      <HowLearnFromNativeISUnique />
                    ) : null} */}

                    {countryName === "India" ? <Catalogue /> : null}

                    {countryName === "India" ? (
                      <StudentTestimonial />
                    ) : (
                      <USAStudentTestimonial />
                    )}
                    <BookAFreeDemo Route="HomePage" />
                    <FrequentlyAskQuestion />
                    <Footer />
                  </>
                }
              </>
            }
          />
          <Route path="TutorProfile" element={<TutorProfile />} />
          <Route
            path="PlanPricingTutorList"
            element={<PlanPricingTutorList />}
          />
          <Route path="BillingInvoice" element={<BillingInvoice />} />
          <Route path="FreeBillingInvoice" element={<FreeBillingInvoice />} />
          <Route path="Login" element={<Login />} />
          <Route path="Blogs" element={<Blogs />} />
          <Route path="Kids/" element={<Kids />} />
          <Route path="Kids/en-us" element={<Kids />} />
          <Route path="Kids/en-in" element={<Kids />} />

          <Route path="TestAIApp" element={<TestAIApp />} />
          <Route path="TestAI" element={<TestAI />} />
          <Route
            path="FreeDemoClassBooking"
            element={<FreeDemoClassBooking />}
          />
          <Route path="Stripe" element={<Stripe />} />
          <Route
            path="FreeDemoClassBookingCalendarView"
            element={<FreeDemoClassBookingCalendarView />}
          />
          <Route
            path="FreeDemoClassBokkingConfirmation"
            element={<FreeDemoClassBokkingConfirmation />}
          />

          <Route
            path="the-role-of-culture-in-learning-spoken-english-for-kids-in-india-insights-and-tips"
            element={<SpokenEnglishForKidsInIndia />}
          />
          <Route
            path="spoken-english-kids-confidence"
            element={<SpokenEnglishKidsConfidence />}
          />
          <Route
            path="tips-spoken_english-kids"
            element={<TipsSpokenEnglishKids />}
          />
          <Route
            path="pronunciation-spoken-english-kids"
            element={<PronunciationSpokenEnglishKids />}
          />
          <Route path="spoken-english-kids" element={<SpokenEnglishKids />} />
          <Route
            path="online-english-ielts-toefl"
            element={<OnlineEnglishIeltsToefl />}
          />
          <Route
            path="native-english-teachers"
            element={<NativeEnglishTeachers />}
          />
          <Route path="TutorEnrollment" element={<TutorEnrollment />} />

          <Route path="AIPOC" element={<AIPOC />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

interface FormData {
  ActionName: string;
  ParameterJSON: string;
  SessionDataJSON: string;
  OperationName: string;
}

export function Execute(FormData: FormData) {
  return fetch("https://liveapi.learnfromnative.com/api/Device/Execute", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    body: JSON.stringify(FormData),
  })
    .then((res) => res.json())
    .then((json) => {
      return { DataIsLoaded: true, items: json.Data, message: json.Message };
    });
}

import { useEffect, useState } from "react";
import LanguageOffered from "./LanguageOffered";

function WhatDoWeHaveToOfferUSA()
{
    
    const [activeTab, setActiveTab] = useState('Launchers');
    const backgroundColors = ['#FFB802', '#FF8B00', '#117800', '#8AA4FF','#FF5858'];
    var languageOfferedItems=[
        
        {
            name:"English",
            src:"/assest/images/languages/English-Kids.png"
        },
        {
            name:"Science",
            src:"/assest/images/languages/Science-Kids.png"
        },
        {
            name:"Math",
            src:"/assest/images/languages/Mathematics-Kids.png"
        }
        
    ]
    // const intervalDuration = 3000; // Change tab every 5 seconds
    // const tabs = ['ExpertTutors', 'FlexibleScheduling', 'FreeLearningResources'];

    useEffect(() => 
    {
        const content = document.querySelectorAll('.content');
        content.forEach((item: Element) => 
        {
          if (item.id === activeTab) {
            (item as HTMLElement).style.display = 'block';
          } else {
            (item as HTMLElement).style.display = 'none';
          }
        });
    }, [activeTab]);
  
    const onBookAFreeDemo=()=>
    {
        window.location.href='/#planPricingSection'
    }

    // useEffect(() => 
    // {
    //     let currentIndex = 0;
    
    //     const interval = setInterval(() =>
    //     {
    //       const newActiveTab = tabs[currentIndex];
    
    //       setActiveTab(newActiveTab);
    //       currentIndex = (currentIndex + 1) % tabs.length;
    //     }, intervalDuration);
    
    //     return () => {
    //       clearInterval(interval);
    //     };
    // }, []); // Empty dependency array ensures the effect runs only once
    

    const [activeMenu, setActiveMenu] = useState('English'); // Initialize with 'English'
 
    const handleClick = (target:any) => 
    {
        setActiveTab(target);
    }
    const getCourseDetails=(menu)=>
    {
        //add class to set border red
        setActiveMenu(menu);

        if(menu=='Math')
        {
            setActiveTab('MathLaunchers')
        }
        else if(menu=='English')
        {
            setActiveTab('Launchers')
        }
        else if(menu=='Science')
        {
            setActiveTab('GeneralScience')
        }
       

    }

    
    return<>
    <div className="LFNOffer doMoreWithLFN">
        <div className="doMoreWithLFN-content">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="heading">What do we have to offer?</h1>
                </div>
            </div>
            
            <div className="row">
                <div className="col-12 text-center">
                    <p>Learn From Native provides you with a world of endless possibilities for your child's growth and development. Explore our wide range of courses, in various subjects like English, Math & Science. With personalized learning options, tailor your child's education to their unique needs and preferences. Unleash their growth and development with us.</p>
                </div>
            </div>
            <div className="languagesOffered whatDoWeHaveToOfferSubjectList">
            <div className="languageOffered-content d-flex mt-3 mb-3">
                {languageOfferedItems.map(obj => 
                (
                   
                    <div className="languages">
                        {
                        
                        <>
                            <img src={obj.src} alt={obj.name} onClick={()=>getCourseDetails(obj.name)} className={activeMenu === obj.name ? 'active-image cursor-pointer' : 'cursor-pointer'}/>
                            <span style={{ marginTop: '8px' }}><b>{obj.name}</b></span>
                        </>
                           
                        
                        }
                    </div>
                    
                   
                ))}
            </div>
            </div>
            

        </div>
        <div className="menu-items">

            {
                activeMenu=='English'&&
                (
                    <div className="row g-2 g-md-3 d-flex justify-content-between flex-nowrap">
                    <div className={`col-3 col-md pt-4 text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-SpeakUpAndShine ${activeTab === 'Launchers' ? 'active' : ''}`} onClick={() => handleClick('Launchers')}>
                    <img src="/assest/images/Launchers.png" alt="Icon"></img>
                    <p className="text-light text-center">Launchers<br/>(5 to 7 years)</p>
                    </div>
                    <div className={`col-3 col-md pt-4  d-flex flex-column align-items-center justify-content-end courseOverView bg-DiscoverTheMagic ${activeTab === 'Explorers' ? 'active' : ''}`} onClick={() => handleClick('Explorers')}>
                        <img src="/assest/images/Explorer.png" alt="Icon" ></img>
                        <p className="text-light text-center mr-2 ml-2">Explorers<br/>(8 to 11 years)</p>
                    </div>
                    <div  className={`col-3 col-md pt-4  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-ReadWithPhonics ${activeTab === 'Pathfinders' ? 'active' : ''}`} onClick={() => handleClick('Pathfinders')}>
                    <img src="/assest/images/Pathfinder.png" alt="Icon" ></img>
                    <p className="text-light text-center mr-2 ml-2">Pathfinders<br/>(12 to 15 years)</p>
                    </div>
                    </div>
                )
            }
            {
                activeMenu=='Math'&&
                (
                    <div className="row g-2 g-md-3 d-flex justify-content-between flex-nowrap">
                    <div className={`col-3 col-md pt-4 text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-SpeakUpAndShine ${activeTab === 'MathLaunchers' ? 'active' : ''}`} onClick={() => handleClick('MathLaunchers')}>
                    <img src="/assest/images/Launchers.png" alt="Icon"></img>
                    <p className="text-light text-center">Launchers<br/>(5 to 7 years)</p>
                    </div>
                    <div className={`col-3 col-md pt-4  d-flex flex-column align-items-center justify-content-end courseOverView bg-DiscoverTheMagic ${activeTab === 'MathExplorers' ? 'active' : ''}`} onClick={() => handleClick('MathExplorers')}>
                        <img src="/assest/images/Explorer.png" alt="Icon" ></img>
                        <p className="text-light text-center mr-2 ml-2">Explorers<br/>(8 to 11 years)</p>
                    </div>
                    <div  className={`col-3 col-md pt-4  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-ReadWithPhonics ${activeTab === 'MathPathfinders' ? 'active' : ''}`} onClick={() => handleClick('MathPathfinders')}>
                    <img src="/assest/images/Pathfinder.png" alt="Icon" ></img>
                    <p className="text-light text-center mr-2 ml-2">Pathfinders<br/>(12 to 15 years)</p>
                    </div>
                    </div>
                )
            }
            {
                activeMenu=='Science'&&
                (
                    <div className="row g-2 g-md-3 d-flex justify-content-between flex-nowrap">
                    <div  className={`col-3 col-md pt-5  d-flex flex-column align-items-center justify-content-end courseOverView bg-GeneralScience ${activeTab === 'GeneralScience' ? 'active' : ''}`} onClick={() => handleClick('GeneralScience')}>
                    <img src="/assest/images/GeneralScience.png" alt="Icon" style={{ width: '150px', height: '250px' }}></img>
                    <p className="text-light text-center mr-2 ml-2">General Science</p>
                    </div>
                    <div  className={`col-3 col-md pt-5  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-SpeakUpAndShine ${activeTab === 'Chemistry' ? 'active' : ''}`} onClick={() => handleClick('Chemistry')}>
                    <img src="/assest/images/chemistry.png" alt="Icon" style={{ width: '150px', height: '250px' }}></img>
                    <p className="text-light text-center mr-2 ml-2">Chemistry</p>
                    </div>
                    <div  className={`col-3 col-md pt-5  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-GrammarMastery ${activeTab === 'Physics' ? 'active' : ''}`} onClick={() => handleClick('Physics')}>
                    <img src="/assest/images/physics.png" alt="Icon" style={{ width: '150px', height: '250px' }}></img>
                    <p className="text-light text-center mr-2 ml-2">Physics</p>
                    </div>
                    <div  className={`col-3 col-md pt-5  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-ReadWithPhonics ${activeTab === 'Biology' ? 'active' : ''}`} onClick={() => handleClick('Biology')}>
                    <img src="/assest/images/biology.png" alt="Icon" style={{ width: '150px', height: '250px' }}></img>
                    <p className="text-light text-center mr-2 ml-2">Biology</p>
                    </div>
                    
                    </div>
                )
            }
            

            
            <br/>
        </div>
        <div id="web-banner" className="m-4 m-md-5">

            
            <div id="Launchers" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'Launchers' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>This course is designed to develop foundational reading skills and prepare prep starters for reading success. It focuses on everyday conversational vocabulary, correct pronunciation, punctuation recognition, and early reading comprehension.</p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Advanced Phonics & Pronunciation </li>
                                <li>Punctuation Recognition & Intonation </li>
                                <li>Developing Comprehension Skills </li>
                                <li>Fables Reading </li>
                                <li>High Frequency Vocabulary Practice & more.... </li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>By the end of the course, prep starters are expected to have a solid foundation in letter recognition, phonics, and basic sight word reading. They will have enhanced early reading comprehension skills and be better prepared for more advanced reading instruction. </p>
                    </div>
                </div>
            </div>
            <div id="Explorers" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'Explorers' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>This course is designed to foster a love for reading and develop reading comprehension skills among language explorers. It focuses on understanding story elements, making predictions, asking questions, retelling stories, and developing complex vocabulary. </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Story Elements </li>
                                <li>Context Clues </li>
                                <li>Understanding Fictional & Non-Fictional Texts </li>
                                <li>Opinion Based Texts </li>
                                <li>Media Literacy & Comprehension & more....</li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>By the end of the course, language explorers will have a better understanding of different genres, improved comprehension skills, and the ability to identify story elements. They will be able to make predictions, ask relevant questions, and retell stories with greater detail and accuracy. </p>
                    </div>
                </div>
            </div>
            <div id="Pathfinders" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'Pathfinders' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>This course is designed to enhance reading fluency, expand vocabulary, and develop higher-level comprehension skills for pathfinders. It focuses on improving reading speed, expression, vocabulary acquisition, and critical thinking while reading. </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Explore Journaling </li>
                                <li>Learn Figurative Speech </li>
                                <li>Analyse Speech </li>
                                <li>Understand Biographies & Autobiographies</li>
                                <li>Dive Deep into Drama & more..... </li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>By the end of the course, pathfinders will have significantly improved their reading fluency, acquired a broader vocabulary, and developed higher-level comprehension skills. They will demonstrate stronger critical thinking abilities and be able to extract deeper meaning from texts. </p>
                    </div>
                </div>
            </div>


            <div id="MathLaunchers" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'MathLaunchers' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Personalized Math Course for children aged 5 to 7 provides a nurturing environment to lay the foundation of mathematical understanding. Through interactive activities and engaging exercises, we cultivate a positive attitude toward Math, encouraging curiosity and exploration. </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Introduction to Numbers and Counting: Building a strong numerical foundation. </li>
                                <li>Basic Arithmetic Operations (Addition, Subtraction): Instilling fundamental math skills. </li>
                                <li>Developing Comprehension Skills </li>
                                <li>Shapes and Patterns Recognition: Enhancing cognitive abilities through visual learning.  </li>
                                <li>Introduction to Measurements: Laying the groundwork for practical math applications. </li>
                                <li>Interactive Math Games and Activities: Making learning enjoyable and interactive and more….. </li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>
                            <li>Strong Numerical Foundation for the children</li> 
                            <li>Fundamental Math Skills to master addition and subtraction for problem-solving. </li>
                            <li>Cognitive Enhancement by Recognition of shapes and patterns, boosting critical thinking.  </li>
                            <li>Practical Math Readiness by introducing concepts for preparation of real-world math.  </li>
                            <li>Joyful Math Learning with Interactive activities cultivating a love for math exploration.  </li>
                        </p>
                    </div>
                </div>
            </div>
            <div id="MathExplorers" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'MathExplorers' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Personalized Math Course for ages 8 to 11 is designed to foster analytical thinking and problem-solving skills. Through a structured curriculum, we aim to develop math proficiency and logical reasoning in a supportive learning environment. </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                            <li>Advanced Arithmetic (Multiplication, Division): Strengthening foundational arithmetic skills.</li> 
                            <li>Fractions and Decimals Understanding: Building fluency in working with fractions and decimals. </li>
                            <li>Geometry Fundamentals: Exploring shapes, angles, and spatial relationships. </li>
                            <li>Introduction to Algebraic Concepts: Laying the groundwork for algebraic thinking.</li> 
                            <li>Real-world Problem Solving: Applying math concepts to practical situations and more…. </li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>
                            <li>Arithmetic Mastery by developing excellemce in advanced arithmetic, including fractions and decimals. </li>
                            <li>Geometry Proficiency by Mastering shapes, angles, and spatial concepts. </li>
                            <li>Algebraic Foundation building a strong base for algebraic thinking. </li>
                            <li>Analyse and solve real-world math problems with confidence. </li>
                            <li>Sharper Logical Thinking by developing strong analytical and problem-solving skills for critical thinking. </li>

                        </p>
                    </div>
                </div>
            </div>
            <div id="MathPathfinders" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'MathPathfinders' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Personalized Math Course for ages 12 to 15 is designed to challenge and stimulate young minds. Through advanced curriculum and critical thinking exercises, we aim to prepare students for higher-level mathematics and problem-solving.</p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Algebra and Equations Mastery: Developing a strong foundation in algebraic concepts. </li>
                                <li>Advanced Geometry and Trigonometry: Exploring intricate geometrical relationships. </li>
                                <li>Intensive Data Analysis and Probability: Enhancing statistical analysis skills. </li>
                                <li>Critical Thinking through Complex Problem Solving: Tackling challenging math problems.</li>
                                <li>Preparation for Higher-level Mathematics: Equipping students with skills for advanced math studies and more… </li> 
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>
                            <li>Algebra Mastery to Achieve a strong foundation in algebra.</li> 
                            <li>Geometry & Trigonometry Expertise by exploring complex geometrical relationships. </li>
                            <li>Data Analysis Prowess enhancing statistical analysis skills. </li>
                            <li>Critical Problem Solving to tackle challenging math problems with precision.</li> 
                            <li>Advanced Math Readiness to prepare for high school mathematics and problem-solving. </li>
                        </p>
                    </div>
                </div>
            </div>


            <div id="GeneralScience" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'GeneralScience' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Personalized Science Course, spanning ages 5 to 11, ignites curiosity, fosters critical thinking, and deepens scientific knowledge. Through interactive lessons, hands-on experiments, and activities, we empower young learners to explore, analyze, and love the world of science." </p>
                        <h2 className="heading mb-3">Course Outline: for 5 to 7 years learners </h2>
                        <p>
                            <ul>
                                <li>Introduction to Basic Scientific Concepts: Cultivating an early interest in science. </li>
                                <li>Exploring the Natural World: Hands-on exploration of plants, animals, and their environments.</li> 
                                <li>Simple Experiments and Observations: Encouraging curiosity through interactive learning. </li>
                                <li>Basic Concepts of Matter and Energy: Introducing fundamental scientific principles. </li>
                                <li>Environmental Awareness: Instilling an understanding of nature and conservation and more…</li> 
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Course Outline:  8 to 11 years learners  </h2>
                        <p>
                            <ul>
                                <li>Life Sciences Exploration: Studying ecosystems, adaptation, and biodiversity. </li>
                                <li>Physical Sciences Fundamentals: Understanding forces, energy, and basic physics. </li>
                                <li>Hands-on Experiments and Observations: Applying scientific methods to real-world scenarios.</li> 
                                <li>Earth and Space Exploration: Exploring geological processes and the cosmos. </li>
                                <li>Introduction to Scientific Inquiry: Nurturing scientific thinking and questioning.</li> 
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>
                            <li>Strong Numerical Foundation for the children</li> 
                            <li>Fundamental Math Skills to master addition and subtraction for problem-solving. </li>
                            <li>Cognitive Enhancement by Recognition of shapes and patterns, boosting critical thinking.  </li>
                            <li>Practical Math Readiness by introducing concepts for preparation of real-world math.  </li>
                            <li>Joyful Math Learning with Interactive activities cultivating a love for math exploration.  </li>
                        </p>
                    </div>
                </div>
            </div>
            <div id="Physics" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'Physics' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Personalized Science Course for ages 12 to 15 with a Physics focus is designed to delve deep into the world of physical phenomena. Through a comprehensive curriculum and hands-on experiments, we aim to equip students with a solid foundation in physics principles. </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Mechanics and Motion: Exploring concepts of motion, forces, and energy.</li> 
                                <li>Electricity and Magnetism: Understanding electric circuits, magnetism, and electromagnetic waves.</li> 
                                <li>Waves and Optics: Investigating light, sound, and wave properties. </li>
                                <li>Thermodynamics: Studying heat, temperature, and energy transfer. </li>
                                <li>Applied Physics: Real-world applications of physics concepts and more…</li> 
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>
                            <li>Physics Proficiency by mastering the principles of important physics concepts </li>
                            <li>Hands-On Expertise enhancing the practical skills through experiments and real-world physics applications. </li>
                            <li>Ability to apply physics knowledge to solve complex real-world problems. </li>
                            <li>Build a strong base for future physics and science-related pursuits. </li>
                            <li>Gain insights into the natural world's physical laws, fostering appreciation for scientific exploration. </li>

                        </p>
                    </div>
                </div>
            </div>
            <div id="Chemistry" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'Chemistry' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Personalized Science Course for ages 12 to 15 with a Chemistry focus is designed to unravel the mysteries of chemical interactions. Through a comprehensive curriculum and hands-on experiments, we aim to nurture a strong foundation in chemistry concepts. </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Atomic Structure and Periodicity: Exploring elements, atoms, and the periodic table. </li>
                                <li>Chemical Reactions: Understanding chemical equations, reactions, and kinetics. </li>
                                <li>Acids, Bases, and pH: Investigating pH levels, acid-base properties, and indicators.</li> 
                                <li>Organic Chemistry Basics: Introducing hydrocarbons, functional groups, and basic reactions.</li> 
                                <li>Applied Chemistry: Real-world applications of chemistry principles. </li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>
                            <li>Master chemistry concepts, including atomic structure, reactions, acids, bases, and organic chemistry basics. </li>
                            <li>Develop practical skills through experiments, enabling real-world chemistry applications. </li>
                            <li>Build a strong base for future chemistry and science-related pursuits. </li>
                            <li>Understand the real-world relevance of chemistry principles in various applications.</li> 
                        </p>
                    </div>
                </div>
            </div>
            <div id="Biology" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'Biology' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Personalized Science Course for ages 12 to 15 with a Biology focus is designed to uncover the wonders of living organisms. Through a comprehensive curriculum and hands-on exploration, we aim to foster a deep understanding of biological systems. </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Cell Biology and Genetics: Exploring cells, DNA, and hereditary traits. </li>
                                <li>Human Anatomy and Physiology: Understanding body systems, organs, and functions. </li>
                                <li>Ecology and Ecosystems: Investigating interactions between organisms and their environments.</li> 
                                <li>Evolution and Biodiversity: Studying species diversity and the process of evolution. </li>
                                <li>Applied Biology: Real-world applications of biology concepts. </li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>
                            <li>Attain a profound understanding of cell biology, genetics, human anatomy, physiology, ecology, evolution, and biodiversity.</li> 
                            <li>Develop practical skills through real-world applications of biology concepts. </li>
                            <li>Foster the ability to analyze and interpret biological systems, promoting critical thinking and problem-solving.</li> 
                            <li>Build a strong basis for advanced biology studies and future pursuits in life sciences. </li>
                            <li>Cultivate a deep appreciation for the intricacies of living organisms and their interactions with the environment.</li> 


                        </p>
                    </div>
                </div>
            </div>


            
        </div>

        <div id="mob-banner" className="d-none m-4 shadow-sm rounded">
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-pills  nav-fill  mb-3 custom-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Overview</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Outline</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Outcome</button>
                        </li>
                    </ul>
                    <div className="tab-content p-4 whatdowehavetooffer-tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className={`${activeTab === 'Launchers' ? 'd-block' : 'd-none'}`}>
                                    <p>This course is designed to develop foundational reading skills and prepare prep starters for reading success. It focuses on everyday conversational vocabulary, correct pronunciation, punctuation recognition, and early reading comprehension. </p>
                                </div>
                                <div className={`${activeTab === 'Explorers' ? 'd-block' : 'd-none'}`}>
                                    <p>This course is designed to foster a love for reading and develop reading comprehension skills among language explorers. It focuses on understanding story elements, making predictions, asking questions, retelling stories, and developing complex vocabulary. </p>
                                </div>
                                <div className={`${activeTab === 'Pathfinders' ? 'd-block' : 'd-none'}`}>
                                    <p>This course is designed to enhance reading fluency, expand vocabulary, and develop higher-level comprehension skills for pathfinders. It focuses on improving reading speed, expression, vocabulary acquisition, and critical thinking while reading. </p>
                                </div>


                                <div className={`${activeTab === 'MathLaunchers' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Personalized Math Course for children aged 5 to 7 provides a nurturing environment to lay the foundation of mathematical understanding. Through interactive activities and engaging exercises, we cultivate a positive attitude toward Math, encouraging curiosity and exploration. </p>
                                </div>
                                <div className={`${activeTab === 'MathExplorers' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Personalized Math Course for ages 8 to 11 is designed to foster analytical thinking and problem-solving skills. Through a structured curriculum, we aim to develop math proficiency and logical reasoning in a supportive learning environment. </p>
                                </div>
                                <div className={`${activeTab === 'MathPathfinders' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Personalized Math Course for ages 12 to 15 is designed to challenge and stimulate young minds. Through advanced curriculum and critical thinking exercises, we aim to prepare students for higher-level mathematics and problem-solving.</p>
                                </div>


                                <div className={`${activeTab === 'GeneralScience' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Personalized Science Course, spanning ages 5 to 11, ignites curiosity, fosters critical thinking, and deepens scientific knowledge. Through interactive lessons, hands-on experiments, and activities, we empower young learners to explore, analyze, and love the world of science."</p>
                                </div>
                                <div className={`${activeTab === 'Physics' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Personalized Science Course for ages 12 to 15 with a Physics focus is designed to delve deep into the world of physical phenomena. Through a comprehensive curriculum and hands-on experiments, we aim to equip students with a solid foundation in physics principles.</p>
                                </div>
                                <div className={`${activeTab === 'Chemistry' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Personalized Science Course for ages 12 to 15 with a Chemistry focus is designed to unravel the mysteries of chemical interactions. Through a comprehensive curriculum and hands-on experiments, we aim to nurture a strong foundation in chemistry concepts. </p>
                                </div>
                                <div className={`${activeTab === 'Biology' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Personalized Science Course for ages 12 to 15 with a Biology focus is designed to uncover the wonders of living organisms. Through a comprehensive curriculum and hands-on exploration, we aim to foster a deep understanding of biological systems. </p>
                                </div>


                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className={`${activeTab === 'Launchers' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Advanced Phonics & Pronunciation</li>
                                        <li>Punctuation Recognition & Intonation</li>
                                        <li>Developing Comprehension Skills</li>
                                        <li>Fables Reading</li>
                                        <li>High Frequency Vocabulary Practice & more....</li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'Explorers' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Story Elements </li>
                                        <li>Context Clues </li>
                                        <li>Understanding Fictional & Non-Fictional Texts </li>
                                        <li>Opinion Based Texts </li>
                                        <li>Media Literacy & Comprehension & more.... </li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'Pathfinders' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Explore Journaling</li>
                                        <li>Learn Figurative Speech </li>
                                        <li>Analyse Speech </li>
                                        <li>Understand Biographies & Autobiographies </li>
                                        <li>Dive Deep into Drama & more..... </li>
                                    </ul>
                                </p>
                            </div>


                            <div className={`${activeTab === 'MathLaunchers' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                    <li>Introduction to Numbers and Counting: Building a strong numerical foundation. </li>
                                <li>Basic Arithmetic Operations (Addition, Subtraction): Instilling fundamental math skills. </li>
                                <li>Developing Comprehension Skills </li>
                                <li>Shapes and Patterns Recognition: Enhancing cognitive abilities through visual learning.  </li>
                                <li>Introduction to Measurements: Laying the groundwork for practical math applications. </li>
                                <li>Interactive Math Games and Activities: Making learning enjoyable and interactive and more….. </li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'MathExplorers' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Advanced Arithmetic (Multiplication, Division): Strengthening foundational arithmetic skills.</li> 
                                        <li>Fractions and Decimals Understanding: Building fluency in working with fractions and decimals. </li>
                                        <li>Geometry Fundamentals: Exploring shapes, angles, and spatial relationships. </li>
                                        <li>Introduction to Algebraic Concepts: Laying the groundwork for algebraic thinking.</li> 
                                        <li>Real-world Problem Solving: Applying math concepts to practical situations and more…. </li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'MathPathfinders' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Algebra and Equations Mastery: Developing a strong foundation in algebraic concepts. </li>
                                        <li>Advanced Geometry and Trigonometry: Exploring intricate geometrical relationships. </li>
                                        <li>Intensive Data Analysis and Probability: Enhancing statistical analysis skills. </li>
                                        <li>Critical Thinking through Complex Problem Solving: Tackling challenging math problems.</li>
                                        <li>Preparation for Higher-level Mathematics: Equipping students with skills for advanced math studies and more… </li> 
                                    </ul>
                                </p>
                            </div>


                            <div className={`${activeTab === 'GeneralScience' ? 'd-block' : 'd-none'}`}>
                                <p>Course Outline: for 5 to 7 years learners
                                    <ul>
                                    <li>Introduction to Basic Scientific Concepts: Cultivating an early interest in science. </li>
                                    <li>Exploring the Natural World: Hands-on exploration of plants, animals, and their environments.</li> 
                                    <li>Simple Experiments and Observations: Encouraging curiosity through interactive learning. </li>
                                    <li>Basic Concepts of Matter and Energy: Introducing fundamental scientific principles. </li>
                                    <li>Environmental Awareness: Instilling an understanding of nature and conservation and more…</li> 
                                    </ul>
                                </p>
                                <p>Course Outline:  8 to 11 years learners
                                    <ul>
                                    <li>Life Sciences Exploration: Studying ecosystems, adaptation, and biodiversity. </li>
                                <li>Physical Sciences Fundamentals: Understanding forces, energy, and basic physics. </li>
                                <li>Hands-on Experiments and Observations: Applying scientific methods to real-world scenarios.</li> 
                                <li>Earth and Space Exploration: Exploring geological processes and the cosmos. </li>
                                <li>Introduction to Scientific Inquiry: Nurturing scientific thinking and questioning.</li> 
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'Physics' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                    <li>Mechanics and Motion: Exploring concepts of motion, forces, and energy.</li> 
                                    <li>Electricity and Magnetism: Understanding electric circuits, magnetism, and electromagnetic waves.</li> 
                                    <li>Waves and Optics: Investigating light, sound, and wave properties. </li>
                                    <li>Thermodynamics: Studying heat, temperature, and energy transfer. </li>
                                    <li>Applied Physics: Real-world applications of physics concepts and more…</li> 
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'Chemistry' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                    <li>Atomic Structure and Periodicity: Exploring elements, atoms, and the periodic table. </li>
                                <li>Chemical Reactions: Understanding chemical equations, reactions, and kinetics. </li>
                                <li>Acids, Bases, and pH: Investigating pH levels, acid-base properties, and indicators.</li> 
                                <li>Organic Chemistry Basics: Introducing hydrocarbons, functional groups, and basic reactions.</li> 
                                <li>Applied Chemistry: Real-world applications of chemistry principles. </li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'Biology' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                    <li>Cell Biology and Genetics: Exploring cells, DNA, and hereditary traits. </li>
                                <li>Human Anatomy and Physiology: Understanding body systems, organs, and functions. </li>
                                <li>Ecology and Ecosystems: Investigating interactions between organisms and their environments.</li> 
                                <li>Evolution and Biodiversity: Studying species diversity and the process of evolution. </li>
                                <li>Applied Biology: Real-world applications of biology concepts. </li>
                                    </ul>
                                </p>
                            </div>


                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className={`${activeTab === 'Launchers' ? 'd-block' : 'd-none'}`}>
                                <p>By the end of the course, prep starters are expected to have a solid foundation in letter recognition, phonics, and basic sight word reading. They will have enhanced early reading comprehension skills and be better prepared for more advanced reading instruction.</p>
                            </div>
                            <div className={`${activeTab === 'Explorers' ? 'd-block' : 'd-none'}`}>
                                <p>By the end of the course, language explorers will have a better understanding of different genres, improved comprehension skills, and the ability to identify story elements. They will be able to make predictions, ask relevant questions, and retell stories with greater detail and accuracy. </p>
                            </div>
                            <div className={`${activeTab === 'Pathfinders' ? 'd-block' : 'd-none'}`}>
                                <p>By the end of the course, pathfinders will have significantly improved their reading fluency, acquired a broader vocabulary, and developed higher-level comprehension skills. They will demonstrate stronger critical thinking abilities and be able to extract deeper meaning from texts. </p>
                            </div>


                            <div className={`${activeTab === 'MathLaunchers' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <li>Strong Numerical Foundation for the children</li> 
                                    <li>Fundamental Math Skills to master addition and subtraction for problem-solving. </li>
                                    <li>Cognitive Enhancement by Recognition of shapes and patterns, boosting critical thinking.  </li>
                                    <li>Practical Math Readiness by introducing concepts for preparation of real-world math.  </li>
                                    <li>Joyful Math Learning with Interactive activities cultivating a love for math exploration.  </li>
                                </p>
                            </div>
                            <div className={`${activeTab === 'MathExplorers' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <li>Arithmetic Mastery by developing excellemce in advanced arithmetic, including fractions and decimals. </li>
                                    <li>Geometry Proficiency by Mastering shapes, angles, and spatial concepts. </li>
                                    <li>Algebraic Foundation building a strong base for algebraic thinking. </li>
                                    <li>Analyse and solve real-world math problems with confidence. </li>
                                    <li>Sharper Logical Thinking by developing strong analytical and problem-solving skills for critical thinking. </li>
                                </p>
                            </div>
                            <div className={`${activeTab === 'MathPathfinders' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <li>Algebra Mastery to Achieve a strong foundation in algebra.</li> 
                                    <li>Geometry & Trigonometry Expertise by exploring complex geometrical relationships. </li>
                                    <li>Data Analysis Prowess enhancing statistical analysis skills. </li>
                                    <li>Critical Problem Solving to tackle challenging math problems with precision.</li> 
                                    <li>Advanced Math Readiness to prepare for high school mathematics and problem-solving. </li>
                                </p>
                            </div>


                            <div className={`${activeTab === 'GeneralScience' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <li>Strong Numerical Foundation for the children</li> 
                                    <li>Fundamental Math Skills to master addition and subtraction for problem-solving. </li>
                                    <li>Cognitive Enhancement by Recognition of shapes and patterns, boosting critical thinking.  </li>
                                    <li>Practical Math Readiness by introducing concepts for preparation of real-world math.  </li>
                                    <li>Joyful Math Learning with Interactive activities cultivating a love for math exploration.  </li>

                                </p>
                            </div>
                            <div className={`${activeTab === 'Physics' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <li>Physics Proficiency by mastering the principles of important physics concepts </li>
                                    <li>Hands-On Expertise enhancing the practical skills through experiments and real-world physics applications. </li>
                                    <li>Ability to apply physics knowledge to solve complex real-world problems. </li>
                                    <li>Build a strong base for future physics and science-related pursuits. </li>
                                    <li>Gain insights into the natural world's physical laws, fostering appreciation for scientific exploration. </li>

                                </p>
                            </div>
                            <div className={`${activeTab === 'Chemistry' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <li>Master chemistry concepts, including atomic structure, reactions, acids, bases, and organic chemistry basics. </li>
                                    <li>Develop practical skills through experiments, enabling real-world chemistry applications. </li>
                                    <li>Build a strong base for future chemistry and science-related pursuits. </li>
                                    <li>Understand the real-world relevance of chemistry principles in various applications.</li> 

                                </p>
                            </div>
                            <div className={`${activeTab === 'Biology' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <li>Attain a profound understanding of cell biology, genetics, human anatomy, physiology, ecology, evolution, and biodiversity.</li> 
                                    <li>Develop practical skills through real-world applications of biology concepts. </li>
                                    <li>Foster the ability to analyze and interpret biological systems, promoting critical thinking and problem-solving.</li> 
                                    <li>Build a strong basis for advanced biology studies and future pursuits in life sciences. </li>
                                    <li>Cultivate a deep appreciation for the intricacies of living organisms and their interactions with the environment.</li> 

                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
        
        <div className="row mb-4">
            <div className="col-12 col-md-3 d-flex justify-content-center align-items-center mx-auto">
                <button className="btn btn-jordyBlue btn-payNow p-3 text-center w-100 mx-5 mx-md-0" onClick={() => onBookAFreeDemo()}>
                Book a Free Demo
                </button>
            </div>
        </div>



    </div>
    </>

}

export default WhatDoWeHaveToOfferUSA
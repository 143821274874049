import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Execute } from "../Services/APIService";
import { useLocation, useNavigate } from "react-router-dom";
// import LeftBar from '../component/LeftBar';
import Header from "../component/Header";
import MyDropDown from "../Controls/MyDropDown";
import { IDropdownOption, IDropdownStyles } from "@fluentui/react";
import Login from "../component/Login";
import Dailogue from "../Controls/Modal";

const timezoneOptions: IDropdownOption[] = [
  { key: "IST", text: "Time shown in Local time of IST" },
  { key: "EST", text: "Time shown in Local time of EST" },
];

const screenWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "100%" },
  title: {
    fontSize: "22px",
    color: "#6A6A6A",
    height: 60,
    padding: 15,
    fontWeight: "bold",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
    border: "none",
    marginRight: "20px",
  },
  dropdownOptionText: {
    fontSize: "22px",
    height: 60,
    paddingTop: 20,
    color: "#6A6A6A",
    fontWeight: "bold",
    marginRight: "10px",
  },
  caretDownWrapper: { margin: 15, width: 30 },
};

if (screenWidth < 768) {
  dropdownStyles.dropdownOptionText = {
    fontSize: "16px",
    height: 36,
    paddingTop: 10,
    color: "#6A6A6A",
    fontWeight: "bold",
    marginRight: "10px",
  };
}
function FreeDemoClassBookingCalendarView() {
  const location = useLocation();

  var classTeacherID = location.state.items.TeacherID;
  var classID = location.state.items.ClassID;

  var webAppName = "WebsitePortal";
  var userID;
  var sessionID;
  var teachingLanguageID;

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
    userID = sessionDetails.UserID;
    if (webAppName === "LearnerPortal") {
      sessionID = sessionDetails.SessionID;
      teachingLanguageID = sessionDetails.TeachingLanguageID;
      userID = sessionDetails.UserID;
    } else {
      sessionID = 3;
      teachingLanguageID = 1;
      userID = sessionDetails.UserID;
    }
  }

  const validateUser = () => {
    return new Promise<void>((resolve) => {
      var sessionData = localStorage.getItem("items");
      var sessionDetails = JSON.parse(sessionData);

      if (sessionDetails != null || sessionDetails != undefined) {
        userID = sessionDetails.UserID;
        if (userID == null || userID == "" || userID == undefined) {
          $("#loginModalCenter").modal("show");
          return;
        }
      } else {
        $("#loginModalCenter").modal("show");
        return;
      }
      resolve();
    });
  };

  const navigate = useNavigate();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const InsertPurchaseAndMastersForFreeDemo = (classSlotID) => {
    const oInsertPurchaseAndMastersForFreeDemo = {
      ActionName: "LFN.GTra_InsertPurchaseAndMastersForFreeDemo",
      ParameterJSON:
        '{"ClassSlotID":"' + classSlotID + '","RowsAffecting":"4"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"' +
        userID +
        '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
    Execute(oInsertPurchaseAndMastersForFreeDemo).then((response) => {
      if (!response.DataIsLoaded) {
        return (
          <div>
            <h1>Please wait some time...</h1>
          </div>
        );
      } else {
        if (response.message === "Successfull") {
          var responseData = JSON.parse(response.items);
          var PurchaseClassID = responseData[0].PurchaseClassID;

          if (PurchaseClassID != undefined || PurchaseClassID != "") {
            const oUpdatePurchaseClassesForClassSlotBooking = {
              ActionName: "LFN.GTra_UpdatePurchaseClassesForClassSlotBooking",
              ParameterJSON:
                '{"ClassSlotID":"' +
                classSlotID +
                '","PurchaseClassID":"' +
                PurchaseClassID +
                '","RowsAffecting":"1"}',
              SessionDataJSON:
                '{"UserID":"' +
                userID +
                '","SessionID":"' +
                sessionID +
                '","OnBehalfOfUserID":"' +
                userID +
                '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
              OperationName: "Command",
            };
            Execute(oUpdatePurchaseClassesForClassSlotBooking).then(
              (response) => {
                if (!response.DataIsLoaded) {
                  return (
                    <div>
                      <h1>Please wait some time...</h1>
                    </div>
                  );
                } else {
                  if (response.message === "Successfull") {
                    $("#bookingConfirmationModalCenter").modal("show");
                  } else {
                    alert(response.message);
                  }
                }
              }
            );
          }
        } else {
          alert(response.message);
          onOkayButtonClick();
          return;
        }
      }
    });
  };

  const bookTimeSlot = (
    SlotFromOnTime,
    SlotToOnTime,
    ClassDate,
    ClassSlotID
  ) => {
    setSelectedTimeSlot({ ClassDate, SlotFromOnTime });

    setTimeout(() => {
      InsertPurchaseAndMastersForFreeDemo(ClassSlotID);
      // navigate("/FreeDemoClassBokkingConfirmation", {
      //   state: {
      //     SlotFromOnTime: SlotFromOnTime,
      //     SlotToOnTime: SlotToOnTime,
      //     ClassDate: ClassDate,
      //     ClassDuration: classDetails[0].ClassDuration,
      //     ClassSlotID: ClassSlotID,
      //     obj: location.state.items,
      //   },
      // });
    }, 500);
  };

  const [props, setProps] = React.useState({
    timeZoneDetails: { key: "", text: "" },
  });
  function onchangeProps(event, value) {
    setProps({
      ...props,
      [event.target.id]: value,
    });

    var lastSpaceIndex = value.text.lastIndexOf(" ");
    var timeZone = value.text.substring(lastSpaceIndex + 1);
    getAvailableSlot(timeZone);
  }

  const [currentYear, setCurrentYear] = useState(null);
  const [currentMonth, setCurrentMonth] = useState("");
  const [classDetails, setClassDetails] = useState([]);

  useEffect(() => {
    var text;
    var lastSpaceIndex;
    var timeZone;

    if (countryName === "India") {
      setProps({
        timeZoneDetails: {
          key: "IST",
          text: "Time shown in Local time of IST",
        },
      });

      text = "Time shown in Local time of IST";
      lastSpaceIndex = text.lastIndexOf(" ");
      timeZone = text.substring(lastSpaceIndex + 1);
    } else {
      setProps({
        timeZoneDetails: {
          key: "EST",
          text: "Time shown in Local time of EST",
        },
      });

      text = "Time shown in Local time of EST";
      lastSpaceIndex = text.lastIndexOf(" ");
      timeZone = text.substring(lastSpaceIndex + 1);
    }

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.toLocaleString("default", { month: "short" });

    setCurrentYear(currentYear);
    setCurrentMonth(currentMonth);
    getAvailableSlot(timeZone);
  }, []);

  const getAvailableSlot = (TimeZone) => {
    const oSelectFewFromClassSlotAndMastersWhereClassIDAndClassTeacherID = {
      ActionName:
        "LFN.GTra_SelectFewFromClassSlotAndMastersWhereClassIDAndClassTeacherID",
      ParameterJSON:
        '{"TeachingLanguageID":"' +
        teachingLanguageID +
        '","ClassID":"' +
        classID +
        '","ClassTeacherID":"' +
        classTeacherID +
        '","TimeZone":"' +
        TimeZone +
        '"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(
      oSelectFewFromClassSlotAndMastersWhereClassIDAndClassTeacherID
    ).then((response) => {
      if (!response.DataIsLoaded)
        return (
          <div>
            <h1> Please wait some time.... </h1>{" "}
          </div>
        );
      else {
        if (response.message === "Successfull") {
          if (response.items.length === 0) {
            $("#noSlotsAvailableModalCenter").modal("show");
          } else {
            setClassDetails(response.items);
          }
        } else {
          alert(response.message);
        }
      }
    });
  };

  const classDuration = () => {
    if (classDetails.length > 0) {
      return (
        <label style={{ color: "#949494" }}>
          {classDetails[0].ClassDuration} min class
        </label>
      );
    }
  };

  var matchingDates = [];
  matchingDates = classDetails.reduce(
    (acc, { ClassDate, SlotFromOnTime, SlotToOnTime, ClassSlotID }) => {
      if (acc[ClassDate]) {
        acc[ClassDate].push({ SlotFromOnTime, SlotToOnTime, ClassSlotID });
      } else {
        acc[ClassDate] = [{ SlotFromOnTime, SlotToOnTime, ClassSlotID }];
      }
      return acc;
    },
    {}
  );

  const AvailableDays = ({ date }) => {
    const weekDayName = moment(date).format("ddd");
    const weekDayDate = moment(date).format("DD");
    const currentDate = moment().format("DD-MMM-YYYY");

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {date === currentDate ? (
          <label style={{ color: "#0BCADC" }}>
            {weekDayDate}
            <span style={{ marginLeft: "5px" }}>{weekDayName}</span>
          </label>
        ) : (
          <label style={{ color: "#002570" }}>
            {weekDayDate}
            <span style={{ color: "#949494", marginLeft: "5px" }}>
              {weekDayName}
            </span>
          </label>
        )}
      </div>
    );
  };

  const AvailableTimeSlots = ({ dates, times }) => {
    return (
      <div>
        {times.map((time) => {
          const isDisabled =
            selectedTimeSlot !== null &&
            (selectedTimeSlot.ClassDate !== dates ||
              selectedTimeSlot.SlotFromOnTime !== time.SlotFromOnTime);
          return (
            <div
              className="timeSlot m-2 p-1 p-md-4 text-center"
              key={time.SlotFromOnTime}
              style={{
                backgroundColor:
                  selectedTimeSlot?.ClassDate === dates &&
                  selectedTimeSlot?.SlotFromOnTime === time.SlotFromOnTime
                    ? "#757575"
                    : "#04B636",
                color: "#FFFFFF",
                pointerEvents: isDisabled ? "none" : "auto",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() =>
                bookTimeSlot(
                  time.SlotFromOnTime,
                  time.SlotToOnTime,
                  dates,
                  time.ClassSlotID
                )
              }
            >
              {time.SlotFromOnTime}
            </div>
          );
        })}
      </div>
    );
  };

  const sliceParam = screenWidth < 768 ? 4 : 7;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(sliceParam);

  const onRightArrow = () => {
    setStartIndex(endIndex);
    setEndIndex(endIndex + sliceParam);
  };

  const onLeftArrow = () => {
    var prevStartIndex;
    var prevEndIndex;

    if (screenWidth < 768) {
      prevStartIndex = Math.max(0, startIndex - 4);
      prevEndIndex = Math.max(3, endIndex - 4);
    } else {
      prevStartIndex = Math.max(0, startIndex - 7);
      prevEndIndex = Math.max(6, endIndex - 7);
    }
    setStartIndex(prevStartIndex);
    setEndIndex(prevEndIndex);
  };

  const onModalClose = () => {
    //Do something
    window.location.href = "/#planPricingSection";
  };

  const onOkayButtonClick = () => {
    if (webAppName === "LearnerPortal") {
      window.location.href = "/PlanPricing";
    } else {
      window.location.href = "/#planPricingSection";
    }
  };
  const sendMail = () => {
    const mailtoUrl = `mailto:${"connect@learnfromnative.com"}?subject=${encodeURIComponent(
      "Query About Class Booking"
    )}`;
    window.location.href = mailtoUrl;
  };

  const supportPhoneNumber = "+918058058089";
  const callOnSupportPhoneNo = () => {
    const telLink = `tel:${supportPhoneNumber}`;
    window.location.href = telLink;
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content bookingConfirmation">
        <p>No slots available.</p>
        <button
          className="CTA-btn"
          style={{
            color: "white",
            backgroundColor: "#117800",
            borderColor: "#117800",
            width: "100px",
            height: "50px",
          }}
          onClick={() => onOkayButtonClick()}
        >
          Okay
        </button>
      </div>
    );
  };

  $("#bookingConfirmationModalCenter, #noSlotsAvailableModalCenter").on(
    "hidden.bs.modal",
    function () {
      onOkayButtonClick();
    }
  );

  const getConfirmationDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content bookingConfirmation">
        <p>
          Thank you for scheduling a Free Demo Class with us!
          <br />
          Your class is booked for
          <br />
          <b>{selectedTimeSlot?.ClassDate}</b> at{" "}
          <b>{selectedTimeSlot?.slotFromOnTime}</b>.
          <br />
          For any inquiries, reach out to us at{" "}
          <span
            style={{ color: "#0C9AD8", textDecoration: "underline" }}
            onClick={() => sendMail()}
          >
            connect@learnfromnative.com
          </span>{" "}
          or call
          {screenWidth < 768 ? (
            <span
              style={{ color: "#0C9AD8" }}
              onClick={() => callOnSupportPhoneNo()}
            >
              {" "}
              +918058058089
            </span>
          ) : (
            <span> +918058058089</span>
          )}
        </p>
        <button
          className="CTA-btn"
          style={{
            color: "white",
            backgroundColor: "#117800",
            borderColor: "#117800",
            width: "100px",
            height: "50px",
          }}
          onClick={() => onOkayButtonClick()}
        >
          Okay
        </button>
      </div>
    );
  };

  return (
    <>
      {/* <LeftBar/> */}
      <Header />
      <div className="bookFreeDemoCalendar content-lbl m-md-4">
        <div className="row">
          <div className="col-6 col-md-2">
            <label className="header-lbl" style={{ fontWeight: "bold" }}>
              Demo Class
            </label>
          </div>
          <div className="d-none d-md-block col-md-6 "></div>
          <div className="col-3 col-md-2">{classDuration()}</div>
          <div className="col-3 col-md-2">
            <label style={{ color: "#949494" }}>{currentMonth}</label>
            <label style={{ marginLeft: "5px", color: "#949494" }}>
              {currentYear}
            </label>
          </div>
        </div>
        <br />

        <MyDropDown
          id="timeZoneDetails"
          SelectedKey={props.timeZoneDetails.key}
          styles={dropdownStyles}
          options={timezoneOptions}
          onChange={onchangeProps}
        />

        <br />
        <div style={{ display: "flex" }}>
          {startIndex > 0 && (
            <i
              className="leftRightArrowShadow bi bi-chevron-left"
              style={{ color: "#FF3980" }}
              onClick={() => onLeftArrow()}
            ></i>
          )}

          {Object.entries(matchingDates)
            .slice(startIndex, endIndex)
            .map(([date, times]) => (
              <div key={date}>
                <div>
                  <AvailableDays date={date} />
                </div>
                <hr />
                <div>
                  <AvailableTimeSlots dates={date} times={times} />
                </div>
              </div>
            ))}

          {endIndex < Object.entries(matchingDates).length && (
            <i
              className="leftRightArrowShadow bi bi-chevron-right"
              style={{ color: "#FF3980" }}
              onClick={() => onRightArrow()}
            ></i>
          )}
        </div>
        <Login handleClick={onModalClose} />
        <Dailogue
          id={"noSlotsAvailableModalCenter"}
          body={getDailogueBody}
          title=""
        />
        <Dailogue
          id={"bookingConfirmationModalCenter"}
          body={getConfirmationDailogueBody}
          title=""
        />
      </div>
    </>
  );
}
export default FreeDemoClassBookingCalendarView;

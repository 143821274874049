import { useEffect } from "react";
import Header from "../component/Header";
import Login from "../component/Login";

function FreeDemoClassBooking() {
  var webAppName = "WebsitePortal";
  var userID;

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  const validateUser = () => {
    return new Promise<void>((resolve) => {
      var sessionData = localStorage.getItem("items");
      var sessionDetails = JSON.parse(sessionData);

      if (sessionDetails != null || sessionDetails != undefined) {
        userID = sessionDetails.UserID;
        if (userID == null || userID == "" || userID == undefined) {
          $("#loginModalCenter").modal("show");
          return;
        }
      } else {
        $("#loginModalCenter").modal("show");
        return;
      }
      resolve();
    });
  };

  useEffect(() => {
    validateUser();
  }, []);

  const freeDemoBooking = () => {
    validateUser().then(() => {
      window.location.href =
        "https://docs.google.com/forms/d/e/1FAIpQLScJTuyIMf1Lwly92axbXw25HnAL_p8U-Ux5OyS3aAvKe6OdHw/viewform?usp=sf_link";
    });
  };

  const onModalClose = () => {
    //Do something
    window.location.href = "/#planPricingSection";
  };

  return (
    <>
      <Header></Header>
      <div className="testAI">
        <div className="row justify-content-center m-3 m-md-5">
          <div className="col-md-5 text-center blogs p-3 p-md-5">
            <img src="/core/lfn-logo.png" className="m-5"></img>
            <h5>
              Thank you for Signing Up! Please fill in the form below to book a
              Free Demo Class. Our representative shall reach out to you
              shortly!
            </h5>
            <br />
            <button className="btn btn-tomato w-75" onClick={freeDemoBooking}>
              Click here
            </button>
          </div>
        </div>
      </div>
      <Login handleClick={onModalClose} />
    </>
  );
}

export default FreeDemoClassBooking;

import { useEffect, useState } from "react";
import { Execute } from "./APIService";

var emailID;
var MobileNo;

const prefillemailIDDetails = () => {
  if (emailID != null) {
    return emailID;
  } else {
    return "connect@learnfromnative.com";
  }
};

const prefillMobileNoDetails = () => {
  if (MobileNo != null) {
    return MobileNo;
  } else {
    return "8058058089";
  }
};

const loadscript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

loadscript("https://checkout.razorpay.com/v1/checkout.js");

async function RazorPay(
  obj,
  FinalCost,
  classCount,
  classDuration,
  installmentDetailsProps,
  utilizeCoinsJson,
  countryID,
  planPricingType
) {
  var webAppName = "WebsitePortal";

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  var userID;
  var sessionID;
  var userName;
  var emailID;
  var MobileNo;
  var teachingLanguageID = 1;
  var appPlatform = "Web";

  if (sessionDetails != null || sessionDetails != undefined) {
    userID = sessionDetails.UserID;
    sessionID = -1;
    userName = sessionDetails.UserName;
    emailID = sessionDetails.EmailID;
    MobileNo = sessionDetails.MobileNo;
    teachingLanguageID = 1;
  } else {
    alert("Please login and try again");
    return;
  }

  var paymentDetailsString = "";
  var paymentDetailsParseData = [];
  var planTypeDetailsString = "";
  var planTypeDetailsParseData = [];
  var teacherID = obj.TeacherID === undefined ? "" : obj.TeacherID;

  var purchaseClassCount = classCount === 0 ? "" : classCount;
  var purchaseClassMinutes = classDuration === 0 ? "" : classDuration;
  var installmentID =
    installmentDetailsProps.installmentID > 0
      ? installmentDetailsProps.installmentID
      : null;

  var utilizeCoins = JSON.stringify(JSON.stringify(utilizeCoinsJson));

  //Remove this Logic
  if (obj.PlanName != "One-on-One") {
    purchaseClassCount = "";
    purchaseClassMinutes = "";
  } else {
    if (classCount === 0) {
      purchaseClassCount = "";
    } else if (classDuration == 0) {
      purchaseClassMinutes = "";
    }
  }
  var oInsertPurchaseAndMastersNew;
  var paymentMethod = "RazorPay";

  if (installmentID === null) {
    oInsertPurchaseAndMastersNew = {
      ActionName: "LFN.GTra_InsertPurchaseAndMastersNew",
      ParameterJSON:
        '{"CountryID":"' +
        countryID +
        '","PlanID":"' +
        obj.PlanID +
        '","Currency":"' +
        obj.PlanPricingCurrency +
        '","TeacherID":"' +
        teacherID +
        '","PurchaseClassCount":"' +
        purchaseClassCount +
        '","PurchaseClassMinutes":"' +
        purchaseClassMinutes +
        '","FinalCost":"' +
        FinalCost +
        '","TeachingLanguageID":"' +
        teachingLanguageID +
        '","UtilizeCoinsJson":' +
        utilizeCoins +
        ',"InstallmentAmount":"' +
        installmentDetailsProps.installmentPerMonth +
        '","PlanPricingType":"' +
        planPricingType +
        '","PaymentMethod":"' +
        paymentMethod +
        '","PlanPricingID":"' +
        obj.PlanPricingID +
        '","RowsAffecting":"1"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"' +
        userID +
        '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
  } else {
    oInsertPurchaseAndMastersNew = {
      ActionName: "LFN.GTra_InsertPurchaseAndMastersNew",
      ParameterJSON:
        '{"CountryID":"' +
        countryID +
        '","PlanID":"' +
        obj.PlanID +
        '","Currency":"' +
        obj.PlanPricingCurrency +
        '","TeacherID":"' +
        teacherID +
        '","PurchaseClassCount":"' +
        purchaseClassCount +
        '","PurchaseClassMinutes":"' +
        purchaseClassMinutes +
        '","FinalCost":"' +
        FinalCost +
        '","TeachingLanguageID":"' +
        teachingLanguageID +
        '","UtilizeCoinsJson":' +
        utilizeCoins +
        ',"InstallmentID":"' +
        installmentID +
        '","InstallmentAmount":"' +
        installmentDetailsProps.installmentPerMonth +
        '","PlanPricingType":"' +
        planPricingType +
        '","PaymentMethod":"' +
        paymentMethod +
        '","PlanPricingID":"' +
        obj.PlanPricingID +
        '","RowsAffecting":"1"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"' +
        userID +
        '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
  }

  Execute(oInsertPurchaseAndMastersNew).then((response) => {
    if (!response.DataIsLoaded) {
      return (
        <div>
          <h1>Please wait some time...</h1>
        </div>
      );
    } else {
      if (response.message === "Successfull") {
        paymentDetailsString = JSON.stringify(response.items);
        paymentDetailsParseData = JSON.parse(JSON.parse(paymentDetailsString));
        const Options = {
          key: paymentDetailsParseData[0].PaymentGatewayPaymentAccountID,
          currency: paymentDetailsParseData[0].PaymentGatewayPaymentCurrency,
          amount: paymentDetailsParseData[0].PaymentGatewayPaymentAmount,
          description: "Learn From Native India",
          image:
            "https://testlearner.learnfromnative.com/logo-without-text.png",
          order_id: paymentDetailsParseData[0].PaymentGatewayOrderID,

          handler: function (response) {
            var PaymentGatewayUserDetail = {
              UserEmailID: emailID,
              UserContact: MobileNo,
            };

            var PaymentGatewayUserDetailJSON = JSON.stringify(
              PaymentGatewayUserDetail
            );
            var PaymentGatewayPaymentDetail = response;
            var PaymentGatewayPaymentDetailJSON = JSON.stringify(
              PaymentGatewayPaymentDetail
            );
            var PaymentGatewayPaymentResponseJSON =
              response.razorpay_payment_id;

            const oUpdatePaymentTrackingForPaymentGatewayResponse = {
              ActionName:
                "LFN.GTra_UpdatePaymentTrackingForPaymentGatewayResponse",
              ParameterJSON:
                '{"PaymentTrackingID":"' +
                paymentDetailsParseData[0].PaymentTrackingID +
                '","PurchaseID":"' +
                paymentDetailsParseData[0].PurchaseID +
                ' ","PlanPricingID":"' +
                obj.PlanPricingID +
                ' ","UtilizeCoinsJson":' +
                utilizeCoins +
                ',"PaymentGatewayUserDetailJSON  ":' +
                PaymentGatewayUserDetailJSON +
                ',"PaymentGatewayPaymentDetailJSON  ":' +
                PaymentGatewayPaymentDetailJSON +
                ',"PaymentGatewayPaymentResponseJSON":"' +
                PaymentGatewayPaymentResponseJSON +
                '","PaymentMethod":"' +
                paymentMethod +
                '","RowsAffecting":"1"}',
              SessionDataJSON:
                '{"UserID":"' +
                userID +
                '","SessionID":"' +
                sessionID +
                '","OnBehalfOfUserID":"' +
                userID +
                '","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
                appPlatform +
                '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
              OperationName: "Command",
            };
            Execute(oUpdatePaymentTrackingForPaymentGatewayResponse).then(
              (response) => {
                if (!response.DataIsLoaded) {
                  return (
                    <div>
                      <h1> Please wait some time.... </h1>{" "}
                    </div>
                  );
                } else {
                  if (response.message === "Successfull") {
                    planTypeDetailsString = JSON.stringify(response.items);
                    planTypeDetailsParseData = JSON.parse(
                      JSON.parse(planTypeDetailsString)
                    );
                    //setOrderPlanType(planTypeDetailsParseData[0].PlanType)
                    if (planTypeDetailsParseData[0].PlanType == "Individual") {
                      const oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForIndividual =
                        {
                          ActionName:
                            "LFN.GTra_SelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForIndividual",
                          ParameterJSON:
                            '{"PurchaseID":"' +
                            paymentDetailsParseData[0].PurchaseID +
                            '","PurchasePaymentID":"' +
                            paymentDetailsParseData[0].PurchasePaymentID +
                            '"}',
                          SessionDataJSON:
                            '{"UserID":"' +
                            userID +
                            '","SessionID":"' +
                            sessionID +
                            '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
                            appPlatform +
                            '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
                          OperationName: "Query",
                        };

                      Execute(
                        oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForIndividual
                      ).then((response) => {
                        if (!response.DataIsLoaded)
                          return (
                            <div>
                              <h1> Please wait some time.... </h1>{" "}
                            </div>
                          );
                        else {
                          //setPlanTypeDetails(response.items)
                          $("#paymentModalCenter").modal("show");
                        }
                      });
                    } else {
                      const oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForGroup =
                        {
                          ActionName:
                            "LFN.GTra_SelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForGroup",
                          ParameterJSON:
                            '{"PurchasePaymentID":"' +
                            paymentDetailsParseData[0].PurchasePaymentID +
                            '"}',
                          SessionDataJSON:
                            '{"UserID":"' +
                            userID +
                            '","SessionID":"' +
                            sessionID +
                            '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
                            appPlatform +
                            '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
                          OperationName: "Query",
                        };

                      Execute(
                        oSelectFewFromPurchaseAndMastersWhereStudentIDAndPurchaseIDForGroup
                      ).then((response) => {
                        if (!response.DataIsLoaded) {
                          return (
                            <div>
                              <h1> Pleses wait some time.... </h1>{" "}
                            </div>
                          );
                        } else {
                          //setPlanTypeDetails(response.items)
                          $("#paymentModalCenter").modal("show");
                        }
                      });
                    }
                    //openOrderConfirmationDialog()
                    //setPlanTypeDetails([])
                  }
                }
              }
            );
          },
          modal: {
            ondismiss: function () {
              alert("Payment Cancelled");
            },
          },
          prefill: {
            name: userName,
            email: prefillemailIDDetails(),
            contact: prefillMobileNoDetails(),
          },
          method: {
            qr: false,
          },
        };
        const paymentObject = new (window as any).Razorpay(Options);
        paymentObject.open();

        paymentObject.on("payment.failed", function (response) {
          var PaymentGatewayUserDetail = {
            UserEmailID: emailID,
            UserContact: MobileNo,
          };

          var PaymentGatewayUserDetailJSON = JSON.stringify(
            PaymentGatewayUserDetail
          );
          var PaymentGatewayPaymentDetail = response;
          var PaymentGatewayPaymentDetailJSON = JSON.stringify(
            PaymentGatewayPaymentDetail
          );
          var PaymentGatewayPaymentResponse = response;
          var PaymentGatewayPaymentResponseJSON = JSON.stringify(
            PaymentGatewayPaymentResponse
          );

          const oUpdatePaymentTrackingForPaymentGatewayResponse = {
            ActionName:
              "LFN.GTra_UpdatePaymentTrackingForPaymentGatewayResponse",
            ParameterJSON:
              '{"PaymentTrackingID":"' +
              paymentDetailsParseData[0].PaymentTrackingID +
              '","PurchaseID":"' +
              paymentDetailsParseData[0].PurchaseID +
              ' ","PlanPricingID":"' +
              obj.PlanPricingID +
              ' ","UtilizeCoinsJson":' +
              utilizeCoins +
              ',"PaymentGatewayUserDetailJSON  ":' +
              PaymentGatewayUserDetailJSON +
              ',"PaymentGatewayPaymentDetailJSON  ":' +
              PaymentGatewayPaymentDetailJSON +
              ',"PaymentGatewayPaymentResponseJSON":' +
              PaymentGatewayPaymentResponseJSON +
              ',"PaymentMethod":"' +
              paymentMethod +
              '","RowsAffecting":"1"}',
            SessionDataJSON:
              '{"UserID":"' +
              userID +
              '","SessionID":"' +
              sessionID +
              '","OnBehalfOfUserID":"' +
              userID +
              '","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"' +
              appPlatform +
              '","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
            OperationName: "Command",
          };
          Execute(oUpdatePaymentTrackingForPaymentGatewayResponse).then(
            (response) => {
              if (!response.DataIsLoaded)
                return (
                  <div>
                    <h1> Please wait some time.... </h1>{" "}
                  </div>
                );
              else {
                console.log("payment Failed responce");
              }
            }
          );
          alert(`payment.failed`);
        });
      } else {
        alert(response.message);
      }
    }
  });
}

export default RazorPay;

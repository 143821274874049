import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../component/CheckoutForm';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { selectAppDataConfigData } from '../features/appData/appDataConfigSlice';

export default function Stripe() 
{
    var webAppName = 'WebsitePortal'; 
    var stripePromise;
    //const AppDataConfig = useSelector(selectAppDataConfigData);
    
    if(webAppName==="LearnerPortal")
    {
        //const Data = AppDataConfig.appDataConfig.configurationData
        //var DataToString=JSON.stringify(Data)
        //var DataToParse=JSON.parse(DataToString)
        //const publishableKey = DataToParse.filter(obj => obj.ConfigurationName==="StripePublishableKey")
        //stripePromise = loadStripe(publishableKey[0].ConfigurationValue);
    }
    else
    {
      stripePromise = loadStripe('pk_live_51MyCpOGk6JSSQ3dEMGCzL9pOD105N0B6tgmmIZUWvJ2bWGZ5pSnDWjYHCqlLLm8xkg7ABY5B8GqsTNmdBXvwPv8R00TMxWxaEl');
    }

    const location=useLocation();
    var paymentDetailsParseData=location.state.paymentDetailsParseData
    var obj=location.state.item
    var utilizeCoinsJson=location.state.utilizeCoinsJson

    const options = 
    {
      clientSecret: paymentDetailsParseData[0].PaymentGatewayClientSecret
    };
  
    return (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm paymentDetailsParseData={paymentDetailsParseData} obj={obj} utilizeCoinsJson={utilizeCoinsJson}/>
      </Elements>
    );
};
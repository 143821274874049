import { useEffect, useState } from "react";
import { Execute } from "../Services/APIService";
import { useNavigate } from "react-router-dom";
import { colorCode } from "../utils/colors";
import Dailogue from "../Controls/Modal";
/// <reference types="jquery" />

function OneOnOnePlanPricingTutorList(props) {
  var webAppName = "WebsitePortal";
  var planPricingItems;

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);
  var GuestMobileNo = "";
  if (sessionDetails != null || sessionDetails != undefined) {
    var countryID = sessionDetails.CountryID;
    var userID = sessionDetails.UserID;
    var countryName = sessionDetails.CountryName;
    var oGuestUserDetails = sessionDetails.GuestUserDetails;
    if (oGuestUserDetails != undefined) {
      GuestMobileNo = oGuestUserDetails.GuestMobileNo;
    }
  }

  if (userID == undefined) {
    userID = -1;
  }

  // Determine the screen width of the device
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const sliceParam = screenWidth < 768 ? 4 : 4;

  const navigate = useNavigate();
  const [oneOnOnePlanPricingItems, setOneOnOnePlanPricingItems] = useState([]);
  const [countryWiseSubjects, setCountryWiseSubjects] = useState(null);
  const [activeSubject, setActiveSubject] = useState(props.selectedSubjectName);

  const countrySubjectColorCode = [
    {
      BackgroundColor: "#FFD9D9",
      Color: "#FF5858",
    },
    {
      BackgroundColor: "#FFF3CD",
      Color: "#FFC100",
    },
    {
      BackgroundColor: "#E8FFE4",
      Color: "#117800",
    },
    {
      BackgroundColor: "#FFEAD1",
      Color: "#FF8B00",
    },
  ];

  const userImgPath =
    "https://liveapi.learnfromnative.com/documents/User Photos/";
  const userCountryPath =
    "https://liveapi.learnfromnative.com/documents/Country/";

  const [planOfferingItems, setPlanOfferingItems] = useState([]); //Make this one

  useEffect(() => {
    //oneOneOne Plan
    const oSelectFewFromPlanAndMastersWherePlanTypeIsIndividualNew = {
      ActionName:
        "LFN.GMst_SelectFewFromPlanAndMastersWherePlanTypeIsIndividualNew",
      ParameterJSON:
        '{"CountryID ":"' +
        countryID +
        '","TeachingLanguageID ":"' +
        1 +
        '","GuestMobileNo":"' +
        GuestMobileNo +
        '"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromPlanAndMastersWherePlanTypeIsIndividualNew).then(
      (response) => {
        if (response.DataIsLoaded) {
          setOneOnOnePlanPricingItems(response.items);
        }
      }
    );

    //subject
    const oSelectFewFromSubjectAndMasterWhereCountryID = {
      ActionName: "LFN.GMst_SelectFewFromSubjectAndMasterWhereCountryID",
      ParameterJSON: '{"CountryID ":"' + countryID + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromSubjectAndMasterWhereCountryID).then((response) => {
      if (response.DataIsLoaded) {
        setCountryWiseSubjects(response.items);
      }
    });
  }, []);

  if (props.filterData == true) {
    //HomeScreen
    planPricingItems = props.ShowAllTutorList
      ? oneOnOnePlanPricingItems.filter(
          (obj) => obj.SubjectName == props.selectedSubjectName
        )
      : oneOnOnePlanPricingItems
          .filter((obj) => obj.SubjectName == props.selectedSubjectName)
          .slice(0, sliceParam);
  } else {
    //PlanPricingTutorList
    planPricingItems = props.ShowAllTutorList
      ? oneOnOnePlanPricingItems.filter(
          (obj) => obj.SubjectName == activeSubject
        )
      : oneOnOnePlanPricingItems
          .filter((obj) => obj.SubjectName == activeSubject)
          .slice(0, sliceParam);
  }

  const getPlanOffering = async (PlanID) => {
    const oSelectFewFromPlanOfferingsWherePlanIDAndNonDeleted = {
      ActionName: "LFN.GMst_SelectFewFromPlanOfferingsWherePlanIDAndNonDeleted",
      ParameterJSON: '{"PlanID ":"' + PlanID + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    const response = await Execute(
      oSelectFewFromPlanOfferingsWherePlanIDAndNonDeleted
    );
    if (!response.DataIsLoaded) {
      return (
        <div>
          <h1>Please wait some time....</h1>
        </div>
      );
    } else {
      setPlanOfferingItems(response.items);
      return response.items; //NewValue
    }
  };

  const getTutorProfile = async (obj, type) => {
    const planOfferingDetails = await getPlanOffering(obj.PlanID);

    navigate("/TutorProfile", {
      state: {
        tutorProfileDetails: obj,
        planOfferingItems: planOfferingDetails,
        type: type,
      },
    });
  };

  const handleClick = () => {
    //window.location.href = "/PlanPricingTutorList";
    navigate("/PlanPricingTutorList", {
      state: { tutorSelectedSubjectName: props.selectedSubjectName },
    });
  };

  const handleOneOnOnePayNow = (obj, type) => {
    if (type == "Book a Free Demo") {
      navigate("/FreeBillingInvoice", { state: { tutorProfileDetails: obj } });
    } else {
      navigate("/BillingInvoice", { state: { tutorProfileDetails: obj } });
    }
  };
  var oFilterSubject = [];

  const getPricingInformation = () => {
    return (
      <div>
        <h6>This reflects the regular class pricing. Demo classes are Free.</h6>
      </div>
    );
  };

  const getSelectSubject = (subjectName) => {
    // if (subjectName == "Hindi") {
    //   $("#languageOfferedModalCenter").modal("show");
    //   return;
    // }
    setActiveSubject(subjectName);
  };

  var storeSubject;
  if (props.filterData == true) {
    storeSubject = props.selectedSubjectName;
  } else {
    storeSubject = activeSubject;
  }

  oFilterSubject = oneOnOnePlanPricingItems.filter(
    (obj) => obj.SubjectName != storeSubject
  );

  let oAllTutorItems = oneOnOnePlanPricingItems
    .concat(oFilterSubject)
    .sort(function (a, b) {
      if (a.SubjectName == storeSubject) {
        return -1;
      }
      if (b.SubjectName == storeSubject) {
        return 1;
      }
      return 0;
    });

  var oTeacherID = [];
  oAllTutorItems.map((obj) => {
    if (!oTeacherID.some((item) => item.TeacherID === obj.TeacherID)) {
      oTeacherID.push(obj);
    }
  });

  oTeacherID.sort(function (a, b) {
    if (a.TeacherRating == storeSubject) {
      return -1;
    }
    if (b.SubjectName == storeSubject) {
      return 1;
    }
    return 0;
  });

  var sliceItems = [];
  if (!props.ShowAllTutorList) {
    oTeacherID = oTeacherID.slice(0, sliceParam);
  }

  const onTeachingSubjectInfo = () => {
    $("#pricingInformationModalCenter").modal("show");
  };
  const getDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content">
        <img src="/assest/images/lfn-logo.png" className="m-5"></img>
        <h3 className="mb-5">
          Currently, we are offering English Language Courses.
        </h3>
        <h3>
          French, Spanish, German, Hindi, IELTS Training, <br />
          and much more
          <br /> coming soon!
        </h3>
      </div>
    );
  };

  return (
    <>
      {props.ShowAllTutorList ? (
        countryName == "India" ? (
          <div className="countryWiseSubject  ms-3 me-3 ms-md-5 me-md-5 mt-3 mb-3 d-flex justify-content-between overflow-auto">
            {countryWiseSubjects &&
              countryWiseSubjects.map((obj, index) => (
                <button
                  key={index}
                  className={`btn-subject p-md-2 ${
                    index !== countryWiseSubjects.length - 1 ? "me-3" : ""
                  }`}
                  style={{
                    backgroundColor: `${
                      countrySubjectColorCode[
                        index % countrySubjectColorCode.length
                      ].BackgroundColor
                    }`,
                    color: `${
                      countrySubjectColorCode[
                        index % countrySubjectColorCode.length
                      ].Color
                    }`,
                    border:
                      activeSubject === obj.SubjectName
                        ? `4px solid ${
                            countrySubjectColorCode[
                              index % countrySubjectColorCode.length
                            ].Color
                          }`
                        : "none",
                  }}
                  onClick={() => getSelectSubject(obj.SubjectName)}
                >
                  {obj.SubjectName}
                </button>
              ))}
          </div>
        ) : (
          <div className="countryWiseSubject  ms-2 me-2 mt-3 d-flex justify-content-between overflow-auto">
            {countryWiseSubjects &&
              countryWiseSubjects.map((obj, index) => (
                <button
                  key={index}
                  className={`btn-subject p-md-2 ${
                    index !== countryWiseSubjects.length - 1 ? "me-3" : ""
                  }`}
                  style={{
                    backgroundColor: `${
                      countrySubjectColorCode[
                        index % countrySubjectColorCode.length
                      ].BackgroundColor
                    }`,
                    color: `${
                      countrySubjectColorCode[
                        index % countrySubjectColorCode.length
                      ].Color
                    }`,
                    border:
                      activeSubject === obj.SubjectName
                        ? `4px solid ${
                            countrySubjectColorCode[
                              index % countrySubjectColorCode.length
                            ].Color
                          }`
                        : "none",
                  }}
                  onClick={() => getSelectSubject(obj.SubjectName)}
                >
                  {obj.SubjectName}
                </button>
              ))}
          </div>
        )
      ) : null}
      <div
        className={props.ShowAllTutorList ? "" : "teacherPlanPricingList"}
        id="oneOnOnePlan"
      >
        <div
          className={
            props.ShowAllTutorList ? "" : "teacherPlanPricingList-content"
          }
        >
          {oTeacherID.map((firstItemInGroup, groupIndex) => {
            return (
              <>
                {/* New UI Code */}
                <div
                  className={
                    props.ShowAllTutorList
                      ? "teacherPlanPricingList teacherPlanPricingList-content mb-4 borderColorOnHoverTeacherPlanPricingList"
                      : ""
                  }
                >
                  <div className="row mb-2" key={groupIndex}>
                    <div className="col-md-8 d-none d-md-flex  align-items-center ">
                      <div
                        style={{ display: "inline-block" }}
                        className="circle-image-container"
                      >
                        <img
                          className="circle-image"
                          src={`${userImgPath}${firstItemInGroup.TeacherID}.png`}
                          style={{
                            borderRadius: "50%",
                            border: `10px solid ${
                              colorCode[groupIndex % colorCode.length]
                            }`,
                          }}
                        ></img>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                          marginLeft: "35px",
                        }}
                        className="tutorInfo"
                      >
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          className="mb-1"
                        >
                          <h4 style={{ margin: "0px 10px 0px 0px" }}>
                            <b>{firstItemInGroup.TeacherName}</b>
                          </h4>

                          <div
                            className="tutorRating d-none d-md-flex"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "30px",
                            }}
                          >
                            <img
                              src="/core/star.png"
                              style={{
                                width: "28px",
                                height: "28px",
                                marginRight: "2px",
                              }}
                              alt="Star"
                            ></img>
                            <p className="p-0 m-0">
                              {firstItemInGroup.TeacherRating}
                            </p>
                          </div>

                          <img
                            src={`${userCountryPath}${firstItemInGroup.CountryID}.png`}
                            style={{ width: "30px", height: "30px" }}
                            alt="Country Flag"
                            className="d-none d-md-block ms-3"
                          ></img>
                        </div>

                        <div className="d-none d-md-block">
                          <p className="mb-0 fs-4" id="tutorExperience">
                            {firstItemInGroup.Experience +
                              "+ year's experience"}
                          </p>

                          <p className="mb-2">
                            <span
                              onClick={() =>
                                getTutorProfile(
                                  firstItemInGroup,
                                  firstItemInGroup.CardType == "Book Demo Class"
                                    ? "Book a Free Demo Class"
                                    : "Book A Class"
                                )
                              }
                            >
                              More Details
                            </span>
                          </p>

                          <h4 className="m-0 p-0 fw-bolder d-flex align-items-center">
                            {firstItemInGroup.CardType === "Book Demo Class" ? (
                              firstItemInGroup.PlanDemoPricing > 0 ? (
                                <>
                                  <h4 className="m-0 p-0 fw-bold  d-none d-md-block">
                                    {(firstItemInGroup.PlanPricingCurrency ==
                                    "INR"
                                      ? firstItemInGroup.PlanPricingCurrency +
                                        " "
                                      : "$") + firstItemInGroup.PlanDemoPricing}
                                    <small className="text-200">
                                      {" per " +
                                        firstItemInGroup.PlanPerClassMinutes +
                                        "-min Lesson"}
                                    </small>
                                  </h4>
                                </>
                              ) : (
                                <>
                                  <h4 className="m-0 p-0 fw-bold  d-none d-md-block">
                                    {(firstItemInGroup.PlanPricingCurrency ==
                                    "INR"
                                      ? firstItemInGroup.PlanPricingCurrency +
                                        " "
                                      : "$") +
                                      firstItemInGroup.PlanOriginalPricing}
                                    <small className="text-200">
                                      {" per " +
                                        firstItemInGroup.PlanPerClassMinutes +
                                        "-min Lesson"}
                                    </small>
                                  </h4>
                                  {
                                    <img
                                      src="/assest/images/icons/info.png"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginLeft: "15px",
                                      }}
                                      onClick={onTeachingSubjectInfo}
                                    ></img>
                                  }
                                </>
                              )
                            ) : (
                              <>
                                {(firstItemInGroup.PlanPricingCurrency == "INR"
                                  ? firstItemInGroup.PlanPricingCurrency + " "
                                  : "$") + firstItemInGroup.PlanOriginalPricing}
                                <small className="text-200 ms-2">
                                  {" per " +
                                    firstItemInGroup.PlanPerClassMinutes +
                                    "-min Lesson"}
                                </small>
                              </>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>

                    {/**Mobile Device */}
                    <div className="col-8 d-md-none d-flex  align-items-center">
                      <div
                        style={{ display: "inline-block" }}
                        className="circle-image-container"
                      >
                        <img
                          className="circle-image"
                          src={`${userImgPath}${firstItemInGroup.TeacherID}.png`}
                          style={{
                            borderRadius: "50%",
                            border: `10px solid ${
                              colorCode[groupIndex % colorCode.length]
                            }`,
                          }}
                        ></img>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                          marginLeft: "45px",
                        }}
                        className="tutorInfo"
                      >
                        <div
                          style={{ alignItems: "center" }}
                          className="mb-0 mb-md-2"
                        >
                          <h4 style={{ marginRight: "10px" }}>
                            <b>{firstItemInGroup.TeacherName}</b>
                          </h4>
                        </div>
                        <div
                          className="tutorRating"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src="/core/star.png"
                            style={{
                              width: "28px",
                              height: "28px",
                              marginRight: "2px",
                            }}
                            alt="Star"
                          />
                          <p className="p-0 m-0">
                            {firstItemInGroup.TeacherRating}
                          </p>

                          <img
                            src={`${userCountryPath}${firstItemInGroup.CountryID}.png`}
                            style={{ width: "30px", height: "30px" }}
                            alt="Country Flag"
                            className="ms-4"
                          ></img>
                        </div>
                      </div>
                    </div>
                    {firstItemInGroup.CardType == "Book Demo Class" &&
                      firstItemInGroup.PlanDemoPricing == 0 && (
                        <div className="col-4 d-flex justify-content-end align-items-start align-items-md-center d-md-none d-block">
                          <span className="bg-dark text-light rounded p-1 text-decoration-none text-center  freeDemoTag">
                            Free Demo
                          </span>
                        </div>
                      )}

                    <div className="col-md-4">
                      <div className="row h-50 d-none d-md-block">
                        <div className="col d-flex justify-content-end ">
                          {firstItemInGroup.CardType == "Book Demo Class" &&
                            firstItemInGroup.PlanDemoPricing == 0 && (
                              <div className="">
                                <span className="bg-dark text-light rounded p-1 text-decoration-none text-center d-block d-sm-none freeDemoTag">
                                  Free Demo
                                </span>
                                <span className="bg-dark text-light rounded p-2 text-decoration-none text-center w-100 d-none d-sm-block">
                                  Free Demo
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="row d-none d-md-block">
                        <div className="col d-flex justify-content-end">
                          {firstItemInGroup.CardType == "Book Demo Class" ? (
                            firstItemInGroup.NoOfSlotsAvailableFromClassSlot >
                            0 ? (
                              <p className="NoOfSpotsLeftHeading mb-md-1">
                                Only{" "}
                                {
                                  firstItemInGroup.NoOfSlotsAvailableFromClassSlot
                                }{" "}
                                spots left!
                              </p>
                            ) : firstItemInGroup.NoOfSlotsAvailableFromPlanPricing !==
                              null ? (
                              <p className="NoOfSpotsLeftHeading  mb-md-1">
                                Only{" "}
                                {
                                  firstItemInGroup.NoOfSlotsAvailableFromPlanPricing
                                }{" "}
                                spots left!
                              </p>
                            ) : (
                              <div
                                className=""
                                style={{ marginBottom: "30px" }}
                              ></div>
                            )
                          ) : (
                            <div
                              className=""
                              style={{ marginBottom: "30px" }}
                            ></div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col d-flex justify-content-end m-0">
                          <button
                            className="btn btn-mayGreen btn-payNow p-2 d-none d-md-flex text-center justify-content-center w-75"
                            onClick={() =>
                              handleOneOnOnePayNow(
                                firstItemInGroup,
                                firstItemInGroup.CardType == "Book Demo Class"
                                  ? "Book a Free Demo"
                                  : "Book A Class"
                              )
                            }
                          >
                            {firstItemInGroup.CardType == "Book Demo Class"
                              ? firstItemInGroup.PlanDemoPricing > 0
                                ? "Book a Trial Class"
                                : "Book a Free Demo"
                              : "Pay Now"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Mobile Device */}
                  <div className="row d-block d-md-none">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-7 ">
                          <p className="mb-0 fs-4" id="tutorExperience">
                            {firstItemInGroup.Experience +
                              "+ year's experience"}
                          </p>
                          <p className="mb-1">
                            <span
                              onClick={() =>
                                getTutorProfile(
                                  firstItemInGroup,
                                  firstItemInGroup.CardType == "Book Demo Class"
                                    ? "Book a Free Demo Class"
                                    : "Book A Class"
                                )
                              }
                            >
                              More Details
                            </span>
                          </p>

                          <div className="">
                            <h4 className="m-0 p-0 fw-bolder d-flex align-items-center">
                              {firstItemInGroup.CardType ===
                              "Book Demo Class" ? (
                                firstItemInGroup.PlanDemoPricing > 0 ? (
                                  <>
                                    <h4 className="m-0 p-0 fw-bold  d-block d-md-none">
                                      {(firstItemInGroup.PlanPricingCurrency ==
                                      "INR"
                                        ? firstItemInGroup.PlanPricingCurrency +
                                          " "
                                        : "$") +
                                        firstItemInGroup.PlanDemoPricing}
                                      <p className="text-200 m-0">
                                        {" per " +
                                          firstItemInGroup.PlanPerClassMinutes +
                                          "-min Lesson"}
                                      </p>
                                    </h4>
                                  </>
                                ) : (
                                  <>
                                    <h4 className="m-0 p-0 fw-bold  d-block d-md-none">
                                      {(firstItemInGroup.PlanPricingCurrency ==
                                      "INR"
                                        ? firstItemInGroup.PlanPricingCurrency +
                                          " "
                                        : "$") +
                                        firstItemInGroup.PlanOriginalPricing}
                                      <p className="text-200 m-0">
                                        {" per " +
                                          firstItemInGroup.PlanPerClassMinutes +
                                          "-min Lesson"}
                                      </p>
                                    </h4>

                                    {
                                      <img
                                        src="/assest/images/icons/info.png"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          marginLeft: "15px",
                                        }}
                                        onClick={onTeachingSubjectInfo}
                                      ></img>
                                    }
                                  </>
                                )
                              ) : (
                                <>
                                  {(firstItemInGroup.PlanPricingCurrency ==
                                  "INR"
                                    ? firstItemInGroup.PlanPricingCurrency + " "
                                    : "$") +
                                    firstItemInGroup.PlanOriginalPricing}
                                  <small className="text-200 ms-2">
                                    {" per " +
                                      firstItemInGroup.PlanPerClassMinutes +
                                      "-min Lesson"}
                                  </small>
                                </>
                              )}
                            </h4>

                            <p className="m-0 p-0 d-none d-md-block">
                              {firstItemInGroup.PlanPerClassMinutes +
                                " - min class"}
                            </p>
                          </div>
                        </div>
                        <div className="col-5  d-flex flex-column justify-content-end align-items-end">
                          <div className="justify-content-end align-items-end">
                            {firstItemInGroup.CardType == "Book Demo Class" ? (
                              firstItemInGroup.NoOfSlotsAvailableFromClassSlot >
                              0 ? (
                                <p className="NoOfSpotsLeftHeading m-0 mb-md-3">
                                  Only{" "}
                                  {
                                    firstItemInGroup.NoOfSlotsAvailableFromClassSlot
                                  }{" "}
                                  spots left!
                                </p>
                              ) : firstItemInGroup.NoOfSlotsAvailableFromPlanPricing !==
                                null ? (
                                <p className="NoOfSpotsLeftHeading m-0 mb-md-3">
                                  Only{" "}
                                  {
                                    firstItemInGroup.NoOfSlotsAvailableFromPlanPricing
                                  }{" "}
                                  spots left!
                                </p>
                              ) : null
                            ) : null}
                          </div>

                          <button
                            className="btn btn-mayGreen btn-payNow p-2 d-none d-md-flex text-center justify-content-center"
                            onClick={() =>
                              handleOneOnOnePayNow(
                                firstItemInGroup,
                                firstItemInGroup.CardType == "Book Demo Class"
                                  ? "Book a Free Demo"
                                  : "Book A Class"
                              )
                            }
                          >
                            {firstItemInGroup.CardType == "Book Demo Class"
                              ? firstItemInGroup.PlanDemoPricing > 0
                                ? "Book a Trial Class"
                                : "Book a Free Demo"
                              : "Pay Now"}
                          </button>
                        </div>
                        <div className="col-12  d-block d-md-none mt-2">
                          <button
                            className="btn btn-mayGreen btn-payNow p-1 w-100 text-center"
                            onClick={() =>
                              handleOneOnOnePayNow(
                                firstItemInGroup,
                                firstItemInGroup.CardType == "Book Demo Class"
                                  ? "Book a Free Demo"
                                  : "Book A Class"
                              )
                            }
                          >
                            {firstItemInGroup.CardType == "Book Demo Class"
                              ? firstItemInGroup.PlanDemoPricing > 0
                                ? "Book a Trial Class"
                                : "Book a Free Demo"
                              : "Pay Now"}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {groupIndex !== Object.values(oTeacherID).length - 1 &&
                  !props.ShowAllTutorList && <hr className="" />}
              </>
            );
          })}
        </div>
        {props.ShowAllTutorList ? null : (
          <button
            className="btn btn-tomato w-100 btn-PlanPricing"
            onClick={handleClick}
          >
            See More
          </button>
        )}
      </div>
      <Dailogue
        id={"pricingInformationModalCenter"}
        body={getPricingInformation}
        title="Information"
        staticBackdrop
        isClose
      />
      <Dailogue
        id={"languageOfferedModalCenter"}
        body={getDailogueBody}
        title=""
      />
    </>
  );
}
export default OneOnOnePlanPricingTutorList;

import { useEffect, useState } from "react";

function HowLearnFromNativeWorks() {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }
  var sections = [];

  if (countryName == "India") {
    sections = [
      {
        heading: "Book a Free Demo",
        paragraph:
          "Begin with a comprehensive assessment of your English language skills through the free demo class conducted by our expert Tutor.",
      },
      {
        heading: "Personalize the Lesson Plan",
        paragraph:
          "Based on the assessment results, with the help of our AI Tool we create a customized learning plan tailored to your specific needs.",
      },
      {
        heading: "Live Virtual Classes",
        paragraph:
          "Participate in interactive classes led by our experienced English tutors, who make learning fun and engaging.",
      },
      {
        heading: "Accomplish & Witness Results",
        paragraph:
          "Track your progress through our regular assessments and feel confident as you progress in your English language course and achieve your language goals.",
      },
    ];
  } else {
    sections = [
      {
        heading: "Book a Free Demo",
        paragraph:
          "Begin with a comprehensive assessment of your subject  skills through the free demo class conducted by our expert Tutor.",
      },
      {
        heading: "Personalize the Lesson Plan",
        paragraph:
          "Based on the assessment results, with the help of our AI Tool we create a customized learning plan tailored to your specific needs.",
      },
      {
        heading: "Live Virtual Classes",
        paragraph:
          "Participate in interactive classes led by our experienced tutors, who make learning fun and engaging.",
      },
      {
        heading: "Accomplish & Witness Results",
        paragraph:
          "Track your progress through our regular assessments and  feel confident as you progress in your  course and achieve your proficiency goals.",
      },
    ];
  }

  return (
    <>
      <div className="howLearnFromNativeWorks" id="howLearnFromNativeWorks">
        <div className="row mb-md-5 mb-4">
          <h1 className="heading">Discover how Learn From Native works</h1>
        </div>
        <div className="howLearnFromNativeWorks-container">
          <div className="col">
            <div className="row">
              <div className="col-6 col-md-7 d-flex justify-content-center">
                <div className="image-container m-md-auto">
                  <img src="/assest/images/Assess.png" alt="Icon"></img>
                  <div className="vertical-dotted-line d-md-none"></div>
                </div>
              </div>
              <div className="col-6 col-md-4 d-flex flex-column justify-content-md-center">
                <h2 className="assess-heading">{sections[0].heading}</h2>
                <p>{sections[0].paragraph}</p>
              </div>
            </div>

            <div className="row d-none d-md-block">
              <div className="col-6 col-md-7 d-flex justify-content-center">
                <div className="vertical-dotted-line"></div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-7 d-flex justify-content-center">
                <div className="image-container m-md-auto">
                  <img src="/assest/images/Personalize.png" alt="Icon"></img>
                  <div className="vertical-dotted-line d-md-none"></div>
                </div>
              </div>
              <div className="col-6 col-md-4 d-flex flex-column justify-content-md-center">
                <h2 className="personalize-heading">{sections[1].heading}</h2>
                <p>{sections[1].paragraph}</p>
              </div>
            </div>
            <div className="row d-none d-md-block">
              <div className="col-6 col-md-7 d-flex justify-content-center">
                <div className="vertical-dotted-line"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-7 d-flex justify-content-center">
                <div className="image-container m-md-auto">
                  <img src="/assest/images/Engage.png" alt="Icon"></img>
                  <div className="vertical-dotted-line d-md-none"></div>
                </div>
              </div>
              <div className="col-6 col-md-4 d-flex flex-column justify-content-md-center">
                <h2 className="engage-heading">{sections[2].heading}</h2>
                <p>{sections[2].paragraph}</p>
              </div>
            </div>
            <div className="row d-none d-md-block">
              <div className="col-6 col-md-7 d-flex justify-content-center">
                <div className="vertical-dotted-line"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-7 d-flex justify-content-center">
                <div className="image-container m-md-auto">
                  <img src="/assest/images/Accomplish.png" alt="Icon"></img>
                </div>
              </div>
              <div className="col-6 col-md-4 d-flex flex-column justify-content-md-center">
                <h2 className="accomplish-heading">{sections[3].heading}</h2>
                <p>{sections[3].paragraph}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowLearnFromNativeWorks;

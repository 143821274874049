import { useEffect, useState } from "react";
import { Execute } from "../Services/APIService";
import MyTextField from "../Controls/MyTextField";
import { ITextFieldStyles } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import Header from "../component/Header";

function TestAIApp() {
  const [file, setFile] = useState(null);

  const [createAppResponse, setCreateAppResponse] = useState(null);
  const navigate = useNavigate();
  const [item, setItem] = useState({ PromptMessage: "", EmailID: "", URL: "" });
  const [fileContentBase64, setFileContentBase64] = useState(
    "Q2hhcmdlcw0KVG8gY2hhcmdlIGEgY3JlZGl0IG9yIGEgZGViaXQgY2FyZCwgeW91IGNyZWF0ZSBhIENoYXJnZSBvYmplY3QuIFlvdSBjYW4gcmV0cmlldmUgYW5kIHJlZnVuZCBpbmRpdmlkdWFsIGNoYXJnZXMgYXMgd2VsbCBhcyBsaXN0IGFsbCBjaGFyZ2VzLiBDaGFyZ2VzIGFyZSBpZGVudGlmaWVkIGJ5IGEgdW5pcXVlLCByYW5kb20gSUQuDQpSZWxhdGVkIGd1aWRlOiBBY2NlcHQgYSBwYXltZW50IHdpdGggdGhlIENoYXJnZXMgQVBJLg0KV2FzIHRoaXMgc2VjdGlvbiBoZWxwZnVsP1llc05vDQoJDQpUaGUgY2hhcmdlIG9iamVjdA0KQXR0cmlidXRlcw0K4oCiCQ0KaWRzdHJpbmcNClVuaXF1ZSBpZGVudGlmaWVyIGZvciB0aGUgb2JqZWN0Lg0K4oCiCQ0KYW1vdW50cG9zaXRpdmUgaW50ZWdlciBvciB6ZXJvDQpBbW91bnQgaW50ZW5kZWQgdG8gYmUgY29sbGVjdGVkIGJ5IHRoaXMgcGF5bWVudC4gQSBwb3NpdGl2ZSBpbnRlZ2VyIHJlcHJlc2VudGluZyBob3cgbXVjaCB0byBjaGFyZ2UgaW4gdGhlIHNtYWxsZXN0IGN1cnJlbmN5IHVuaXQgKGUuZy4sIDEwMCBjZW50cyB0byBjaGFyZ2UgJDEuMDAgb3IgMTAwIHRvIGNoYXJnZSDCpTEwMCwgYSB6ZXJvLWRlY2ltYWwgY3VycmVuY3kpLiBUaGUgbWluaW11bSBhbW91bnQgaXMgJDAuNTAgVVMgb3IgZXF1aXZhbGVudCBpbiBjaGFyZ2UgY3VycmVuY3kuIFRoZSBhbW91bnQgdmFsdWUgc3VwcG9ydHMgdXAgdG8gZWlnaHQgZGlnaXRzIChlLmcuLCBhIHZhbHVlIG9mIDk5OTk5OTk5IGZvciBhIFVTRCBjaGFyZ2Ugb2YgJDk5OSw5OTkuOTkpLg0K4oCiCQ0KYmFsYW5jZV90cmFuc2FjdGlvbnN0cmluZw0KRVhQQU5EQUJMRQ0KSUQgb2YgdGhlIGJhbGFuY2UgdHJhbnNhY3Rpb24gdGhhdCBkZXNjcmliZXMgdGhlIGltcGFjdCBvZiB0aGlzIGNoYXJnZSBvbiB5b3VyIGFjY291bnQgYmFsYW5jZSAobm90IGluY2x1ZGluZyByZWZ1bmRzIG9yIGRpc3B1dGVzKS4NCuKAogkNCmJpbGxpbmdfZGV0YWlsc2hhc2gNCkJpbGxpbmcgaW5mb3JtYXRpb24gYXNzb2NpYXRlZCB3aXRoIHRoZSBwYXltZW50IG1ldGhvZCBhdCB0aGUgdGltZSBvZiB0aGUgdHJhbnNhY3Rpb24uDQpTaG93IGNoaWxkIGF0dHJpYnV0ZXMNCuKAogkNCmN1cnJlbmN5Y3VycmVuY3kNCg0K"
  );
  var selectedFile = "";
  const [fileContent, setFileContent] = useState(null);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const getDataSource = () => {
    var promptMessage = item.PromptMessage;
    var emailID = item.EmailID;
    if (item.EmailID == "") {
      alert("Please Enter EmailID");
      return;
    } else if (file == null) {
      alert("Please Upload file to interact with AI");
      return;
    } else {
      const formData = new FormData();
      formData.append("user_email", emailID);
      formData.append("data_source", file);
      if (promptMessage.trim() !== "") {
        formData.append("prompt", promptMessage);
      }

      fetch("https://api.berri.ai/create_app", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.hasOwnProperty("error_message")) {
            alert(data.error_message);
            return;
          } else {
            let websiteEndPointURL = data.website_endpoint;
            navigate("/TestAI", {
              state: { websiteEndPointURL: websiteEndPointURL },
            });
            // Do something with endpoint variable
          }
        });
    }
  };

  function handleChange(event) {
    setItem({ ...item, [event.target.id]: event.target.value });
  }

  var textFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: {
      width: "100%",
      height: 45,
      borderRadius: "5px",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
      border: "none",
    },

    field: { fontSize: "16px", fontWeight: "bold" },
  };

  return (
    <>
      <Header active="TestAI"></Header>
      <div className="testAI  m-md-5">
        <h1 className="m-3">ChatBuddy</h1>
        <div className="row">
          <div className="col-md-12 p-5">
            <div className="form-group">
              <label>Email address</label>

              <MyTextField
                styles={textFieldStyles}
                CaptionText=""
                id="EmailID"
                value={item.EmailID}
                description="EmailID"
                onChange={handleChange}
              />
            </div>
            <br />

            <div className="form-group">
              <label htmlFor="exampleFormControlFile1">Data Source</label>
              <div className="data-source w-100">
                <input
                  type="file"
                  className="form-control p-2"
                  id="exampleFormControlFile1"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="template-txt">
                Download Template from here{" "}
                <a href="/TestAI.txt" download>
                  Test AI
                </a>
              </label>
            </div>
            <br />
            <div className="form-group">
              <label>Prompt (Optional )</label>
              <MyTextField
                styles={textFieldStyles}
                placeholder="For Eg You are an AI Tutor bot"
                CaptionText=""
                id="PromptMessage"
                value={item.PromptMessage}
                description="PromptMessage"
                onChange={handleChange}
              />
            </div>
            <br />

            <button className="btn btn-primary w-100" onClick={getDataSource}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestAIApp;

import { useEffect, useState } from "react";

function KidsCountController() {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  const [downloadCount, setDownloadCount] = useState(0);
  const [dailyUserCount, setDailyUserCount] = useState(0);
  const [englishLearnerEmpowered, setEnglishLearnerEmpowered] = useState(0);

  const targetDownloadCount = 20000;
  const targetDailyUserCount = 8;
  const targetEnglishLearnersEmpowered = 150500;
  var statsContent = [];
  if (countryName == "India") {
    statsContent = [
      {
        firstStats: "Live English Classes Making a Real Difference",
        secondStats:
          "Countries Reached: Experience Quality English Education Globally!",
      },
    ];
  } else {
    statsContent = [
      {
        firstStats: "Live Classes Making a Real Difference",
        secondStats:
          "Countries Reached: Experience Quality Education Globally!",
      },
    ];
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (downloadCount < targetDownloadCount) {
        setDownloadCount((prevCount) => prevCount + 1000);
      }

      if (dailyUserCount < targetDailyUserCount) {
        setDailyUserCount((prevCount) => prevCount + 1);
      }

      // if (englishLearnerEmpowered < targetEnglishLearnersEmpowered)
      // {
      //   setEnglishLearnerEmpowered(prevCount => prevCount + 5000);
      // }
    }, 80); // Change the interval duration as needed to control the animation speed

    return () => clearInterval(interval);
  }, [downloadCount, dailyUserCount, englishLearnerEmpowered]);

  return (
    <div className="kids-content">
      <div className="row  text-center mb-3">
        <div className="col d-flex flex-column align-items-center text-center">
          <h1 className="kids-count appDownloadCount text-center ">
            {downloadCount}+
          </h1>
          <p className="text-center">{statsContent[0].firstStats}</p>
        </div>
        <div className="col d-flex flex-column align-items-center text-center">
          <h1 className="kids-count dailyUserCount">{dailyUserCount}+</h1>
          <p className="text-center">{statsContent[0].secondStats} </p>
        </div>
        {/* <div className="col-4 d-flex flex-column align-items-center text-center">
                     <h1 className="kids-count dailyUserCount">{englishLearnerEmpowered}+</h1>
                     <p className="text-center">English Learners Empowered with Learn From Native’s Impactful English Education </p>
                 </div> */}
      </div>
    </div>
  );
}
export default KidsCountController;

import React from 'react'
import { TextField, ITextFieldStyles, Label } from '@fluentui/react';

function MyTextField(props)
{
    var textFieldStyles: Partial<ITextFieldStyles> =
    {
        fieldGroup: { width: 300, margin: "auto" },
        field :{fontSize:"20px"}
    };
   
    return (
        <div>
            <Label>{props.CaptionText}</Label>
            <TextField id={props.id}
                value={props.value}
                type={props.type}
                styles={props.styles?props.styles:textFieldStyles}
                placeholder={props.placeholder}
                onChange={props.onChange}
                className={props.className}
                defaultValue={props.defaultValue}
                onClick={props.onClick}
                disabled={props.disabled}
         />
        </div>
    );
}
export default MyTextField;
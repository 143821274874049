import { Modal } from "@fluentui/react";
import Dailogue from "../Controls/Modal";
import { useRef } from "react";

function HighImpactTutoringBanner(props) {
  const handleKnowMore = () => {
    window.location.href = "/kids/#HighTutoringImpact";
  };
  const ref = useRef(null);

  const handleClick = () => {
    window.location.href = "/Kids/#highTutoringImpact";
  };

  return (
    <>
      <div className="highImpactTutoringBanner">
        <div className="highImpactTutoringBanner-content">
          <div className="row d-flex align-items-center text-center text-md-start p-0">
            <div className="col-12 col-md-4 order-1 order-md-1 d-flex justify-content-center justify-content-md-center">
              <img
                src="/assest/images/HIT-BannerImg.png"
                className="w-100"
                id="web-banner"
              ></img>
              <img
                src="/assest/images/HIT-BannerImg.png"
                className="w-50 d-none mb-2"
                id="mob-banner"
              ></img>
            </div>
            <div className="col-12 col-md-8 order-2 order-md-3">
              <h1 className="heading mb-3 text-100">High Impact Tutoring</h1>

              <p>
                Learn From Native’s High Impact Tutoring Framework focuses on
                personalised learning, expert guidance, interactivity, progress
                tracking, and a goal-oriented approach, all within a carefully
                structured timeframe.
              </p>
              <button className="btn-howLFNWorks mt-2" onClick={handleClick}>
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HighImpactTutoringBanner;

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Execute } from "../Services/APIService";
import Login from "../component/Login";
import Header from "../component/Header";
import Installment from "../component/Installment";
import { Checkbox, ICheckboxProps, ICheckboxStyles } from "@fluentui/react";
import RazorPay from "../Services/RazorPay";
import Dailogue from "../Controls/Modal";

const checkboxStyles: ICheckboxStyles = {
  text: { fontSize: "20px", fontWeight: "bold" },
};

var reedemCoinsList: ICheckboxProps[] = [];
var reedemLFNCoins = [];
var utilizeCoinsJson = [];
var discountCost = 0;
var groupFinalCost;

var planClassMinuteOptionCSVArray;
var planClassCountOptionCSVArray;
var planPricingType = "Paid";

function BillingInvoice() {
  var webAppName = "WebsitePortal";
  var userID;
  var sessionID;
  var teachingLanguageID;

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryID = sessionDetails.CountryID;
    var countryName = sessionDetails.CountryName;
    if (webAppName === "LearnerPortal") {
      sessionID = sessionDetails.SessionID;
      teachingLanguageID = sessionDetails.TeachingLanguageID;
    } else {
      sessionID = 3;
      teachingLanguageID = 1;
    }
  }

  const navigate = useNavigate();
  const location = useLocation();
  const tutorProfileDetails = location.state.tutorProfileDetails;

  const [installmentDetailsProps, setInstallmentDetailsProps] = useState({
    installmentMonths: "",
    installmentPerMonth: 0,
    finalCostWithoutInstallmentPerMonth: 0,
    installmentID: 0,
  });
  const [purchaseClassDetails, setPurchaseClassDetails] = useState({
    noOfClasses: 4,
    classDuration: 60,
  });
  const [discount, setDiscount] = useState(0);

  const validateUser = () => {
    return new Promise<void>((resolve) => {
      var sessionData = localStorage.getItem("items");
      var sessionDetails = JSON.parse(sessionData);

      if (sessionDetails != null || sessionDetails != undefined) {
        userID = sessionDetails.UserID;
        if (userID == null || userID == "" || userID == undefined) {
          $("#loginModalCenter").modal("show");
          return;
        }
      } else {
        $("#loginModalCenter").modal("show");
        return;
      }
      resolve();
    });
  };

  if (tutorProfileDetails.PlanType == "Individual") {
    planClassMinuteOptionCSVArray =
      tutorProfileDetails?.PlanClassMinuteOptionCSV.split(","); // ['30', '45', '60']
    planClassCountOptionCSVArray =
      tutorProfileDetails?.PlanClassCountOptionCSV.split(",");
  }

  const individualPlanCost =
    tutorProfileDetails.PlanOfferPricing ==
    tutorProfileDetails.PlanOriginalPricing
      ? tutorProfileDetails.PlanOriginalPricing
      : tutorProfileDetails.PlanOfferPricing;
  const groupClassCost =
    tutorProfileDetails.PlanOfferingPricingPerClass ==
    tutorProfileDetails.PlanOriginalPricingPerClass
      ? tutorProfileDetails.PlanOriginalPricingPerClass
      : tutorProfileDetails.PlanOfferingPricingPerClass;
  const groupPlanCost =
    tutorProfileDetails.PlanOfferPricing ==
    tutorProfileDetails.PlanOriginalPricing
      ? tutorProfileDetails.PlanOriginalPricing
      : tutorProfileDetails.PlanOfferPricing;
  const [redeemCoins, setRedeemCoins] = useState([]);

  useEffect(() => {
    //validateUser()

    const oSelectFewFromStudentCoinEarnedWhereStudentIDAndPlanPricingIDForRedeemableCoinsOnPayment =
      {
        ActionName:
          "LFN.GMst_SelectFewFromStudentCoinEarnedWhereStudentIDAndPlanPricingIDForRedeemableCoinsOnPayment",
        ParameterJSON:
          '{"PlanPricingID ":"' + tutorProfileDetails.PlanPricingID + '"}',
        SessionDataJSON:
          '{"UserID":"' +
          userID +
          '","SessionID":"3","OnBehalfOfUserID":"' +
          userID +
          '","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
        OperationName: "Query",
      };

    Execute(
      oSelectFewFromStudentCoinEarnedWhereStudentIDAndPlanPricingIDForRedeemableCoinsOnPayment
    ).then((response) => {
      if (response.DataIsLoaded) {
        setRedeemCoins(response.items);
        reedemCoinsList.push({ id: JSON.stringify(response) });
      }
    });

    if (tutorProfileDetails.InstallmentJSON != null) {
      if (tutorProfileDetails.PlanType != "Individual") {
        document.getElementById("viewInstallment").style.display = "block";
      }
    }
    document.getElementById("installmentDetails").style.display = "none";
  }, []);

  groupFinalCost = groupPlanCost - discount - 0;
  const onChangeNoOfClasses = (value) => {
    const selectedText = value;
    setPurchaseClassDetails((prevState) => ({
      ...prevState,
      noOfClasses: selectedText,
    }));

    clearSelection();
  };

  const onChangeClassDuration = (event) => {
    const selectedValue = event.target.value;
    setPurchaseClassDetails((prevState) => ({
      ...prevState,
      classDuration: selectedValue,
    }));
    clearSelection();
  };

  const clearSelection = () => {
    if (tutorProfileDetails.InstallmentJSON != null) {
      if (tutorProfileDetails.PlanType != "Individual") {
        document.getElementById("viewInstallment").style.display = "block";
      }
    }

    document.getElementById("installmentDetails").style.display = "none";
    setInstallmentDetailsProps({
      installmentMonths: "",
      installmentPerMonth: 0,
      finalCostWithoutInstallmentPerMonth: 0,
      installmentID: 0,
    });
  };

  const [totalCost, setTotalCost] = useState(null);
  const [classCost, setClassCost] = useState(null);
  useEffect(() => {
    var costPerMinute =
      individualPlanCost / tutorProfileDetails.PlanPerClassMinutes;
    var costPerClass = costPerMinute * purchaseClassDetails.classDuration;
    var totalClassCost = (
      costPerClass * purchaseClassDetails.noOfClasses
    ).toFixed(2);
    setTotalCost(totalClassCost);
    var calculatedClassCost = (
      costPerMinute * purchaseClassDetails.classDuration
    ).toFixed(2);
    setClassCost(calculatedClassCost);
  });

  const oneOnOneFinalCost = (totalCost - discount).toFixed(2);
  function openIndividualInstallmentDialog(obj) {
    $(document).ready(function () {
      $("#installmentModalCenter").modal("show");
    });
  }

  function onChangeProfileCheckbox(event, obj, planName) {
    const checked = event.target.checked;

    if (planName != "One-on-One") {
      if (obj.WalletRedeemableCoins > groupPlanCost) {
        alert("Not allowed higher amount for discount than paying amount");
        return;
      }
    } else {
      if (obj.WalletRedeemableCoins > totalCost) {
        alert("Not allowed higher amount for discount than paying amount");
        return;
      }
    }

    if (checked) {
      reedemLFNCoins.push(obj.WalletRedeemableCoins);
      utilizeCoinsJson.push({
        WalletName: obj.WalletName,
        UtilizeCoins: obj.WalletRedeemableCoins.toString(),
      });
    } else {
      for (var i = 0; i < reedemLFNCoins.length; i++) {
        if (reedemLFNCoins[i] === obj.WalletRedeemableCoins) {
          reedemLFNCoins.splice(i, 1);
          utilizeCoinsJson.splice(i, 1);
        }
      }
    }

    discountCost = reedemLFNCoins.reduce(function (accumVariable, curValue) {
      return accumVariable + curValue;
    }, 0);

    setDiscount(discountCost);
    clearSelection();
  }

  const ReedemCoins = (planName) =>
    redeemCoins?.map((obj) => {
      if (redeemCoins.length > 0) {
        return (
          <>
            <Checkbox
              styles={checkboxStyles}
              onChange={(ev) => onChangeProfileCheckbox(ev, obj, planName)}
              label={
                "Use " +
                obj.WalletRedeemableCoins +
                " Coins from " +
                obj.WalletName
              }
              key={obj.WalletRedeemableCoins}
            />
            <p style={{ marginLeft: "27px" }}>
              Available {obj.WalletName} points {obj.WalletBalanceCoins}
            </p>
          </>
        );
      }
    });
  const handleChildClick = (selectedInstallmentJson) => {
    setInstallmentDetailsProps(selectedInstallmentJson);
    groupFinalCost =
      selectedInstallmentJson.finalCostWithoutInstallmentPerMonth;
    setTotalCost(selectedInstallmentJson.finalCostWithoutInstallmentPerMonth);

    $("#installmentModalCenter").modal("hide");
    document.getElementById("installmentDetails").style.display = "block";

    if (tutorProfileDetails.PlanType === "Individual") {
      document.getElementById("OneOneOneViewInstallment").style.display =
        "none";
    } else {
      document.getElementById("viewInstallment").style.display = "none";
    }
  };

  const onPayNow = (item, finalcost) => {
    validateUser().then(() => {
      if (countryName === "India") {
        RazorPay(
          item,
          finalcost,
          purchaseClassDetails.noOfClasses,
          purchaseClassDetails.classDuration,
          installmentDetailsProps,
          utilizeCoinsJson,
          countryID,
          planPricingType
        );
      } else {
        var paymentDetailsString = "";
        var paymentDetailsParseData = [];

        var purchaseClassCount =
          purchaseClassDetails.noOfClasses === 0
            ? ""
            : purchaseClassDetails.noOfClasses;
        var purchaseClassMinutes =
          purchaseClassDetails.classDuration === 0
            ? ""
            : purchaseClassDetails.classDuration;

        var utilizeCoins = JSON.stringify(JSON.stringify(utilizeCoinsJson));

        //Remove this Logic
        if (item.PlanName != "One-on-One") {
          purchaseClassCount = "";
          purchaseClassMinutes = "";
        } else {
          if (purchaseClassDetails.noOfClasses === 0) {
            purchaseClassCount = "";
          } else if (purchaseClassDetails.classDuration == 0) {
            purchaseClassMinutes = "";
          }
        }
        var oInsertPurchaseAndMastersNew;

        var paymentMethod = "Stripe";
        var installmentID =
          installmentDetailsProps.installmentID > 0
            ? installmentDetailsProps.installmentID
            : null;
        var teacherID = item.TeacherID === undefined ? "" : item.TeacherID;

        if (installmentID === null) {
          oInsertPurchaseAndMastersNew = {
            ActionName: "LFN.GTra_InsertPurchaseAndMastersNew",
            ParameterJSON:
              '{"CountryID":"' +
              countryID +
              '","PlanID":"' +
              item.PlanID +
              '","Currency":"' +
              item.PlanPricingCurrency +
              '","TeacherID":"' +
              teacherID +
              '","PurchaseClassCount":"' +
              purchaseClassCount +
              '","PurchaseClassMinutes":"' +
              purchaseClassMinutes +
              '","FinalCost":"' +
              finalcost +
              '","TeachingLanguageID":"' +
              teachingLanguageID +
              '","UtilizeCoinsJson":' +
              utilizeCoins +
              ',"InstallmentAmount":"' +
              installmentDetailsProps.installmentPerMonth +
              '","PlanPricingType":"' +
              planPricingType +
              '","PaymentMethod":"' +
              paymentMethod +
              '","PlanPricingID":"' +
              item.PlanPricingID +
              '","RowsAffecting":"1"}',
            SessionDataJSON:
              '{"UserID":"' +
              userID +
              '","SessionID":"' +
              sessionID +
              '","OnBehalfOfUserID":"' +
              userID +
              '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
            OperationName: "Command",
          };
        } else {
          oInsertPurchaseAndMastersNew = {
            ActionName: "LFN.GTra_InsertPurchaseAndMastersNew",
            ParameterJSON:
              '{"CountryID":"' +
              countryID +
              '","PlanID":"' +
              item.PlanID +
              '","Currency":"' +
              item.PlanPricingCurrency +
              '","TeacherID":"' +
              teacherID +
              '","PurchaseClassCount":"' +
              purchaseClassCount +
              '","PurchaseClassMinutes":"' +
              purchaseClassMinutes +
              '","FinalCost":"' +
              finalcost +
              '","TeachingLanguageID":"' +
              teachingLanguageID +
              '","UtilizeCoinsJson":' +
              utilizeCoins +
              ',"InstallmentID":"' +
              installmentID +
              '","InstallmentAmount":"' +
              installmentDetailsProps.installmentPerMonth +
              '","PlanPricingType":"' +
              planPricingType +
              '","PaymentMethod":"' +
              paymentMethod +
              '","PlanPricingID":"' +
              item.PlanPricingID +
              '","RowsAffecting":"1"}',
            SessionDataJSON:
              '{"UserID":"' +
              userID +
              '","SessionID":"' +
              sessionID +
              '","OnBehalfOfUserID":"' +
              userID +
              '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
            OperationName: "Command",
          };
        }

        Execute(oInsertPurchaseAndMastersNew).then((response) => {
          if (!response.DataIsLoaded) {
            return (
              <div>
                <h1>Please wait some time...</h1>
              </div>
            );
          } else {
            if (response.message === "Successfull") {
              paymentDetailsString = JSON.stringify(response.items);
              paymentDetailsParseData = JSON.parse(
                JSON.parse(paymentDetailsString)
              );
              navigate("/Stripe", {
                state: {
                  paymentDetailsParseData: paymentDetailsParseData,
                  item: item,
                  utilizeCoinsJson: utilizeCoinsJson,
                },
              });
            }
          }
        });
      }
    });
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content">
        <img src="/core/lfn-logo.png" className="m-3"></img>
        <h3 className="mb-5">
          We appreciate your payment! Our team will be in touch with you
          shortly. If you have any inquiries in the meantime, please don't
          hesitate to reach out to us at +91 8058058087 or send us an email at
          connect@learnfromnative.com.
        </h3>
      </div>
    );
  };

  const onModalClose = () => {
    window.location.href = "/#planPricingSection";
  };

  return (
    <>
      {webAppName != "LearnerPortal" && <Header />}
      {tutorProfileDetails.PlanType === "Individual" ? (
        <>
          <div className="billingInvoice m-md-5 m-4">
            <div className="row">
              <div className="col-md-3 serviceDetails">
                <h1>Service Details</h1>
                <span>Class Duration</span>
                <div>
                  {planClassMinuteOptionCSVArray.map((value, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id={`exampleRadios${index}`}
                        value={value}
                        onChange={onChangeClassDuration}
                        defaultChecked={
                          index === planClassMinuteOptionCSVArray.length - 1
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`exampleRadios${index}`}
                      >
                        {value}
                      </label>
                    </div>
                  ))}
                </div>
                <br />
                <span>No of Classes</span>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    value={purchaseClassDetails.noOfClasses}
                  />
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {planClassCountOptionCSVArray.map((value, index) => (
                      <li key={index}>
                        <a
                          className="dropdown-item"
                          onClick={() => onChangeNoOfClasses(value)}
                        >
                          {value}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p style={{ color: "#878787" }}>Cost/Class</p>

                    <p style={{ color: "#878787" }}>Total Class</p>
                  </div>
                  <div className="col-6">
                    <p>
                      -{" "}
                      {(tutorProfileDetails.PlanPricingCurrency == "INR"
                        ? tutorProfileDetails.PlanPricingCurrency + " "
                        : "$") + classCost}
                    </p>

                    <p>
                      -{" "}
                      {(tutorProfileDetails.PlanPricingCurrency == "INR"
                        ? tutorProfileDetails.PlanPricingCurrency + " "
                        : "$") + totalCost}
                    </p>
                  </div>
                </div>

                {ReedemCoins(tutorProfileDetails.PlanName)}
              </div>
              <div className="col-md-4 offset-md-5 billingInvoice fixedContentToFooter">
                <h1>Billing Invoice</h1>
                <div className="row">
                  <div className="col-6">
                    <p>Total Cost</p>
                    <p>LFN Discount</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {" "}
                      -{" "}
                      {(tutorProfileDetails.PlanPricingCurrency == "INR"
                        ? tutorProfileDetails.PlanPricingCurrency + " "
                        : "$") + totalCost}
                    </p>
                    <p>- {discount}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <p>Final Cost </p>
                  </div>
                  <div className="col-6">
                    <p>
                      -{" "}
                      {(tutorProfileDetails.PlanPricingCurrency == "INR"
                        ? tutorProfileDetails.PlanPricingCurrency + " "
                        : "$") + oneOnOneFinalCost}
                    </p>
                  </div>
                </div>

                {/* {purchaseClassDetails.noOfClasses>=16?<p id="OneOneOneViewInstallment">Paid Amount  <a onClick={() => { openIndividualInstallmentDialog(tutorProfileDetails.InstallmentJSON)}}>View Installments</a></p>:null} */}
                <div id="installmentDetails">
                  <p>
                    Installment months{" "}
                    {installmentDetailsProps.installmentMonths}
                  </p>
                  <p>
                    Installment amount{" "}
                    {installmentDetailsProps.installmentPerMonth} per month
                  </p>
                </div>

                <button
                  className="btn btn-tomato w-100 d-none d-md-block p-2 fw-bold fs-5"
                  onClick={() =>
                    onPayNow(tutorProfileDetails, oneOnOneFinalCost)
                  }
                >
                  Pay Now
                </button>
              </div>
            </div>

            <Login handleClick={onModalClose} />

            {tutorProfileDetails.InstallmentJSON != null ? (
              <Installment
                installmentJSON={tutorProfileDetails.InstallmentJSON}
                totalCost={totalCost}
                discount={discount}
                onChildClick={handleChildClick}
              />
            ) : null}
          </div>
          <button
            className="btn btn-tomato w-100 d-md-none p-2 fixed-bottom mb-0"
            onClick={() => onPayNow(tutorProfileDetails, oneOnOneFinalCost)}
          >
            Pay Now
          </button>
        </>
      ) : (
        <>
          <div className="billingInvoice m-md-5 m-4">
            <div className="row">
              <div className="col-md-4 serviceDetails mb-5">
                <h1>Service Details</h1>
                <div>
                  <div className="row">
                    <div className="col-6">
                      <p>Level</p>
                      <p>No of months</p>
                      <p>Cost/Class</p>
                      <p>Total Cost </p>
                      <div>
                        {(() => {
                          if (redeemCoins?.length > 0) {
                            return (
                              <p style={{ marginBottom: "10px" }}>
                                Redeem LFN Coin
                              </p>
                            );
                          }
                        })()}
                        {ReedemCoins(tutorProfileDetails.PlanName)}
                      </div>
                    </div>
                    <div className="col-6">
                      <p> - {tutorProfileDetails.PlanName}</p>
                      <p> - {tutorProfileDetails.PlanDuration}</p>
                      <p>
                        {" "}
                        -{" "}
                        {tutorProfileDetails.PlanPricingCurrency == "INR"
                          ? tutorProfileDetails.PlanPricingCurrency + " "
                          : "$"}
                        {groupClassCost}
                      </p>
                      <p>
                        -{" "}
                        {tutorProfileDetails.PlanPricingCurrency == "INR"
                          ? tutorProfileDetails.PlanPricingCurrency + " "
                          : "$"}
                        {groupPlanCost}
                      </p>
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <div className="col-md-4 offset-md-4 billingInvoice fixedContentToFooter">
                <h1>Billing Invoice</h1>
                <div className="row">
                  <div className="col-6">
                    <p>Total Cost </p>
                    <p>LFN Discount </p>
                  </div>
                  <div className="col-6">
                    <p>
                      -{" "}
                      {(tutorProfileDetails.PlanPricingCurrency == "INR"
                        ? tutorProfileDetails.PlanPricingCurrency + " "
                        : "$") + groupPlanCost}
                    </p>
                    <p>
                      -{" "}
                      {(tutorProfileDetails.PlanPricingCurrency == "INR"
                        ? tutorProfileDetails.PlanPricingCurrency + " "
                        : "$") + 0}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <p>Final Cost</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {" "}
                      -{" "}
                      {(tutorProfileDetails.PlanPricingCurrency == "INR"
                        ? tutorProfileDetails.PlanPricingCurrency + " "
                        : "$") + groupFinalCost}
                    </p>
                  </div>
                </div>

                <p id="viewInstallment">
                  Paid Amount{" "}
                  <a
                    onClick={() => {
                      openIndividualInstallmentDialog(
                        tutorProfileDetails.InstallmentJSON
                      );
                    }}
                  >
                    {" "}
                    View Installments
                  </a>
                </p>
                <div id="installmentDetails">
                  <p>
                    Installment months{" "}
                    {installmentDetailsProps.installmentMonths}
                  </p>
                  <p>
                    Installment amount{" "}
                    {installmentDetailsProps.installmentPerMonth} per month
                  </p>
                </div>
                <button
                  className="btn btn-tomato w-100 d-none d-md-block"
                  onClick={() => onPayNow(tutorProfileDetails, groupFinalCost)}
                >
                  Pay Now
                </button>
              </div>
            </div>
            <Login handleClick={onModalClose} />
            {tutorProfileDetails.InstallmentJSON != null ? (
              <Installment
                installmentJSON={tutorProfileDetails.InstallmentJSON}
                totalCost={groupPlanCost}
                discount={discount}
                onChildClick={handleChildClick}
              />
            ) : null}
          </div>
          <button
            className="btn btn-tomato w-100 d-block d-md-none p-2 fixed-bottom mb-0"
            onClick={() => onPayNow(tutorProfileDetails, groupFinalCost)}
          >
            Pay Now
          </button>
        </>
      )}
      <Dailogue
        id={"paymentModalCenter"}
        body={getDailogueBody}
        title=""
        isClose={true}
      />
    </>
  );
}

export default BillingInvoice;

import Dailogue from "../Controls/Modal";

function Catalogue() {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }
  const kids = () => {
    window.location.href = "/Kids";
  };
  const ielts = () => {
    $("#ieltsModalCenter").modal("show");
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center ielts-Content">
        <img src="/assest/images/lfn-logo.png" className="m-5"></img>
        <p>
          Thank you for your interest in our IELTS Training Course. We offer a
          thorough and personalized One-to-one program designed to help you
          achieve your desired band score in the IELTS exam. For more
          information and further details, please contact us via phone or
          WhatsApp at +91 8058058087.
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="Catalogue">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading">More in the Catalogue</h1>
            <br></br>
          </div>
        </div>

        <div className="catalogue-content d-flex text-center justify-content-between">
          <div className="">
            <div className="catalogue-section kids-section px-0">
              <h4>Have your Kids Speak English Confidently!</h4>
              <img
                className="catalogue-img img-responsive"
                src="/assest/images/catalogue-kids.png"
              ></img>
              <button
                className="btn btn-primary  w-100 btn-lg btn-tomato p-2 p-md-3"
                onClick={kids}
              >
                <b>See More</b>
                <img
                  src="/assest/images/right-arrow-white.png"
                  alt="Icon"
                  className="float-end btn-icon"
                ></img>
              </button>
            </div>
          </div>
          <div className="">
            <div className="catalogue-section ielts-section px-0">
              <h4>Ace your IELTS with the Desired band! </h4>

              <img
                className="catalogue-img img-responsive"
                src="/assest/images/catalogue-ielts.png"
              ></img>
              <button
                className="btn btn-primary w-100 btn-lg btn-selectiveYellow p-2 p-md-3"
                onClick={ielts}
              >
                <b>See More</b>
                <img
                  src="/assest/images/right-arrow-white.png"
                  alt="Icon"
                  className="float-end btn-icon"
                ></img>
              </button>
            </div>
          </div>
        </div>

        {/* <div className="row ">
    <div className="col-md-6 text-center kids-section box px-0">
        
            <h4>Have your Kids Speak English Confidently!</h4>
            <img className="catalogue-img img-responsive" src="/assest/images/catalogue-kids.png"></img>
            <button className="btn btn-primary w-100 btn-lg btn-tomato p-2 p-md-3" onClick={kids}>
                <b>See More</b>
                <img src="/assest/images/right-arrow-white.png" alt="Icon" className="float-end btn-icon"></img>
            </button>
        
    </div>
    
    <div className="col-md-6 text-center box ielts-section px-0" >
        
            <h4>Ace your IELTS with the Desired band! </h4>
            <img className="catalogue-img img-responsive" src="/assest/images/catalogue-ielts.png"></img>
            <button className="btn btn-primary w-100 btn-lg btn-selectiveYellow p-2 p-md-3" onClick={ielts}>
                <b>See More</b>
                <img src="/assest/images/right-arrow-white.png" alt="Icon" className="float-end btn-icon"></img>
            </button>
       
    </div>
</div> */}
      </div>
      <Dailogue id={"ieltsModalCenter"} body={getDailogueBody} title="" />
    </>
  );
}

export default Catalogue;

import { useState } from "react"
import Header from "../component/Header"

const blogs=[
{
    title:"The Role of Culture in Learning Spoken English for Kids in India: Insights and Tips",
    description:"English is a widely spoken language around the world, and in India, it is considered an essential skill for both personal and professional growth. However, learning spoken English can be challenging, especially for kids who may struggle with cultural differences and lack of exposure to the language. India is a culturally diverse country with more than 22 languages spoken across different states. English is one of the official languages of...",
    src:"/assest/images/blogs-content/The-Role-of-Culture-in-Learning-Spoken-English-for-Kids-in-India-Insights-and-tips.jpg",
    redirectUrl:"/the-role-of-culture-in-learning-spoken-english-for-kids-in-india-insights-and-tips",
    categoryID:1
         
},
{
  title:"Spoken English for Kids: Tips for Overcoming Shyness and Building Confidence",
  description:"Learning to speak English is an essential skill for kids, and it can be an enriching experience for their personal growth and academic development. However, some children may feel shy or nervous when speaking in public, which can hinder their progress in learning English.English is a global language that connects people from different cultures and countries. Learning English is not only essential for communication but can also provide numerous...",
  src:"/assest/images/blogs-content/spoken-english-for-kids-tips-for-overcoming-shyness-and-building-confidence.jpg",
  redirectUrl:"/spoken-english-kids-confidence",
  categoryID:2
       
},
{
  title:"Boost Spoken English for Kids with these Easy Tips",
  description:"Learning to speak English is an essential skill for kids, and it can be an enriching experience for their personal growth and academic development. However, some children may feel shy or nervous when speaking in public, which can hinder their progress in learning English.English is a global language that connects people from different cultures and countries. Learning English is not only essential for communication but can also provide numerous...",
  src:"/assest/images/blogs-content/boost-spoken-english-for-kids-with-these-easy-tips.jpg",
  redirectUrl:"/tips-spoken_english-kids",
  categoryID:3
},
{
  title:"The Importance of Pronunciation in Spoken English for Kids",
  description:"Proper pronunciation is a crucial aspect of learning spoken English for kids. While grammar, vocabulary, and reading are all essential, clear pronunciation is equally important for effective communication. Not only does it improve comprehension and expression, but it also enhances confidence, promotes academic success, and improves listening skills. Clear communication is crucial in social interactions, academic performance, and future career...",
  src:"/assest/images/blogs-content/The-Importance-of-Pronuciation-in-Spoken-English-for-Kids.jpg",
  redirectUrl:"/pronunciation-spoken-english-kids",
  categoryID:4
},
{
  title:"10 Fun and Effective Ways to Teach Spoken English to Kids",
  description:"Teaching spoken English to kids can be a challenging task, especially for those who are non-native speakers themselves. However, it's an essential skill for children to learn as they grow up in a world that's increasingly globalized and interconnected. Being able to communicate effectively in English can open up a world of opportunities for young learners, from academic and professional success to cultural exchange and travel.  While teaching English to...",
  src:"/assest/images/blogs-content/10-Best-and-Effective-way-to-Teach-Spoken-English-to-Kids.jpg",
  redirectUrl:"/spoken-english-kids",
  categoryID:5
},
{
  title:"Boost Your Child’s Exam Performance with Online English Courses for TOEFL and IELTS Preps",
  description:"When it comes to studying or working abroad, having strong English language skills is often a requirement. As a result, many universities and employers require international students or candidates to take an English language proficiency exam, such as the TOEFL or IELTS. These exams can be challenging, but they are necessary to prove one's ability to communicate effectively in English. Therefore, it is important for your child to prepare...",
  src:"/assest/images/blogs-content/Boost-your-Child's-Exam-Performance-with-online-English-Cources-for-TOEFL-and-IELTS-Preps.jpg",
  redirectUrl:"/online-english-ielts-toefl",
  categoryID:6
},
{
  title:"The Role of Native English-Speaking Teachers in Effective Online English Courses for Kids",
  description:"In today's interconnected world, learning English has become increasingly important for kids. Not only is English the language of international communication, but it is also the language of global commerce, technology, and entertainment. With online English courses becoming more popular than ever, it's important to consider the role of native English-speaking teachers in these courses.  The Importance of Native English-Speaking Teachers in Online English Courses for Kids  1. Native speakers have...",
  src:"/assest/images/blogs-content/The-Role-of-Native-English-Speaking-Teachers-in-Effective-Online-English-Courses-for-Kids.jpg",
  redirectUrl:"/native-english-teachers",
  categoryID:7
}
]

const categories=[
  {
    id:1,
    categoryName:"Spoken English",
  },
  {
    id:2,
    categoryName:"Creative Writing",
  },
  {
    id:3,
    categoryName:"Vocabulary",
  },
  {
    id:4,
    categoryName:"Grammar",
  },
  {
    id:5,
    categoryName:"Guided Reading",
  },
  {
    id:6,
    categoryName:"IELTS",
  },
  {
    id:7,
    categoryName:"Miscellaneous",
  }
 
]




function Blogs()
{

  const [blogsItems,setBlogsItems]=useState(blogs)

  const getBlogs=()=>
  {
    return(
      <div className="m-4 m-md-5">
        <div className="row">
  <div className="col-md-8 col-12 order-md-1 order-2">
    {blogsItems.map((obj, index) => (
      <div className={`row blogs mb-5${index % 2 !== 0 ? ' flex-row-reverse' : ''}`}>
        <div className={`col-md-5 col-12 p-0 p-md-3${index % 2 !== 0 ? ' order-2 order-md-1' : ''}`}>
          <img src={obj.src} alt="" className="w-100 h-100" />
        </div>
        <div className={`col-md-7 col-12 p-3${index % 2 !== 0 ? ' order-1 order-md-2' : ''}`}>
          <h1 className="mb-3">{obj.title}</h1>
          <p>{obj.description}</p>
          <a href='' className="hyperLinkText">Read More</a>
        </div>
      </div>
    ))}
  </div>
  <div className="blogs-categories col-md-4 text-md-end order-md-1 order-1">
    <h4>Categories</h4>
    <ul>
      {categories.map((obj, index) => (
        <li onClick={() => getBlogsCategory(obj.id)}>{obj.categoryName}</li>
      ))}
    </ul>
  </div>
</div>


      </div>
      )

    
  }
  const getBlogsCategory=(id)=>
  {
    const filteredBlogs = blogs.filter((blog) => blog.categoryID === id);
    setBlogsItems(filteredBlogs);
  }

    return<>
    <Header active="Blogs"/>
      {getBlogs()}
    </>
}
export default Blogs

import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SocialMedia from '../component/SocialMedia';
import Header from '../component/Header';
const mdDocument = require('../posts/online-english-ielts-toefl.md');

function OnlineEnglishIeltsToefl()
{

  const [markdownContent, setMarkdownContent] = useState('');
  useEffect(() => {
    // Fetch the Markdown file content from a different folder
    fetch(mdDocument)
      .then(response => response.text())
      .then(text => {
        setMarkdownContent(text);
      });
  }, []);

  return (
    <>
      <Header/>
      <div className='blogs-content'>
        <ReactMarkdown>{markdownContent}</ReactMarkdown>
      </div>
      <SocialMedia/>
    </>
   
  )
}

export default OnlineEnglishIeltsToefl

import React, { useEffect, useRef, useState } from "react";
import "../style/Style.css";
import MyDropDown from "../Controls/MyDropDown";
import MyTextField from "../Controls/MyTextField";
import SingleSignOn from "../pages/SingleSignOn";
import { Execute } from "../Services/APIService";
import {
  Dialog,
  DialogFooter,
  DialogType,
  IDropdownOption,
  IDropdownStyles,
  IStackProps,
  ITextFieldStyles,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import ContactUs from "../component/ContactUs";
import { selectloginOptions } from "../features/appData/loginOptionsSlice";
import { useSelector } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase.config";
import Timer from "./Timer";

var result;
var verificationID = null;
function generateOTP() {
  var min = 100000;
  var max = 999999;
  result = Math.floor(min + Math.random() * (max - min));
  return result;
}

var sessionData = localStorage.getItem("items");
var sessionDetails = JSON.parse(sessionData);
var sessionID = -1;
var deviceRegistrationID = "";
// if (sessionDetails != null || sessionDetails != undefined)
// {
//     var userID = sessionDetails.UserID
//     //var deviceRegistrationID = sessionDetails.DeviceRegistrationID
// }

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 10 },
  styles: { root: { margin: "auto", paddingLeft: 5 } },
};

const SignUpInformationDialogContent = {
  type: DialogType.normal,
  title: "Information",
  showCloseButton: true,
};

const items: IDropdownOption[] = [];
let oSelectFewFromMultipleTablesForMobileLoginScreen = {
  ActionName: "Core.GMst_SelectFewFromMultipleTablesForMobileLoginScreen",
  ParameterJSON: "",
  SessionDataJSON:
    '{"UserID":"","SessionID":"' +
    sessionID +
    '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
  OperationName: "Query",
};
Execute(oSelectFewFromMultipleTablesForMobileLoginScreen).then((response) => {
  if (response.DataIsLoaded) {
    const filterData = response.items.filter(
      (obj) => obj.DataTableName === "Country"
    );
    filterData.map((entry) => {
      items.push({
        key: entry.CountryID,
        id: JSON.stringify(entry),
        text: entry.CountryDialingCode + "  " + entry.CountryName,
      });
    });
  }
});

function LoginPage(props) {
  var Latitude = null;
  var Longitude = null;

  if (sessionDetails != null || sessionDetails != undefined) {
    Latitude = sessionDetails.Latitude;
    Longitude = sessionDetails.Longitude;
  }
  const [loading, setLoading] = useState(false);

  const [showOTPTextBox, setOTPTextBox] = useState(false);
  const [signUpDialogProp, setSignUpDialogProp] = useState({ IsShow: false });
  const openSignUpDialog = () => {
    setSignUpDialogProp({ IsShow: true });
  };

  const closeSignUpDialog = () => {
    setSignUpDialogProp({ IsShow: false });
    clearLogin();
  };

  const [policyPrivacyProp, setPolicyPrivacyProp] = useState({ IsShow: false });
  const openPolicyPrivacyDialog = () => {
    setPolicyPrivacyProp({ IsShow: true });
  };
  const closePolicyPrivacyDialog = () =>
    setPolicyPrivacyProp({ IsShow: false });

  const [termsOfUseProp, setTermsOfUseProp] = useState({ IsShow: false });
  const openTermsOfUseDialog = () => {
    setTermsOfUseProp({ IsShow: true });
  };
  const closeTermsOfUseDialog = () => setTermsOfUseProp({ IsShow: false });

  const [countryProps, setCountryProps] = React.useState({
    CountryData: {
      CountryID: "",
      CountryDialingCode: "",
      CountryName: "",
      CountryNameWithDialingCode: "",
    },
  });

  function onChangeCountry(event, value) {
    var countryDetail = JSON.parse(value.id);
    setCountryProps({
      ...countryProps,
      [event.target.id]: countryDetail,
    });
  }
  const [item, setItem] = React.useState({ OTP: "" });

  function handleChange(event) {
    setItem({ ...item, [event.target.id]: event.target.value });
    onLogin(event.target.value);
  }
  const [phoneNo, setPhoneNo] = React.useState({ MobileNo: "" });
  function onMobileNoChange(event) {
    setPhoneNo({ ...phoneNo, [event.target.id]: event.target.value });
  }
  const [appPolicy, setAppPolicy] = useState([]);

  const clearLogin = () => {
    //Clear all value
    setPhoneNo({ MobileNo: "" });
    setItem({ OTP: "" });
    setCountryProps({
      CountryData: {
        CountryID: "",
        CountryDialingCode: "",
        CountryName: "",
        CountryNameWithDialingCode: "",
      },
    });
    setDisabled(false);
  };

  useEffect(() => {
    let oSelectFewFromMultipleTablesForMobileLoginScreen = {
      ActionName: "Core.GMst_SelectFewFromMultipleTablesForMobileLoginScreen",
      ParameterJSON: "",
      SessionDataJSON:
        '{"UserID":"","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };
    Execute(oSelectFewFromMultipleTablesForMobileLoginScreen).then(
      (response) => {
        if (!response.DataIsLoaded)
          return (
            <div>
              <h1> Please wait some time.... </h1>{" "}
            </div>
          );
        else {
          if (response.message === "Successfull") {
            setAppPolicy(response.items);
          }
        }
      }
    );
  }, []);

  const privacyPolicy = appPolicy.filter(privacyPolicyData);
  function privacyPolicyData(obj) {
    return (
      obj.DataTableName === "AppPolicy" &&
      obj.AppPolicyName === "Privacy Policy"
    );
  }

  const termsOfUse = appPolicy.filter(termsOfUseData);
  function termsOfUseData(obj) {
    return (
      obj.DataTableName === "AppPolicy" && obj.AppPolicyName === "Terms Of Use"
    );
  }

  const [disabled, setDisabled] = useState(false);
  const [getResult, setResult] = useState("");

  const SendOTPButton = () => {
    if (disabled === false) {
      return (
        <div className="form sendOTP-Button" style={{ marginTop: "12px" }}>
          <PrimaryButton className="CTA-btn" onClick={() => onSendOTP()}>
            {"Get Started"}
          </PrimaryButton>
          <br />
        </div>
      );
    } else {
      return (
        <div className="text-center" style={{ marginTop: "10px" }}>
          <Timer
            maxRange={60}
            MobileNo={
              countryProps.CountryData.CountryDialingCode +
              " " +
              phoneNo.MobileNo
            }
            result={setResult}
          />
          <MyTextField
            styles={textFieldStyles}
            placeholder="OTP"
            CaptionText=""
            id="OTP"
            value={item.OTP}
            description="OTP"
            onChange={handleChange}
          />
          <br />
        </div>
      );
    }
  };

  const BookAsAGuest = () => {
    return (
      <div className="form sendOTP-Button" style={{ marginTop: "12px" }}>
        <PrimaryButton className="CTA-btn" onClick={() => onSendOTP()}>
          {"Get Started"}
        </PrimaryButton>
        <br />
      </div>
    );
  };
  // const FormButton = props => (
  //     <div id="button" className="form" >
  //         <PrimaryButton className='CTA-btn' style={{marginBottom:"-20px",marginTop:"5px"}} onClick={props.onClick}>{props.title}</PrimaryButton>
  //     </div>
  // );

  const OtherMethods = () => (
    <div style={{ marginTop: "-15px" }}>
      <div className="text-center" style={{ fontSize: "22px" }}>
        <label>or</label>
        <br />
        <label>Log In through</label>
      </div>
      <div id="iconGroup">
        <SingleSignOn />
      </div>
    </div>
  );

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%", margin: "auto" },
    title: {
      fontSize: "20px",
      height: 40,
      paddingTop: 4,
      paddingLeft: 10,
      fontWeight: "bold",
      borderRadius: "10px",
      textAlign: "left",
    },
    dropdownOptionText: {
      fontSize: "20px",
      height: "36px",
      paddingTop: "10px",
    },
    caretDownWrapper: { marginTop: 7, width: 20 },
  };

  var textFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: {
      width: "100%",
      margin: "auto",
      height: 40,
      borderRadius: "10px",
    },
    field: { fontSize: "18px", fontWeight: "bold" },
  };

  const login = () => {
    return (
      <div className="content-lbl">
        <MyDropDown
          styles={dropdownStyles}
          placeholder="Country"
          CaptionText=""
          id="CountryData"
          SelectedKey={countryProps.CountryData.CountryID}
          options={items}
          onChange={onChangeCountry}
        />
        <MyTextField
          styles={textFieldStyles}
          id="MobileNo"
          CaptionText=""
          placeholder="Mobile Number"
          value={phoneNo.MobileNo}
          description="Mobile No"
          type="number"
          onChange={onMobileNoChange}
        />
        {props.LoginMode == "LoginWithoutOTP"
          ? BookAsAGuest()
          : SendOTPButton()}

        {/* <div className='wrapper align-center' style={{fontSize:"20px"}} >
                <label className='left' onClick={()=>openPolicyPrivacyDialog()}>Privacy Policy </label>
                <label className='right' onClick={()=>openTermsOfUseDialog()}>Terms Of Use</label>
            </div> */}
      </div>
    );
    // return<>

    // <form className="loginForm">

    //     <div className="row mb-2">
    //        <div className="col-12">
    //             <MyDropDown styles={dropdownStyles} placeholder="Country" CaptionText="" id="CountryData" SelectedKey={countryProps.CountryData.CountryID} options={items} onChange={onChangeCountry} />
    //         </div>
    //     </div>
    //     <div className="row mb-2">
    //        <div className="col-12">
    //        <MyTextField styles={textFieldStyles} id="MobileNo" CaptionText="" placeholder="Mobile Number" value={item.MobileNo} description="Mobile No" type="number" onChange={handleChange} />
    //         </div>
    //     </div>
    //     <div className="row mb-2">
    //         <div className="col-12">
    //         <SendOTPButton/>
    //         </div>

    //     </div>
    //     <div className="row mb-2">
    //        <div className="col-12">
    //             <input type="text" className="form-control" id="" placeholder="OTP" />
    //         </div>
    //     </div>
    //     <div className="row">
    //         <div className="col-12">
    //             <button className="myBtn w-100 btn-register">Login</button>
    //         </div>

    //     </div>
    //     <div>
    //         <p className="m-0 p-0">Privacy Policy | Terms of Use</p>
    //         <p className="m-0 p-0">Or</p>
    //         <p className="mb-5 p-0">Log In through</p>
    //     </div>
    //     <div>
    //         <p>Having Trouble?<a href="">Whatsapp Us</a></p>

    //     </div>

    // </form></>
  };

  const navigateToHomePage = () => {
    window.location.href = "/HomePage/";
  };

  const updateUserDevicesForSignCompleted = () => {
    var SignMode = "Sign In";

    let oUpdateUserDevicesForSignCompleted = {
      ActionName: "Core.GUtl_UpdateUserDevicesForSignCompleted",
      ParameterJSON:
        '{"DeviceRegistrationID ":"' +
        deviceRegistrationID +
        '","SignMode":"' +
        SignMode +
        '","RowsAffecting":"' +
        2 +
        '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };

    Execute(oUpdateUserDevicesForSignCompleted).then((response) => {
      if (response.message != "Successfull") {
        alert(response.message);
      }
    });
  };

  var configurationDetailsToParse = [];
  var usersettingJson = [];
  var teachingLanguageDetails;
  var teachingLanguageID;
  var userID = "";
  var userName = "";
  var userType = "";
  var emailID = "";
  var loginProvider = "";

  const [userDetails, setuserDetails] = React.useState([]);

  const getUser = () => {
    var countryDialingCode = countryProps.CountryData.CountryDialingCode;
    var LoginProvider = "Mobile No";
    var loginName = countryDialingCode + " " + phoneNo.MobileNo;

    const oSelectFewFromUserForSignIn = {
      ActionName: "Core.GMst_SelectFewFromUserForSignIn",
      ParameterJSON:
        '{"LoginProvider ":"' +
        LoginProvider +
        '","LoginName":"' +
        loginName +
        '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };
    Execute(oSelectFewFromUserForSignIn).then((response) => {
      if (!response.DataIsLoaded)
        return (
          <div>
            <h1> Please wait some time.... </h1>{" "}
          </div>
        );
      else {
        if (response.message === "Successfull") {
          userID = response.items[0].UserID;
          userName = response.items[0].UserName;
          userType = response.items[0].UserType;
          emailID = response.items[0].EmailID;
          loginProvider = response.items[0].LoginProvider;

          // updateUserDevicesForSignCompleted()

          var ConfigurationNetwork = "Public";
          var ConfigurationPlatform = "Web";

          //  const oInsertUserCompanySessionLogAndSelectFewFromMultipleTables =
          //  {
          //      ActionName: "Core.GUtl_InsertUserCompanySessionLogAndSelectFewFromMultipleTables",
          //      ParameterJSON: "{\"ConfigurationNetwork \":\"" + ConfigurationNetwork + "\",\"ConfigurationPlatform\":\"" + ConfigurationPlatform + "\",\"RowsAffecting\":\"1\"}",
          //      SessionDataJSON: "{\"UserID\":\"" + response.items[0].UserID + "\",\"SessionID\":\"" + sessionID + "\",\"OnBehalfOfUserID\":\"0\",\"CompanyID\":\"100\",\"SubscriberID\":\"10000\",\"AppID\":\"learner.learnfromnative.com\",\"AppVersion\":\"1.0\",\"AppPlatform\":\"Web\",\"IsDeveloper\":\"0\",\"ServiceAccessToken\":\"BBADCACCBCCJDC - BBADCACCBCCJDC\"}",
          //      OperationName: "Command"
          //  };
          //  Execute(oInsertUserCompanySessionLogAndSelectFewFromMultipleTables).then(response =>
          //  {
          //      if (!response.DataIsLoaded)
          //          return <div><h1> Pleses wait some time.... </h1> </div>
          //      else
          //      {
          //          if (response.message === 'Successfull')
          //          {
          //             configurationDetailsToParse = JSON.parse(response.items)

          //             if(configurationDetailsToParse[0].UserSettingJSON.length>0)
          //             {
          //                 usersettingJson = JSON.parse(configurationDetailsToParse[0].UserSettingJSON)
          //                 teachingLanguageDetails = usersettingJson.filter(ID => ID.SettingName === 'DefaultTeachingLanguageID')
          //             }

          //             if (teachingLanguageDetails.length > 0)
          //             {
          //                 teachingLanguageID = teachingLanguageDetails[0].SettingValue
          //             }
          //             else
          //             {
          //                 teachingLanguageID=1
          //             }

          //             const obj =
          //             {
          //                 ...sessionDetails,
          //                 UserID: userID,
          //                 UserName: userName,
          //                 UserType:userType,
          //                 EmailID: emailID,
          //                 LoginProvider:loginProvider,
          //                 TeachingLanguageID: teachingLanguageID
          //             };
          //              localStorage.setItem("items", JSON.stringify({ ...obj }))
          //              navigateToHomePage()
          //         }
          //         else
          //         {
          //             alert(response.message);
          //         }
          //      }
          // });
          const obj = {
            ...sessionDetails,
            UserID: userID,
            UserName: userName,
            UserType: userType,
            EmailID: emailID,
            LoginProvider: loginProvider,
            TeachingLanguageID: teachingLanguageID,
          };
          localStorage.setItem("items", JSON.stringify({ ...obj }));
          $("#loginModalCenter").modal("hide");
        } else if (response.message.includes("Sign Up")) {
          setuserDetails(response.message);
          openSignUpDialog();
        } else {
          alert(response.message);
        }
      }
    });
  };

  const insertOTP = () => {
    generateOTP();

    var sTemplateName = "Code No Share";
    var countryDialingCode = countryProps.CountryData.CountryDialingCode;
    var sMSMobileNo = countryDialingCode + " " + phoneNo.MobileNo;

    var sSMSParameter = {
      Description: "ForSignIn",
      OTP: result,
    };
    var sMSParameterJSON = JSON.stringify(sSMSParameter);

    const oInsertSMSSentLog = {
      ActionName: "Core.GUtl_InsertSMSSentLog",
      ParameterJSON:
        '{"TemplateName ":"' +
        sTemplateName +
        '","SMSMobileNo ":"' +
        sMSMobileNo +
        '","SMSParameterJSON  ":' +
        sMSParameterJSON +
        ',"RowsAffecting":"1"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
    Execute(oInsertSMSSentLog).then((response) => {
      if (!response.DataIsLoaded)
        return (
          <div>
            <h1> Please wait some time.... </h1>{" "}
          </div>
        );
      else {
        if (response.message === "Successfull") {
          var smsAPIProviderNameJSON = JSON.parse(response.items);
          var smsAPIProviderName = smsAPIProviderNameJSON[0].SMSAPIProviderName;

          if (smsAPIProviderName == "Device Firebase") {
            handleFirebaseOTP();
          }

          alert("OTP Send successfully..");

          setDisabled(true);
          setTimeout(() => {
            setDisabled(true);
          }, 60000);
        } else {
          alert("OTP not Send." + response.message);
        }
      }
    });
  };

  const InsertGuestUser = (oUser) => {
    var countryDialingCode = countryProps.CountryData.CountryDialingCode;
    var MobileNo = countryDialingCode + " " + oUser.MobileNo;
    var CountryID = oUser.CountryID;
    var TeacherID = props.TeacherID;

    const oInsertGuestUser = {
      ActionName: "LFN.GMst_InsertGuestUser",
      ParameterJSON:
        '{"MobileNo ":"' +
        MobileNo +
        '","CountryID ":"' +
        CountryID +
        '","Longitude":' +
        Longitude +
        ',"Latitude":' +
        Latitude +
        ',"TeacherID":' +
        TeacherID +
        ',"RowsAffecting":"1"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
    Execute(oInsertGuestUser).then((response) => {
      var guestUserDetails = JSON.parse(response.items);
      console.log("guestUserDetails", guestUserDetails);
      if (guestUserDetails) {
        var validationMessage = guestUserDetails[0].ValidationMessage;
        var guestUserID = guestUserDetails[0].GuestUserID;
      }

      console.log("guestUserDetails", guestUserDetails);
      console.log("Validation Message", validationMessage);

      const oGuestUserDetails = {
        GuestUserID: guestUserID,
        GuestMobileNo: MobileNo,
        GuestCountryID: CountryID,
      };

      const obj = {
        ...sessionDetails,
        GuestUserDetails: oGuestUserDetails,
      };
      localStorage.setItem("items", JSON.stringify({ ...obj }));

      if (response.message == "Successfull") {
        if (validationMessage != "" && validationMessage != undefined) {
          props.handleInsertGuestUserError(validationMessage);
        } else {
          props.handleInsertGuestUserSuccess();
        }
      } else {
        console.log("Response ", response);
        props.handleInsertGuestUserError(response.message);
      }
    });
  };

  const onSendOTP = () => {
    if (countryProps.CountryData.CountryID === "") {
      alert("Please Select Country.");
    } else if (phoneNo.MobileNo === "") {
      alert("please enter mobile number");
    } else if (
      countryProps.CountryData.CountryName == "India" &&
      phoneNo.MobileNo.length != 10
    ) {
      alert("please enter a valid 10 digit mobile number");
    } else {
      props.LoginMode == "LoginWithoutOTP"
        ? InsertGuestUser({
            MobileNo: phoneNo.MobileNo,
            CountryID: countryProps.CountryData.CountryID,
          })
        : insertOTP();
    }
  };

  const onLogin = (otp) => {
    if (otp.length === 6) {
      if (result == otp) {
        getUser();
      } else {
        if (verificationID != null) {
          setLoading(true);

          (window as any).confirmationResult
            .confirm(otp)
            .then(() => {
              getUser();
            })
            .catch((err) => {
              alert("Invalid OTP");
            })
            .finally(() => {
              setLoading(false); // Set loading to false after the OTP confirmation is complete
            });
        } else {
          alert("Invalid OTP");
        }
        // var credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
        // firebase.auth().signInWithCredential(credential);
      }
    }
  };

  function onCaptchVerify() {
    try {
      // (window as any).recaptchaVerifier = new (window as any).firebase.auth.RecaptchaVerifier( "recaptcha-container",
      //     {
      //       size: "invisible",
      //       callback: function (response) {
      //         console.log("Captcha Resolved");
      //         this.handleFirebaseOTP();
      //       },auth
      //     }
      //   );
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            this.handleFirebaseOTP();
          },
          "expired-callback": () => {},
        },
        auth
      );
    } catch (error) {
      alert(error);
    }
  }

  const handleFirebaseOTP = () => {
    onCaptchVerify();
    var countryDialingCode = countryProps.CountryData.CountryDialingCode;
    var sMSMobileNo = countryDialingCode + phoneNo.MobileNo;

    let appVerifier = (window as any).recaptchaVerifier;

    signInWithPhoneNumber(auth, sMSMobileNo, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        (window as any).confirmationResult = confirmationResult;
        verificationID = confirmationResult.verificationId;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        alert(error);
      });
  };
  const onSignUp = (mobilenumber) => {
    var countryDialingCode = countryProps.CountryData.CountryDialingCode;
    var loginName = countryDialingCode + " " + mobilenumber;
    var loginProviderUserID = "";
    var loginProvider = "Mobile No";
    var EmailID = "";
    var emailIDVerified = "";
    var mobileNo = countryDialingCode + " " + mobilenumber;
    var mobileNoVerified = 1;
    var countryID = countryProps.CountryData.CountryID;
    var deviceRegistrationID = "";
    var preferredLanguageCSV = "English";
    var longitude = 0.0;
    var latitude = 0.0;

    const oInsertUserForSignUp = {
      ActionName: "Core.GMst_InsertUserForSignUp",
      ParameterJSON:
        '{"LoginName ":"' +
        loginName +
        '","LoginProviderUserID ":"' +
        loginProviderUserID +
        '","LoginProvider ":"' +
        loginProvider +
        '","EmailID ":"' +
        EmailID +
        '","EmailIDVerified ":"' +
        emailIDVerified +
        '","MobileNo":"' +
        mobileNo +
        '","MobileNoVerified ":"' +
        mobileNoVerified +
        '","CountryID":"' +
        countryID +
        '","DeviceRegistrationID ":"' +
        deviceRegistrationID +
        '","PreferredLanguageCSV":"' +
        preferredLanguageCSV +
        '","Longitude ":"' +
        longitude +
        '","Latitude ":"' +
        latitude +
        '","RowsAffecting":" 3 "}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
    Execute(oInsertUserForSignUp).then((response) => {
      if (!response.DataIsLoaded)
        return (
          <div>
            <h1> Please wait some time.... </h1>{" "}
          </div>
        );
      else {
        if (response.message === "Successfull") {
          var userDetails = JSON.parse(response.items);

          var ConfigurationNetwork = "Public";
          var ConfigurationPlatform = "Web";

          // const oInsertUserCompanySessionLogAndSelectFewFromMultipleTables =
          // {
          //     ActionName: "Core.GUtl_InsertUserCompanySessionLogAndSelectFewFromMultipleTables",
          //     ParameterJSON: "{\"ConfigurationNetwork \":\"" + ConfigurationNetwork + "\",\"ConfigurationPlatform\":\"" + ConfigurationPlatform + "\",\"RowsAffecting\":\"1\"}",
          //     SessionDataJSON: "{\"UserID\":\"" + userDetails[0].UserID + "\",\"SessionID\":\"" + sessionID + "\",\"OnBehalfOfUserID\":\"0\",\"CompanyID\":\"100\",\"SubscriberID\":\"10000\",\"AppID\":\"learner.learnfromnative.com\",\"AppVersion\":\"1.0\",\"AppPlatform\":\"Web\",\"IsDeveloper\":\"0\",\"ServiceAccessToken\":\"BBADCACCBCCJDC - BBADCACCBCCJDC\"}",
          //     OperationName: "Command"
          // };

          // Execute(oInsertUserCompanySessionLogAndSelectFewFromMultipleTables).then(response =>
          //     {
          //         if (!response.DataIsLoaded)
          //             return <div><h1> Pleses wait some time.... </h1> </div>
          //         else
          //         {
          //             if (response.message != 'Successfull')
          //             {
          //                 alert(response.message)
          //             }
          //         }
          //     })

          const obj = {
            ...sessionDetails,
            UserID: userDetails[0].UserID,
            UserName: "",
            UserType: "",
            EmailID: "",
            LoginProvider: "Mobile No",
            TeachingLanguageID: "",
          };
          localStorage.setItem("items", JSON.stringify({ ...obj }));
          $("#loginModalCenter").modal("hide");
          closeSignUpDialog();
          //navigateToHomePage()
        } else {
          alert(response.message);
        }
      }
    });
  };

  const policyPrivacyDetails = () => {
    if (privacyPolicy.length > 0) {
      return (
        <iframe
          style={{ height: "500px", width: "400px" }}
          src={privacyPolicy[0].AppPolicyDocumentURL}
        ></iframe>
      );
    }
  };

  const termsOfUseDetails = () => {
    if (termsOfUse.length > 0) {
      return (
        <iframe
          style={{ height: "500px", width: "400px" }}
          src={termsOfUse[0].AppPolicyDocumentURL}
        ></iframe>
      );
    }
  };

  const contactUslabelVisibility = () => {
    var supportWhatsAppNo = "+91 8058058089";
    if (supportWhatsAppNo) {
      return <ContactUs supportWhatsAppNo={supportWhatsAppNo} />;
    }
  };

  return (
    <>
      <div>
        <div id="recaptcha-container"></div>
        <div id="loginform">
          <div className="text-center">
            <label className="content-lbl">
              <b>Welcome, </b>Login to Continue
            </label>
          </div>
          {login()}
          {/* <OtherMethods /> */}
          {contactUslabelVisibility()}
        </div>
      </div>

      <Dialog
        isOpen={signUpDialogProp.IsShow}
        onDismiss={closeSignUpDialog}
        dialogContentProps={SignUpInformationDialogContent}
      >
        <Stack {...columnProps}>
          <label className="popupContent-lbl">{userDetails}</label>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            className="CTA-btn"
            style={{ backgroundColor: "#002475" }}
            text="Cancel"
            onClick={() => closeSignUpDialog()}
          />
          <PrimaryButton
            className="CTA-btn"
            style={{ backgroundColor: "#002475" }}
            text="Sign Up"
            onClick={() => onSignUp(phoneNo.MobileNo)}
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        styles={{
          main: {
            selectors: {
              ["@media (min-height: 500px)"]: {
                height: "95%",
                minHeight: "95%",
                maxHeight: "95%",
                width: "30%",
                minWidth: "30%",
                maxWidth: "30%",
              },
            },
          },
        }}
        isOpen={policyPrivacyProp.IsShow}
        onDismiss={closePolicyPrivacyDialog}
      >
        {policyPrivacyDetails()}
        <br />
        <div style={{ textAlign: "right" }}>
          <label
            className="popupContent-lbl"
            style={{ fontWeight: "bold" }}
            onClick={() => closePolicyPrivacyDialog()}
          >
            {" "}
            Close
          </label>
        </div>
      </Dialog>

      <Dialog
        styles={{
          main: {
            selectors: {
              ["@media (min-height: 500px)"]: {
                height: "95%",
                minHeight: "95%",
                maxHeight: "95%",
                width: "30%",
                minWidth: "30%",
                maxWidth: "30%",
              },
            },
          },
        }}
        isOpen={termsOfUseProp.IsShow}
        onDismiss={closeTermsOfUseDialog}
      >
        {termsOfUseDetails()}
        <br />
        <div style={{ textAlign: "right" }}>
          <label
            className="popupContent-lbl"
            style={{ fontWeight: "bold" }}
            onClick={() => closeTermsOfUseDialog()}
          >
            Close{" "}
          </label>
        </div>
      </Dialog>
    </>
  );
}

export default LoginPage;

/*minWidth added for classbooking*/
import React, { useEffect, useState } from "react";
import { Execute } from "../Services/APIService";
import { Dialog, DialogType, PrimaryButton } from "@fluentui/react";
import ClassBookingConfirmation from "./ClassBookingConfirmation";

// var sessionData = localStorage.getItem("items");
var sessionData = localStorage.getItem("items");
var sessionDetails = JSON.parse(sessionData);
var sessionID = "";
var userID;

// if (sessionDetails != null || sessionDetails != undefined)
// {
//     sessionID = sessionDetails.SessionID
//     userID = sessionDetails.UserID
// }

if (sessionDetails != null || sessionDetails != undefined) {
  sessionID = "3";
  userID = sessionDetails.UserID;
}
const classBookingDialogContent = {
  type: DialogType.normal,
  title: "Class Booking",
  showCloseButton: true,
};

const ClassBooking = (props) => {
  const handleClick = () => {
    props.onChildClick();
  };

  const [bookingConfirmationDialogProp, setBookingConfirmationDialogProp] =
    React.useState({ ClassSlotID: "", PurchaseClassID: "", IsShow: false });
  const openBookingConfirmationDialog = (ClassSlotID, PurchaseClassID) => {
    setBookingConfirmationDialogProp({
      ClassSlotID: ClassSlotID,
      PurchaseClassID: PurchaseClassID,
      IsShow: true,
    });
  };
  const closeBookingConfirmationDialog = () => {
    setBookingConfirmationDialogProp({
      ClassSlotID: "",
      PurchaseClassID: "",
      IsShow: false,
    });
    window.location.reload();
  };
  const [classData, setClassData] = useState([]);

  useEffect(() => {
    let oSelectFewFromClassSlotAndMastersWhereClassID = {
      ActionName: "LFN.GTra_SelectFewFromClassSlotAndMastersWhereClassID",
      ParameterJSON: '{"ClassID":"' + props.ClassID + '"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromClassSlotAndMastersWhereClassID).then((response) => {
      if (!response.DataIsLoaded)
        return (
          <div>
            <h1> Please wait some time.... </h1>{" "}
          </div>
        );
      else {
        if (response.message === "Successfull") {
          setClassData(response.items);
        } else {
          alert(response.message);
        }
      }
    });
  }, []);

  const bookedClasses = classData.map((obj) => {
    return (
      <>
        <div className="popupContent-lbl" style={{ margin: "20px" }}>
          <div
            className="p-3"
            style={{
              backgroundColor: "#8AA4FF",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <label style={{ color: "white", fontWeight: "bold" }}>
              {obj.SlotDate}
            </label>
          </div>
          <div
            className="p-3"
            style={{
              backgroundColor: "#E2E9FF",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div className="row">
              <div className="col-md-9 my-auto">
                <label>{obj.SlotTime + "-" + obj.SlotToOnTime}</label>
              </div>
              <div className="col-md-3 my-auto">
                <PrimaryButton
                  className="CTA-btn"
                  style={{
                    backgroundColor: "white",
                    color: "#002475",
                    borderColor: "white",
                  }}
                  text="Book Class"
                  onClick={() => {
                    onBookClass(obj.ClassSlotID);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  });

  const onBookClass = (ClassSlotID) => {
    var purchaseClassID = props.PurchaseClassID;
    let oUpdatePurchaseClassesForClassSlotBooking = {
      ActionName: "LFN.GTra_UpdatePurchaseClassesForClassSlotBooking",
      ParameterJSON:
        '{"ClassSlotID":"' +
        ClassSlotID +
        '","PurchaseClassID":"' +
        purchaseClassID +
        '","RowsAffecting":"1"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
    Execute(oUpdatePurchaseClassesForClassSlotBooking).then((response) => {
      if (!response.DataIsLoaded)
        return (
          <div>
            <h1> Please wait some time.... </h1>{" "}
          </div>
        );
      else {
        if (response.message === "Successfull") {
          //openBookingConfirmationDialog(ClassSlotID, purchaseClassID);
          alert("Class Successfully Booked");
          props.onDismiss();
        } else {
          alert(response.message);
          props.onDismiss();
        }
      }
    });
  };

  return (
    <>
      <Dialog
        maxWidth={"100%"}
        minWidth={"70%"}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        dialogContentProps={classBookingDialogContent}
        hidden={!props.isOpen}
      >
        {classData.length > 0 ? (
          <div className="bookedClasseslabel" style={{ marginLeft: "20px" }}>
            <label
              className="popupContent-lbl"
              style={{ color: "#002475", fontWeight: "bold" }}
            >
              {props.ClassName}
            </label>
          </div>
        ) : (
          <div className="bookedClasseslabel" style={{ marginLeft: "20px" }}>
            <label
              className="popupContent-lbl"
              style={{ color: "#002475", fontWeight: "bold" }}
            >
              Please try after sometime, Slots not available for now
            </label>
          </div>
        )}
        {bookedClasses}
      </Dialog>
      {bookingConfirmationDialogProp.IsShow == true && (
        <ClassBookingConfirmation
          isOpen={bookingConfirmationDialogProp.IsShow}
          onDismiss={closeBookingConfirmationDialog}
          ClassSlotID={bookingConfirmationDialogProp.ClassSlotID}
          PurchaseClassID={bookingConfirmationDialogProp.PurchaseClassID}
        />
      )}
    </>
  );
};

export default ClassBooking;

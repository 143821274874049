import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ISessionData
{
  DeviceInstallationID:string,
  DeviceRegistrationID:string,
  SessionID:string,
  EmailID:string,
  IsPaidPlan:string,
  LoginProvider:string,
  MobileNo:string,
  TeachingLanguageID:string,
  UserID:string,
  UserName:string,
  UserType:string
}

const initialState: ISessionData = 
{
  DeviceInstallationID:"",
  DeviceRegistrationID:"",
  SessionID:"",
  EmailID:"",
  IsPaidPlan:"",
  LoginProvider:"",
  MobileNo:"",
  TeachingLanguageID:"",
  UserID:"",
  UserName:"",
  UserType:""
};

export const sessionDataSlice = createSlice({
  name: "sessionData",
  initialState,
  reducers: {
    setSessionData: (state, action) => {
      return {
        DeviceInstallationID: action.payload.payload.DeviceInstallationID,
        DeviceRegistrationID:action.payload.payload.DeviceRegistrationID,
        SessionID:action.payload.payload.SessionID,
        EmailID:action.payload.payload.EmailID,
        IsPaidPlan:action.payload.payload.IsPaidPlan,
        LoginProvider:action.payload.payload.LoginProvider,
        MobileNo:action.payload.payload.MobileNo,
        TeachingLanguageID:action.payload.payload.TeachingLanguageID,
        UserID:action.payload.payload.UserID,
        UserName:action.payload.payload.UserName,
        UserType:action.payload.payload.UserType
      };
    },
  },
});

export const selectSessionData = (state: RootState) =>
  state;

export const { setSessionData } = sessionDataSlice.actions;
export default sessionDataSlice.reducer;
import React from 'react'

const ContactUs = (props) => 
(  
    <div className='text-center popupContent-lbl'>
        <label style={{marginBottom:"10px"}} >Having Trouble?</label>
        <label style={{textDecoration:"underline",marginBottom:"10px",color:"#117800",marginLeft:"5px"}} onClick={()=>window.open("https://web.whatsapp.com/send?Phone="+props.supportWhatsAppNo+"&text="+"Hello")}>WhatsApp Us</label>
    </div>
);
  
export default ContactUs;
import React, { useEffect, useState } from "react";
import { Execute } from "../Services/APIService";
import { Dialog, DialogType, PrimaryButton } from "@fluentui/react";

// var sessionData = localStorage.getItem("items");
var sessionData = localStorage.getItem("items");
var sessionDetails = JSON.parse(sessionData);
var sessionID = "";
var userID;
var teachingLanguageID;

// if (sessionDetails != null || sessionDetails != undefined)
// {
//     sessionID = sessionDetails.SessionID
//     userID = sessionDetails.UserID
//     teachingLanguageID = sessionDetails.TeachingLanguageID
// }

if (sessionDetails != null || sessionDetails != undefined) {
  userID = sessionDetails.UserID;
  sessionID = "3";
  teachingLanguageID = 1;
}
const BookingConfirmationDialogContent = {
  type: DialogType.normal,
  title: "Class Booking Information",
  showCloseButton: true,
};

const ClassBookingConfirmation = (props) => {
  const [classBookingData, setClassBookingData] = useState([]);

  useEffect(() => {
    let oSelectFewFromPurchaseClassesAndClassSlotWherePurchaseClassIDAndClassSlotID =
      {
        ActionName:
          "LFN.GTra_SelectFewFromPurchaseClassesAndClassSlotWherePurchaseClassIDAndClassSlotID",
        ParameterJSON:
          '{"TeachingLanguageID":"' +
          teachingLanguageID +
          '","ClassSlotID":"' +
          props.ClassSlotID +
          '","PurchaseClassID":"' +
          props.PurchaseClassID +
          '"}',
        SessionDataJSON:
          '{"UserID":"' +
          userID +
          '","SessionID":"' +
          sessionID +
          '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
        OperationName: "Query",
      };
    Execute(
      oSelectFewFromPurchaseClassesAndClassSlotWherePurchaseClassIDAndClassSlotID
    ).then((response) => {
      if (!response.DataIsLoaded)
        return (
          <div>
            <h1> Please wait some time.... </h1>{" "}
          </div>
        );
      else {
        if (response.message === "Successfull") {
          setClassBookingData(response.items);
        } else {
          alert(response.message);
        }
      }
    });
  }, []);

  const bookedClassInformation = () => {
    if (classBookingData.length > 0) {
      return (
        <>
          <div
            className="popupContent-lbl"
            style={{
              position: "relative",
              top: "-250px",
              left: "10px",
              color: "white",
              marginBottom: "-220px",
            }}
          >
            <label style={{ marginBottom: "10px" }}>
              Thank you for booking the class!
            </label>
            <br />
            <label style={{ marginBottom: "20px" }}>
              Your Booking Details are:
            </label>
            <br />
            <label style={{ marginBottom: "20px" }}>
              Day:{" " + classBookingData[0].SlotDay}
            </label>
            <br />
            <label style={{ marginBottom: "20px" }}>
              Date:{" " + classBookingData[0].SlotDate}
            </label>
            <br />
            <label>Time:{" " + classBookingData[0].SlotTime}</label>
          </div>
        </>
      );
    }
  };

  return (
    <Dialog
      maxWidth="100%"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      dialogContentProps={BookingConfirmationDialogContent}
    >
      <img src="../BookingConfirmation.png"></img>
      {bookedClassInformation()}
    </Dialog>
  );
};

export default ClassBookingConfirmation;

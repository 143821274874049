import { json } from "stream/consumers";

function WhatMakesLearnFromNativeUnique() {
  const onBookAFreeDemo = () => {
    window.location.href = "/TestAIApp";
  };

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }
  var sections = [];

  if (countryName == "India") {
    sections = [
      {
        heading: "Learn English | Speak Up | Feel Confident",
        paragraph:
          "Embark on a journey of confidence and fluency in English with our AI-curated curriculum, expert tutors, and captivating interactive classes.",
      },
      {
        heading: "AI-Powered, Personalized Learning Experience",
        paragraph:
          "Our AI-curated curriculum ensures a customized learning journey tailored to your specific needs, strengths, and goals. You'll receive individual attention and targeted guidance to maximize your progress. ",
        url: "/assest/images/AI-Powered.png",
      },
      {
        heading: "Highly Experienced Tutors",
        paragraph:
          "Benefit from the expertise of our qualified and experienced tutors who are dedicated to your success. They provide personalized feedback, support, and motivation, helping you overcome challenges and reach your language goals. ",
        url: "/assest/images/highlyExperiencedTutors.png",
      },
      {
        heading: "Engaging and Interactive Classes",
        paragraph:
          "Experience fun, interactive, and dynamic classes that make learning English enjoyable. Engage in activities, discussions, and real-world simulations that enhance your language skills and boost your confidence in using English. ",
        url: "/assest/images/engagingAndInteractiveClasses.png",
      },
      {
        heading: "Application Based Learning",
        paragraph:
          "Our curriculum focuses on real-life situations and practical topics, equipping you with the language skills necessary for everyday communication and professional contexts. You'll learn to navigate real-world scenarios confidently and effectively. ",
        url: "/assest/images/applicationBasedLearning.png",
      },
      {
        heading: "Test AI - ChatBuddy ",
        paragraph:
          "Unlock the power of personalized learning with our revolutionary feature!  Simply upload your detailed feedback file, highlighting your strengths, weaknesses, and areas of focus. Our AI, fueled by your unique data, seamlessly generates tailored responses to your prompts. ",
        url: "/assest/images/testAI-TalkBuddy.png",
      },
    ];
  } else {
    sections = [
      {
        heading: "Learn | Excel | Conquer",
        paragraph:
          "Embark on a confidence-building journey in English, Math, and Science, guided by our AI-driven curriculum, seasoned tutors, and engaging interactive classes.",
      },
      {
        heading: "Personalized Learning Experience:",
        paragraph:
          "Tailored learning paths for each student, blending AI technology and human expertise to meet individual needs and learning styles",
        url: "/assest/images/PersonalizedLearningExperience.png",
      },
      {
        heading: "Globally Connected Tutors:",
        paragraph:
          "Access to top tutors from around the world, offering diverse perspectives and expertise to enhance learning outcomes.",
        url: "/assest/images/GloballyConnectedTutor.png",
      },
      {
        heading: "AI Co-Pilot for Tutors:",
        paragraph:
          "Innovative AI tool assists tutors in designing customized curricula, streamlining lesson planning, and enhancing teaching effectiveness.",
        url: "/assest/images/AI-Co-PilotForTutors.png",
      },
      {
        heading: "24/7 On-Demand Support",
        paragraph:
          "Students have access to an AI tutor anytime, anywhere, providing instant help with practice, homework, and learning challenges",
        url: "/assest/images/247On-DemandSupport.png",
      },
      {
        heading: "Evidence-Based Approach",
        paragraph:
          "Rigorous data analysis and evidence-based strategies drive continuous improvement, ensuring the highest standards of academic support and outcomes.",
        url: "/assest/images/EvidenceBasedApproach.png",
      },
    ];
  }

  return (
    <>
      <div className="learnFromNativeUnique">
        <img
          className="img-responsive pinkCurve"
          src="assest/images/pinkCurve-1.png"
        ></img>
        <div className="learnFromNativeUnique-heading">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 mb-2">
              <h1 className="heading">What Makes Learn From Native Unique?</h1>
            </div>
          </div>
        </div>

        <div className="learnFromNativeUnique-content learnFromNativeUnique-heading text-center text-md-start">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-5 order-1 order-md-1 d-flex justify-content-center justify-content-md-center">
              <img src={sections[1].url} id="web-banner"></img>
              <img
                src={sections[1].url}
                id="mob-banner"
                className="d-none"
              ></img>
            </div>
            <div className="col-md-2 d-none d-md-block order-2 order-md-2"></div>
            <div className="col-12 col-md-5 order-2 order-md-3">
              <h2 className="heading">{sections[1].heading}</h2>
              <p>{sections[1].paragraph}</p>
            </div>
          </div>
          <br />
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-5 order-1 order-md-3 d-flex justify-content-center justify-content-md-center">
              <img src={sections[2].url} id="web-banner"></img>
              <img
                src={sections[2].url}
                id="mob-banner"
                className="d-none"
              ></img>
            </div>
            <div className="col-md-2 d-none d-md-block order-2"></div>
            <div className="col-12 col-md-5 order-2 order-md-1">
              <h2 className="heading">{sections[2].heading}</h2>
              <p>{sections[2].paragraph}</p>
            </div>
          </div>
          <br />
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-5 order-1 order-md-1 d-flex justify-content-center justify-content-md-center">
              <img src={sections[3].url} id="web-banner"></img>
              <img
                src={sections[3].url}
                id="mob-banner"
                className="d-none"
              ></img>
            </div>
            <div className="col-md-2 d-none d-md-block order-1 order-md-2"></div>
            <div className="col-12 col-md-5 order-2 order-md-3">
              <h2 className="heading">{sections[3].heading}</h2>
              <p>{sections[3].paragraph}</p>
            </div>
          </div>
          <br />
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-5 order-1 order-md-3 d-flex justify-content-center justify-content-md-center">
              <img src={sections[4].url} id="web-banner"></img>
              <img
                src={sections[4].url}
                id="mob-banner"
                className="d-none"
              ></img>
            </div>
            <div className="col-md-2 d-none d-md-block order-1 order-md-2"></div>
            <div className="col-12 col-md-5 order-2 order-md-1">
              <h2 className="heading">{sections[4].heading}</h2>
              <p>{sections[4].paragraph}</p>
            </div>
          </div>
          <br />

          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-5 order-1 order-md-1 d-flex justify-content-center justify-content-md-center">
              <img src={sections[5].url} id="web-banner"></img>
              <img
                src={sections[5].url}
                id="mob-banner"
                className="d-none"
              ></img>
            </div>
            <div className="col-md-2 d-none d-md-block order-1 order-md-2"></div>
            <div className="col-12 col-md-5 order-2 order-md-3">
              <h2 className="heading">{sections[5].heading}</h2>
              <p>{sections[5].paragraph}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatMakesLearnFromNativeUnique;

import { Link } from "react-router-dom";

function Header(props) {
  function onDownloadApp() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.learnfromnative.apps",
      "_blank"
    );
  }
  function onLogin() {
    window.open("https://learner.learnfromnative.com", "_blank");
  }
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);
  var kidsMenuName;
  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  if (countryName == "India") {
    kidsMenuName = "Kids";
  } else {
    kidsMenuName = "K-10 Students";
  }

  return (
    <>
      <nav className="header navbar navbar-expand-lg navbar-light ">
        <div className="header-bar d-flex align-items-center justify-content-between">
          <img
            src="/assest/images/icons/englishLanguageIcon.png"
            alt="Icon"
            className="btn-icon d-md-none"
            id="englishLanguageIcon"
          ></img>
          <a href="/" className="navbar-brand">
            <img
              className="brand-logo img-fluid"
              src="/assest/images/lfn-logo-with-text.png"
              alt="LearnFromNative"
            ></img>
          </a>
          <img
            src="/assest/images/icons/download-icon-header.png"
            alt="Icon"
            className="btn-icon d-md-none"
            onClick={onDownloadApp}
          ></img>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav">
            {/* <li className="nav-item">
                    <a className="nav-link" href="https://learnfromnative.com/about-us/">About us</a>
                </li> */}
            <li className="nav-item">
              {/* <a className={props.active === "Kids" ? "nav-link active" : "nav-link"} href={`/Kids/${props.CountryID}`}>Kids</a> */}
              <a
                className={
                  props.active === "Kids" ? "nav-link active" : "nav-link"
                }
                href="/Kids"
              >
                {kidsMenuName}
              </a>
              {/* <Link to="/Kids" className={props.active === "Kids" ? "nav-link active" : "nav-link"}>
                        Kids
                    </Link> */}
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#planPricingSection">
                Book A Tutor
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  props.active === "Blogs" ? "nav-link active" : "nav-link"
                }
                href="/Blogs"
              >
                Blogs
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  props.active === "/HowITWorks"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/Kids#howLearnFromNativeWorks"
              >
                How LFN Works
              </a>
            </li>

            <li className="nav-item ">
              <a
                className={
                  props.active === "BecomeATutor"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/TutorEnrollment"
              >
                Become A Tutor
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item active d-none d-md-block">
              <button
                type="button"
                className="btn-login mb-2 mb-md-0"
                onClick={onLogin}
              >
                Log In
              </button>
            </li>
            <li className="nav-item d-none d-md-block">
              <button
                type="button"
                className="btn-turquoise"
                onClick={onDownloadApp}
              >
                Download the App &nbsp;
                <img
                  src="/assest/images/download-icon.png"
                  alt="Icon"
                  className="btn-icon"
                ></img>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;

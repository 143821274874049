import { useEffect } from "react";
import Header from "../component/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { colorCode } from "../utils/colors";

function TutorProfile() {
  var webAppName = "WebsitePortal";
  const location = useLocation();
  const navigate = useNavigate();

  const tutorProfileDetails = location.state.tutorProfileDetails;
  const type = location.state.type;

  const planOfferingItems = location.state.planOfferingItems;

  const userImgPath =
    "https://liveapi.learnfromnative.com/documents/User Photos/";
  const userCountryPath =
    "https://liveapi.learnfromnative.com/documents/Country/";

  const index = Math.floor(Math.random() * colorCode.length);

  const handleOneOnOnePayNow = (obj, type) => {
    if (type == "Book a Free Demo") {
      navigate("/FreeBillingInvoice", { state: { tutorProfileDetails: obj } });
    } else {
      navigate("/BillingInvoice", { state: { tutorProfileDetails: obj } });
    }
  };

  return (
    <>
      {webAppName != "LearnerPortal" && <Header />}
      <div className="tutorProfile">
        <div className="row">
          <div className="col-md-8">
            <div className="row align-items-center tutorProfile-info">
              <div className="col-md-3">
                <img
                  src={userImgPath + tutorProfileDetails.TeacherID + ".png"}
                  style={{
                    maxWidth: "100%",
                    borderRadius: "50%",
                    height: "200px",
                    width: "200px",
                    border: `10px solid ${colorCode[index]}`,
                  }}
                  className="tutotProfilePicture"
                ></img>
              </div>
              <div className="col-md-9">
                <h1>{tutorProfileDetails.TeacherName}</h1>
                <h5 className="mb-3">
                  {tutorProfileDetails.Experience + "+ years' experience"}
                </h5>
                <h6 className="d-none d-md-block">
                  <img
                    src="/core/verified.png"
                    style={{ width: "30px", height: "30px" }}
                  ></img>{" "}
                  <span>Verified</span>
                </h6>
                <h6 className="d-none d-md-block">
                  <img
                    src={
                      userCountryPath + tutorProfileDetails.CountryID + ".png"
                    }
                    style={{ width: "30px", height: "30px" }}
                  ></img>{" "}
                  <span>{tutorProfileDetails.CountryName}</span>{" "}
                </h6>
              </div>
              {/* Mobile  */}
              <div className="d-md-none">
                <div className="row mt-3">
                  <div className="col-3 d-flex flex-column align-items-center">
                    <span>
                      <img
                        src="/core/verified.png"
                        style={{ width: "30px", height: "30px" }}
                      ></img>
                    </span>
                    <span>Verified</span>
                  </div>

                  {(() => {
                    if (
                      tutorProfileDetails.PlanOfferPricing ===
                      tutorProfileDetails.PlanOriginalPricing
                    ) {
                      return (
                        <>
                          <div className="col-4 d-flex flex-column align-items-center">
                            <span id="perClassPricing">
                              <b>
                                {(tutorProfileDetails.PlanPricingCurrency ==
                                "INR"
                                  ? tutorProfileDetails.PlanPricingCurrency +
                                    " "
                                  : "$") +
                                  tutorProfileDetails.PlanOriginalPricing}
                              </b>
                            </span>
                            <span>
                              Per{" "}
                              <b>
                                {tutorProfileDetails.PlanPerClassMinutes} min{" "}
                              </b>{" "}
                              {tutorProfileDetails.PlanDurationUOM}
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div className="col-4 d-flex flex-column align-items-center">
                            <span>
                              {(tutorProfileDetails.PlanPricingCurrency == "INR"
                                ? tutorProfileDetails.PlanPricingCurrency + " "
                                : "$") + tutorProfileDetails.PlanOfferPricing}
                            </span>
                            <span
                              style={{
                                textDecoration: "line-through",
                                textDecorationColor: "red",
                              }}
                            >
                              {(tutorProfileDetails.PlanPricingCurrency == "INR"
                                ? tutorProfileDetails.PlanPricingCurrency + " "
                                : "$") +
                                tutorProfileDetails.PlanOriginalPricing}
                            </span>
                            <span>
                              Per {tutorProfileDetails.PlanPerClassMinutes} min{" "}
                              {tutorProfileDetails.PlanDurationUOM}
                            </span>
                          </div>
                        </>
                      );
                    }
                  })()}

                  <div className="col-3 d-flex flex-column align-items-center">
                    <span>
                      <b>{tutorProfileDetails.TeacherRating}</b>{" "}
                      <img src="/core/star.png"></img>
                    </span>
                    <span>Rating</span>
                  </div>
                  <div className="col-2 d-flex flex-column align-items-center">
                    <img
                      src={
                        userCountryPath + tutorProfileDetails.CountryID + ".png"
                      }
                      style={{ width: "30px", height: "30px" }}
                    ></img>
                    <span>{tutorProfileDetails.CountryName}</span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <h2>My Profile</h2>
              <p>{tutorProfileDetails.SubjectBiography}</p>
            </div>
            <div className="row">
              <h2>Offerings</h2>
              {tutorProfileDetails.SubjectOfferingDescription ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: tutorProfileDetails.SubjectOfferingDescription,
                  }}
                  className="subjectOfferingDescription"
                ></div>
              ) : (
                <>
                  <p>
                    Thank you for your interest in our one-to-one courses. Our
                    course counsellor will contact you within the next 24 hours
                    to understand your interests and needs better Customized
                    Curriculum Personalized Attention Learn at your own pace
                    Interactive and Engaging Classes Free Demo Class Vocabulary,
                    Public Speaking, Creative Writing, Accent, Grammar, and much
                    more! {" "}
                  </p>
                  <p>
                    {planOfferingItems.map((obj) => (
                      <li className="offeringDescription popupContent-lbl">
                        {obj.OfferingDescription}
                      </li>
                    ))}
                  </p>
                </>
              )}
            </div>
          </div>
          {/* Desktop  */}
          <div className="col-md-4 d-none d-md-block  text-center justify-content-center ">
            <img
              src={userImgPath + tutorProfileDetails.TeacherID + ".png"}
              style={{ height: "364px", width: "100%" }}
            ></img>
            <div className="container d-flex justify-content-center m-3">
              <div className="text-center">
                {(() => {
                  if (
                    tutorProfileDetails.PlanOfferPricing ===
                    tutorProfileDetails.PlanOriginalPricing
                  ) {
                    return (
                      <>
                        <div className="">
                          <span id="perClassPricing">
                            <b>
                              {(tutorProfileDetails.PlanPricingCurrency == "INR"
                                ? tutorProfileDetails.PlanPricingCurrency + " "
                                : "$") +
                                (tutorProfileDetails.CardType ==
                                "Book Demo Class"
                                  ? tutorProfileDetails.PlanDemoPricing > 0
                                    ? tutorProfileDetails.PlanDemoPricing
                                    : tutorProfileDetails.PlanOriginalPricing
                                  : tutorProfileDetails.PlanOriginalPricing)}
                            </b>
                          </span>
                          <br></br>
                          <span>
                            Per{" "}
                            <b>
                              {tutorProfileDetails.PlanPerClassMinutes} min{" "}
                            </b>{" "}
                            {tutorProfileDetails.PlanDurationUOM}
                          </span>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className="">
                          <span>
                            {(tutorProfileDetails.PlanPricingCurrency == "INR"
                              ? tutorProfileDetails.PlanPricingCurrency + " "
                              : "$") +
                              tutorProfileDetails.CardType ==
                            "Book Demo Class"
                              ? tutorProfileDetails.PlanDemoPricing > 0
                                ? tutorProfileDetails.PlanDemoPricing
                                : tutorProfileDetails.PlanOfferPricing
                              : tutorProfileDetails.PlanOfferPricing}
                          </span>
                          <br></br>
                          <span
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "red",
                            }}
                          >
                            {(tutorProfileDetails.PlanPricingCurrency == "INR"
                              ? tutorProfileDetails.PlanPricingCurrency + " "
                              : "$") + tutorProfileDetails.PlanOriginalPricing}
                          </span>
                          <br></br>
                          <span>
                            per {tutorProfileDetails.PlanPerClassMinutes} min{" "}
                            {tutorProfileDetails.PlanDurationUOM}
                          </span>
                        </div>
                      </>
                    );
                  }
                })()}
              </div>

              <div className="mx-5">
                <span>
                  <b>{tutorProfileDetails.TeacherRating}</b>{" "}
                  <img src="/core/star.png"></img>
                </span>
                <br></br>
                <span>Rating</span>
              </div>
            </div>
            <button
              className="btn btn-tomato w-100 p-2"
              style={{ fontSize: "24px", fontWeight: "600" }}
              onClick={() =>
                handleOneOnOnePayNow(
                  tutorProfileDetails,
                  tutorProfileDetails.CardType == "Book Demo Class"
                    ? "Book a Free Demo"
                    : "Book A Class"
                )
              }
            >
              {tutorProfileDetails.CardType == "Book Demo Class"
                ? tutorProfileDetails.PlanDemoPricing == 0
                  ? "Book Now"
                  : "Pay Now"
                : "Pay Now"}
            </button>
          </div>
          <button
            className="btn btn-mayGreen w-100 d-block d-md-none fixed-bottom mb-0 p-2 fw-bold fs-5"
            onClick={() =>
              handleOneOnOnePayNow(
                tutorProfileDetails,
                tutorProfileDetails.CardType == "Book Demo Class"
                  ? "Book a Free Demo"
                  : "Book A Class"
              )
            }
          >
            Book Now
          </button>
        </div>
      </div>
    </>
  );
}
export default TutorProfile;

import { useLocation } from "react-router-dom";

function TestAI() {
    const location = useLocation();
    const websiteEndPointURL = location.state.websiteEndPointURL




    return (

        <iframe
            src={"https://" + websiteEndPointURL}
            frameBorder="0"
            allowFullScreen
            scrolling="no"
            style={{
                width: "100%", // Set the width to 100% of the parent element
                height: "90vh", // Set the height to 90% of the viewport height

            }}
        ></iframe>

    )
}
export default TestAI
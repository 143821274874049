import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import loginOptionsSlice from "../features/appData/loginOptionsSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import sessionDataSlice from '../features/appData/sessionDataSlice';
import countryOptionsSlice from '../features/appData/countryOptionsSlice';

const persistConfig = {
  key: 'root',
  storage,
}

const allReducers = combineReducers({
  loginDetails: persistReducer(persistConfig, loginOptionsSlice),
  sessionData: persistReducer(persistConfig, sessionDataSlice),
  countryDetails: persistReducer(persistConfig, countryOptionsSlice)
});

export const store = configureStore({
  reducer:allReducers
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store)

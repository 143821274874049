function USAStudentTestimonial() {
  return (
    <>
      {/* <div className="studentTestimonial m-4 m-md-5">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <h1 className="heading">Student's testimonial</h1><br></br>
                    </div>
                </div>


                <div className="row gy-6 USAStudentTestimonial d-flex justify-content-between flex-nowrap">
                    <div className="col">
                        <div className="testimonial" style={{ boxShadow: "0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15)", padding: "100px 30px 30px 30px", borderRadius: "5px", position: "relative" }}>
                            <div className="testimonial-author" style={{ background: "#FFD4D4" }}>
                                <div className="text-center">
                                    <h5><b>By Scarlet King</b></h5>
                                    <h5><b>Student name: Kaitlyn King</b></h5>
                                </div>

                            </div>
                            <p className="text-center">Learn From Native’s personalized 1 on 1 learning approach, ongoing feedback, communication, and exceptional quality of tutors have genuinely impressed me. I find their services to be both remarkable and valuable.</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="testimonial" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px", padding: "100px 30px 30px 30px", borderRadius: "5px", position: "relative" }}>
                            <div className="testimonial-author" style={{ background: "#FFF8D4" }}>
                                <div className="text-center">
                                    <h5><b>By Emily Stennis</b></h5>
                                    <h5><b>Student name: Nihgel German</b></h5>
                                </div>
                            </div>
                            <p className="text-center">My son Nihgel has been benefiting from high-dosage tutoring by LFN since April 2023. I've been matched with an exceptional tutor through their model, and my son eagerly anticipates his weekly sessions. Their approach and commitment to fostering my son's proficiency in ELA have made a significant positive impact, leading to noticeable enhancements in his reading skills. Their curriculum, design, and overall product have met my expectations </p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="testimonial" style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px", padding: "100px 30px 30px 30px", borderRadius: "5px", position: "relative" }}>
                            <div className="testimonial-author" style={{ background: "#E4FFDF" }}>
                                <div className="text-center">
                                    <h5><b>By Scarlet King</b></h5>
                                    <h5><b>Student name: Nihgel German </b></h5>
                                </div>
                            </div>
                            <p className="text-center">I'm Agustina, and I want to share my experience with Learn From Native. My daughter, Salma, has been with them for 3+ months, taking weekly ELA classes. The tutors and staff are committed to helping her succeed. Their scheduling, content quality, and personalized approach have greatly benefited Salma, who is introverted. The tutor has made her comfortable and boosted her confidence. </p>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="row">
        <div className="col-md-12">
          <div
            id="USAtestimonial-carousel"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="8000"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="studentTestimonial m-4 m-md-5">
                  <div className="row mb-5">
                    <div className="col-md-12">
                      <h1 className="heading">Student's testimonial</h1>
                      <br></br>
                    </div>
                  </div>

                  <div className="row gy-6 USAStudentTestimonial d-flex justify-content-between flex-nowrap">
                    <div className="col">
                      <div
                        className="testimonial"
                        style={{
                          boxShadow:
                            "0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15)",
                          padding: "100px 30px 30px 30px",
                          borderRadius: "5px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="testimonial-author"
                          style={{ background: "#FFD4D4" }}
                        >
                          <div className="text-center">
                            <h5>
                              <b>By Scarlet King</b>
                            </h5>
                            <h5>
                              <b>Student name: Kaitlyn King</b>
                            </h5>
                          </div>
                        </div>
                        <p className="text-center">
                          Learn From Native’s personalized 1 on 1 learning
                          approach, ongoing feedback, communication, and
                          exceptional quality of tutors have genuinely impressed
                          me. I find their services to be both remarkable and
                          valuable.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="testimonial"
                        style={{
                          boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                          padding: "100px 30px 30px 30px",
                          borderRadius: "5px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="testimonial-author"
                          style={{ background: "#FFF8D4" }}
                        >
                          <div className="text-center">
                            <h5>
                              <b>By Emily Stennis</b>
                            </h5>
                            <h5>
                              <b>Student name: Nihgel German</b>
                            </h5>
                          </div>
                        </div>
                        <p className="text-center">
                          My son Nihgel has been benefiting from high-dosage
                          tutoring by LFN since April 2023. I've been matched
                          with an exceptional tutor through their model, and my
                          son eagerly anticipates his weekly sessions. Their
                          approach and commitment to fostering my son's
                          proficiency in ELA have made a significant positive
                          impact, leading to noticeable enhancements in his
                          reading skills. Their curriculum, design, and overall
                          product have met my expectations 
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="testimonial"
                        style={{
                          boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                          padding: "100px 30px 30px 30px",
                          borderRadius: "5px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="testimonial-author"
                          style={{ background: "#E4FFDF" }}
                        >
                          <div className="text-center">
                            <h5>
                              <b>By Scarlet King</b>
                            </h5>
                            <h5>
                              <b>Student name: Nihgel German </b>
                            </h5>
                          </div>
                        </div>
                        <p className="text-center">
                          I'm Agustina, and I want to share my experience with
                          Learn From Native. My daughter, Salma, has been with
                          them for 3+ months, taking weekly ELA classes. The
                          tutors and staff are committed to helping her succeed.
                          Their scheduling, content quality, and personalized
                          approach have greatly benefited Salma, who is
                          introverted. The tutor has made her comfortable and
                          boosted her confidence.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="studentTestimonial m-4 m-md-5">
                  <div className="row mb-5">
                    <div className="col-md-12">
                      <h1 className="heading">Student's testimonial</h1>
                      <br></br>
                    </div>
                  </div>

                  <div className="row gy-6 USAStudentTestimonial d-flex justify-content-between flex-nowrap">
                    <div className="col">
                      <div
                        className="testimonial"
                        style={{
                          boxShadow:
                            "0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15)",
                          padding: "100px 30px 30px 30px",
                          borderRadius: "5px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="testimonial-author"
                          style={{ background: "#FFD4D4" }}
                        >
                          <div className="text-center">
                            <h5>
                              <b>By Sara Bejil</b>
                            </h5>
                            <h5>
                              <b>Student name: Armando Baldazo</b>
                            </h5>
                          </div>
                        </div>
                        <p className="text-center">
                          My son, Armando, has been receiving personalized,
                          high-impact ELA and Math tutoring from them for the
                          past 4-5 months. Armando, who is on the autism
                          spectrum, thrives on routine and familiarity. The
                          Learn From Native team and tutors have gone above and
                          beyond to create a comfortable and accommodating
                          environment for him. The impressive personalized
                          curriculum and instructional design have captured his
                          interest, and he eagerly anticipates his weekly
                          sessions.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="testimonial"
                        style={{
                          boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                          padding: "100px 30px 30px 30px",
                          borderRadius: "5px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="testimonial-author"
                          style={{ background: "#E4FFDF" }}
                        >
                          <div className="text-center">
                            <h5>
                              <b>By Myka Blunt</b>
                            </h5>
                            <h5>
                              <b>Student name: Jamel Graham </b>
                            </h5>
                          </div>
                        </div>
                        <p className="text-center">
                          I highly recommend Learn From Native for their
                          exceptional program. My son, Jamel Graham, has shown
                          remarkable improvement in his English skills over the
                          past 5 months through their personalized live classes.
                          Facing challenges in reading, we enrolled him in
                          high-impact tutoring sessions twice a week, each
                          lasting an hour. Thanks to their tailored approach,
                          engaging content, and consistent progress updates,
                          Jamel achieved proficiency in English{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <br></br>
            <ol className="carousel-indicators ">
              <li
                data-bs-target="#USAtestimonial-carousel"
                data-bs-slide-to="0"
                className="active"
              ></li>
              <li
                data-bs-target="#USAtestimonial-carousel"
                data-bs-slide-to="1"
              ></li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}
export default USAStudentTestimonial;

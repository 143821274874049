import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import MyTextField from "../Controls/MyTextField";
import {
  ICheckboxStyles,
  IComboBoxStyles,
  IDropdownOption,
  IDropdownStyles,
  ITextFieldStyles,
  PrimaryButton,
} from "@fluentui/react";
import MyDropDown from "../Controls/MyDropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Execute } from "../Services/APIService";
import TextField from "@mui/material/TextField";
import moment from "moment";
import MyCheckbox from "../Controls/MyCheckbox";
import MyComboBox from "../Controls/MyComboBox";
import Dailogue from "../Controls/Modal";

const isMediumScreen = window.innerWidth >= 768;

const checkboxStyle: Partial<ICheckboxStyles> = {
  text: {
    fontSize: "20px",
    marginLeft: "10px",
    marginTop: "30px",
  },
  checkbox: {
    top: isMediumScreen ? "30px" : "15px",
    marginLeft: "25px",
    backgroundColor: "white",
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
    border: "none",
  },
  checkmark: { color: "#1F9E61", fontWeight: "bold", backgroundColor: "white" },
};

const comboBoxStyles: Partial<IComboBoxStyles> = {
  input: {
    width: "100%",
    paddingLeft: "10px",
    height: "60px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  root: {
    height: "60px",
    marginTop: "10px",
    boxSizing: "content-box !important",
    border: "none",
  },
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: "auto", margin: "auto" },
  title: {
    fontSize: "18px",
    height: 60,
    paddingTop: 13,
    paddingLeft: 10,
    fontWeight: "bold",
    borderRadius: "10px",
    textAlign: "left",
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
    border: "none",
  },
  dropdownOptionText: { fontSize: "18px", height: "36px", paddingTop: "10px" },
  caretDownWrapper: { marginTop: 16, width: 20 },
};

var textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    width: "auto",
    margin: "auto",
    height: 60,
    borderRadius: "10px",
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
    border: "none",
  },
  field: { fontSize: "18px", fontWeight: "bold" },
};

const CountryNameList: IDropdownOption[] = [];
const oSelectFewFromCountryWhereNonDeletedForTelephoneNumber = {
  ActionName: "Core.GMst_SelectFewFromCountryWhereNonDeletedForTelephoneNumber",
  ParameterJSON: "",
  SessionDataJSON:
    '{"UserID":"","SessionID":"' +
    -1 +
    '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
  OperationName: "Query",
};
Execute(oSelectFewFromCountryWhereNonDeletedForTelephoneNumber).then(
  (response) => {
    if (response.DataIsLoaded) {
      response.items.map((entry) => {
        CountryNameList.push({
          key: entry.CountryID,
          id: JSON.stringify(entry),
          text: entry.CountryName,
        });
      });
    }
  }
);

const SubjectList: IDropdownOption[] = [];
function getSubjectList(countryID) {
  SubjectList.splice(0, SubjectList.length);
  const oSelectFewFromSubjectAndMasterWhereCountryID = {
    ActionName: "LFN.GMst_SelectFewFromSubjectAndMasterWhereCountryID",
    ParameterJSON: '{"CountryID ":"' + countryID + '"}',
    SessionDataJSON:
      '{"UserID":"","SessionID":"' +
      -1 +
      '","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
    OperationName: "Query",
  };
  Execute(oSelectFewFromSubjectAndMasterWhereCountryID).then((response) => {
    if (response.DataIsLoaded) {
      response.items.map((entry) => {
        SubjectList.push({
          key: entry.SubjectID,
          id: JSON.stringify(entry),
          text: entry.SubjectName,
        });
      });
    }
  });
}

function TutorEnrollment() {
  const defaultCountryDetails = {
    CountryCode: "USA",
    CountryDialingCode: "+1",
    CountryID: "3",
    CountryName: "United States",
    CountryNameWithDialingCode: "United States +1",
    TelephoneNumberValidationRegex: null,
    CurrencyCode: "USD",
  };

  useEffect(() => {
    fetch("https://www.geolocation-db.com/json/")
      .then((response) => response.json())
      .then((data) => {
        if (data.country_name) {
          if (CountryNameList.length > 0) {
            const matchedCountry = CountryNameList.find(
              (item) => item.text === data.country_name
            );
            if (matchedCountry != null) {
              var Data = JSON.parse(matchedCountry.id);
              setProps({
                CountryData: {
                  CountryID: Data.CountryID,
                  CountryDialingCode: Data.CountryDialingCode,
                  CountryName: Data.CountryName,
                  CountryCode: Data.CountryCode,
                  CurrencyCode: Data.CurrencyCode,
                },
              });
              getSubjectList(Data.CountryID);
            } else {
              setProps({
                CountryData: {
                  CountryID: defaultCountryDetails.CountryID,
                  CountryDialingCode: defaultCountryDetails.CountryDialingCode,
                  CountryName: defaultCountryDetails.CountryName,
                  CountryCode: defaultCountryDetails.CountryCode,
                  CurrencyCode: defaultCountryDetails.CurrencyCode,
                },
              });
              getSubjectList(defaultCountryDetails.CountryID);
            }
          }
        }
      });
  }, []);

  const [text, setText] = React.useState({
    Firstname: "",
    Lastname: "",
    EmailID: "",
  });
  function handleChange(event) {
    setText({ ...text, [event.target.id]: event.target.value });
  }

  const [props, setProps] = React.useState({
    CountryData: {
      CountryID: "",
      CountryDialingCode: "",
      CountryName: "",
      CountryCode: "",
      CurrencyCode: "",
    },
  });
  function onChangeProps(event, value) {
    var Data = JSON.parse(value.id);
    getSubjectList(Data.CountryID);
    setProps({
      ...props,
      [event.target.id]: Data,
    });
  }

  const [birthday, setBirthday] = useState(null);
  const onBirthdayChange = (e) => {
    const newDate = moment(new Date(e.target.value)).format("yyyy-MM-DD");
    setBirthday(newDate);
  };

  const calculateAge = () => {
    if (birthday) {
      const birthDate = moment(birthday);
      const today = moment();
      const age = today.diff(birthDate, "years");
      return age;
    }
    return null;
  };

  const isOver18 = calculateAge() > 18;

  const [endDates, setEndDates] = useState([]);
  const onEndDateChange = (e, index) => {
    const today = moment(new Date()).format("yyyy-MM-DD");
    var newDate = moment(new Date(e.target.value)).format("yyyy-MM-DD");
    if (newDate < today) {
      alert(
        "Your ongoing course should have a completion date that falls beyond today's date."
      );
      e.target.value = "";
    } else {
      const updatedEndDates = [...endDates];
      updatedEndDates[index] = newDate;
      setEndDates(updatedEndDates);
    }
  };

  const [phoneNo, setPhoneNo] = React.useState({ MobileNo: "" });
  function onMobileNoChange(event, newValue) {
    const sanitizedValue = newValue.replace(/[^0-9]/g, "");
    event.target.value = sanitizedValue;
    setPhoneNo({ ...phoneNo, [event.target.id]: event.target.value });
  }

  const [perHourCharge, setPerHourCharge] = React.useState({
    PerHourCharge: "",
  });
  function onPerHourChargeChange(event, newValue) {
    const sanitizedValue = newValue.replace(/[^0-9]/g, "");
    event.target.value = sanitizedValue;
    setPerHourCharge({
      ...perHourCharge,
      [event.target.id]: event.target.value,
    });
  }

  const [resume, setResume] = useState(null);
  const handleSelectionOfResume = (event) => {
    const file = event.target.files[0];
    const filename = file.name;
    const parts = filename.split(".");
    const extension = parts.pop();

    if (extension === "pdf" || extension === "docx" || extension === "doc") {
      setResume(file);
    } else {
      alert(
        "Please upload your resume in either MS Word or PDF format exclusively"
      );
    }
  };

  const [bachelorCertificate, setBachelorCertificate] = useState([]);
  const handleSelectionOfCertificate = (e, index) => {
    const files = [...bachelorCertificate];
    files[index] = e.target.files[0];
    const filename = files[index].name;
    const parts = filename.split(".");
    const extension = parts.pop();
    if (extension === "pdf" || extension === "jpeg") {
      setBachelorCertificate(files);
    } else {
      alert(
        "Kindly be aware that the certificate files only in PDF and JPEG formats will be considered for acceptance"
      );
    }
  };

  const [checkboxStates, setCheckboxStates] = useState([]);
  const handleCheckboxChange = (isChecked, index) => {
    if (isChecked) {
      setCheckboxStates((prevCheckboxStates) => [...prevCheckboxStates, index]);
    } else {
      setCheckboxStates((prevCheckboxStates) =>
        prevCheckboxStates.filter((item) => item !== index)
      );
    }
  };

  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const onSelectedSubjectChange = (event, option) => {
    const { selected } = option;

    if (selected) {
      setSelectedSubjects((prevSelectedKeys) => [
        ...prevSelectedKeys,
        option.key,
      ]);
    } else {
      setSelectedSubjects((prevSelectedKeys) =>
        prevSelectedKeys.filter((key) => key !== option.key)
      );
    }
  };

  const [configData, setConfigData] = React.useState([]);
  React.useEffect(() => {
    let oSelectFewFromMultipleTablesForMobileSplashScreen = {
      ActionName: "Core.GMst_SelectFewFromMultipleTablesForMobileSplashScreen",
      ParameterJSON: '{"ConfigurationNetwork":"Public"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"' +
        3 +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };
    Execute(oSelectFewFromMultipleTablesForMobileSplashScreen).then(
      (response) => {
        if (!response.DataIsLoaded)
          return (
            <div>
              <h1> Pleses wait some time.... </h1>{" "}
            </div>
          );
        else {
          if (response.message === "Successfull") {
            const filterConfigData = response.items.filter(
              (obj) => obj.DataTableName === "Configuration"
            );
            setConfigData(filterConfigData);
          } else {
            alert(response.message);
          }
        }
      }
    );
  }, []);

  if (configData.length > 0) {
    const filterFtpData = configData.filter(
      (obj) => obj.ConfigurationName === "FileServerA8CPath"
    );
    var ftpPath = filterFtpData[0].ConfigurationValue;

    const filterHttpData = configData.filter(
      (obj) => obj.ConfigurationName === "HTTPA8CFileServiceURL"
    );
    var httpPath = filterHttpData[0].ConfigurationValue;

    const filterResumeData = configData.filter(
      (obj) => obj.ConfigurationName === "FTPLFNRecruitmentResumeDirectory"
    );
    var resumeConfigurationValue = filterResumeData[0].ConfigurationValue;

    const filterCertificateData = configData.filter(
      (obj) => obj.ConfigurationName === "FTPLFNRecruitmentCertificateDirectory"
    );
    var certificateConfigurationValue =
      filterCertificateData[0].ConfigurationValue;
  }

  const uploadResume = (recruitmentNumber) => {
    const serverFilePath =
      ftpPath +
      resumeConfigurationValue +
      "\\" +
      recruitmentNumber +
      "_" +
      resume.name;
    const serverFilePathStringifyJson = JSON.stringify(serverFilePath);

    var reader = new FileReader();
    reader.readAsDataURL(resume);
    reader.onload = function () {
      var fileContentInBase64String = reader.result.toString().split(",").pop();
      const oFile = {
        ActionName: "WriteFile",
        ParameterJSON:
          '{"ServerFilePath":' +
          serverFilePathStringifyJson +
          ',"FileContentInBase64String":"' +
          fileContentInBase64String +
          '"}',
        SessionDataJSON:
          '{"UserID":"","SessionID":"' +
          3 +
          '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
        OperationName: "File",
      };
      Execute(oFile);
    };
  };

  const uploadCertificate = (recruitmentNumber) => {
    const serverFilePath =
      ftpPath + certificateConfigurationValue + "\\" + recruitmentNumber + "_";
    let currentIndex = 0;
    function uploadNextCertificate() {
      if (currentIndex < bachelorCertificate.length) {
        const file = bachelorCertificate[currentIndex];
        const serverFilePathStringifyJson = JSON.stringify(
          serverFilePath + file.name
        );
        const reader = new FileReader();
        reader.onload = function () {
          if (reader.result === null) {
            alert("Error reading one of the certificate files.");
          } else {
            var fileContentInBase64String = reader.result
              .toString()
              .split(",")
              .pop();
            const oFile = {
              ActionName: "WriteFile",
              ParameterJSON:
                '{"ServerFilePath":' +
                serverFilePathStringifyJson +
                ',"FileContentInBase64String":"' +
                fileContentInBase64String +
                '"}',
              SessionDataJSON:
                '{"UserID":"","SessionID":"' +
                3 +
                '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
              OperationName: "File",
            };
            Execute(oFile).then(() => {
              currentIndex++;
              uploadNextCertificate();
            });
          }
        };
        reader.readAsDataURL(file);
      }
    }
    uploadNextCertificate();
  };

  const onSubmitButton = () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const subjectIDCSV = selectedSubjects.join(",");
    const recruitmentNumber = Math.floor(
      1000000000 + Math.random() * 9000000000
    );
    const mobileNo =
      props.CountryData.CountryDialingCode + " " + phoneNo.MobileNo;
    const rowsAffecting = bachelorCertificate.length + 1;
    var resumeFilePath;
    var resumeFilePathStringifyJson;
    var resumeFilePathSize;
    if (resume != null) {
      resumeFilePath =
        httpPath +
        "/" +
        resumeConfigurationValue +
        "\\" +
        recruitmentNumber +
        "_" +
        resume.name;
      resumeFilePathStringifyJson = JSON.stringify(resumeFilePath);
      resumeFilePathSize = resumeFilePathStringifyJson.length;
    }

    const certificateArray = [];
    const missingEndDateIndices = [];
    for (let i = 0; i < bachelorCertificate.length; i++) {
      if (checkboxStates.includes(i) && !endDates[i]) {
        missingEndDateIndices.push(i);
      }
      var certificateName = bachelorCertificate[i].name.split(".");
      var certificateJson = {
        Pursuing: checkboxStates.includes(i),
        EndDate: endDates[i],
        CertificateName: certificateName[0],
        CertificateFilePath:
          httpPath +
          "/" +
          certificateConfigurationValue +
          "\\" +
          recruitmentNumber +
          "_" +
          bachelorCertificate[i].name,
      };
      certificateArray.push(certificateJson);
    }
    var certificateJsonString = JSON.stringify(certificateArray);

    if (text.Firstname === "") {
      alert("First Name is mandatory.");
    } else if (text.Lastname === "") {
      alert("Last Name is mandatory.");
    } else if (props.CountryData.CountryID === "") {
      alert("You need to select country first.");
    } else if (birthday === null) {
      alert("Birthdate is mandatory.");
    } else if (phoneNo.MobileNo === "") {
      alert("Mobile number is mandatory.");
    } else if (text.EmailID === "") {
      alert("Email ID is mandatory.");
    } else if (!emailPattern.test(text.EmailID)) {
      alert("Please enter a valid email address.");
    } else if (resume === null) {
      alert("You need to upload your resume.");
    } else if (subjectIDCSV.length <= 0) {
      alert("Please select prefered subject.");
    } else if (perHourCharge.PerHourCharge === "") {
      alert("Hourly Charge are mandatory.");
    } else if (missingEndDateIndices.length > 0) {
      alert("If you select Pursuing then you need to select End date.");
    } else if (resumeFilePathSize > 255) {
      alert(
        "Please reduce the filename of your resume path file to ensure it is within the upload size limit for successful submission."
      );
    } else if (isOver18 === false) {
      alert(
        "To proceed with your application as a Tutor on our portal, please ensure that your birthdate indicates that you are over 18 years of age. Kindly provide your accurate date of birth."
      );
    } else {
      const oInsertRecruitmentApplication = {
        ActionName: "LFN.GMst_InsertRecruitmentApplication",
        ParameterJSON:
          '{"FirstName ":"' +
          text.Firstname +
          '","LastName ":"' +
          text.Lastname +
          '","CountryID ":"' +
          props.CountryData.CountryID +
          '","Birthdate ":"' +
          birthday +
          '","MobileNo ":"' +
          mobileNo +
          '","EmailID ":"' +
          text.EmailID +
          '","PerHourCharge":' +
          perHourCharge.PerHourCharge +
          ',"PerHourChargeUOM ":"' +
          props.CountryData.CurrencyCode +
          '","SubjectIDCSV":"' +
          subjectIDCSV +
          '","RecruitmentNumber ":"' +
          recruitmentNumber +
          '","CertificateJson  ":' +
          certificateJsonString +
          ',"ResumeFilePath":' +
          resumeFilePathStringifyJson +
          ',"RowsAffecting":"' +
          rowsAffecting +
          '"}',
        SessionDataJSON:
          '{"UserID":"","SessionID":"' +
          3 +
          '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
        OperationName: "Command",
      };
      console.log("I", oInsertRecruitmentApplication);
      Execute(oInsertRecruitmentApplication).then((response) => {
        if (!response.DataIsLoaded)
          return (
            <div>
              <h1> Pleses wait some time.... </h1>{" "}
            </div>
          );
        else {
          if (response.message === "Successfull") {
            uploadResume(recruitmentNumber);
            if (bachelorCertificate) {
              uploadCertificate(recruitmentNumber);
            }
            showTutorEnrolledPopup();
          } else {
            alert(response.message);
          }
        }
      });
    }
  };

  const [addAnotherCertificate, setaddAnotherCertificate] = useState(0);
  const onAddAnotherCertificateClick = () => {
    setaddAnotherCertificate(addAnotherCertificate + 1);
  };

  const addCertificateControl = [];
  for (let i = 0; i <= addAnotherCertificate; i++) {
    var certificatePlaceHolder =
      i == 0
        ? "Upload bachelor’s Degree/Teaching Certificate(Optional)"
        : "Upload Teaching Certificate(Optional)";
    addCertificateControl.push(
      <>
        <div className="row" key={i}>
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="col-md-1 col-1">
                <FontAwesomeIcon
                  className="bachelorsCertificateBullet"
                  icon={faCircle}
                ></FontAwesomeIcon>
              </div>
              <div className="col-md-11 col-11">
                <label className="content-lbl d-block d-md-none mt-2 fw-bold">
                  Upload bachelor’s Certificate
                </label>
                <div className="d-none d-md-block">
                  <input
                    type="file"
                    accept="*"
                    onChange={(e) => handleSelectionOfCertificate(e, i)}
                    style={{ display: "none" }}
                    id={`fileInput${i}`}
                  />
                  <label
                    htmlFor={`fileInput${i}`}
                    className="basicProfile_CustomInput"
                  >
                    {bachelorCertificate[i]
                      ? bachelorCertificate[i].name
                      : isMediumScreen
                      ? certificatePlaceHolder
                      : ""}
                    <FontAwesomeIcon
                      className="bachelorsCertificateIcon"
                      icon={faArrowUpFromBracket}
                    ></FontAwesomeIcon>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-md-none">
            <div className="row">
              <div className="col-12">
                <input
                  type="file"
                  accept="*"
                  onChange={(e) => handleSelectionOfCertificate(e, i)}
                  style={{ display: "none" }}
                  id={`fileInput${i}`}
                />
                <label
                  htmlFor={`fileInput${i}`}
                  className="basicProfile_CustomInput"
                >
                  {bachelorCertificate[i]
                    ? bachelorCertificate[i].name
                    : isMediumScreen
                    ? certificatePlaceHolder
                    : ""}
                  <FontAwesomeIcon
                    className="bachelorsCertificateIcon"
                    icon={faArrowUpFromBracket}
                  ></FontAwesomeIcon>
                </label>
              </div>
            </div>
          </div>

          <div className="col-md-2 tutorPortalCheckboxStyle d-none d-md-block">
            <MyCheckbox
              styles={checkboxStyle}
              label="Pursuing"
              checked={checkboxStates.includes(i)}
              onChange={(e) => handleCheckboxChange(e.target.checked, i)}
            ></MyCheckbox>
          </div>

          <div className="col-12 tutorPortalCheckboxStyle d-block d-md-none m-2">
            <div className="row">
              <div className="col-3">
                <label className="content-lbl d-block d-md-none fw-bold">
                  Pursuing
                </label>
              </div>
              <div className="col-9">
                {checkboxStates.includes(i) && (
                  <label className="content-lbl d-block d-md-none fw-bold">
                    End Date
                  </label>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div
                  style={{
                    borderRadius: "5px",
                    boxShadow:
                      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                    border: "none",
                    height: "50px",
                    marginTop: "10px",
                  }}
                >
                  <MyCheckbox
                    styles={checkboxStyle}
                    label=" "
                    checked={checkboxStates.includes(i)}
                    onChange={(e) => handleCheckboxChange(e.target.checked, i)}
                  ></MyCheckbox>
                </div>
              </div>
              <div className="col-9">
                {checkboxStates.includes(i) && (
                  <TextField
                    className="basicProfile-BirthDate"
                    type="date"
                    label={isMediumScreen ? "End Date" : ""}
                    size="medium"
                    sx={{ marginTop: "10px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => onEndDateChange(e, i)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-md-3 d-none d-md-block">
            {checkboxStates.includes(i) && (
              <label className="content-lbl d-block d-md-none mt-2 fw-bold">
                End Date
              </label>
            )}
            {checkboxStates.includes(i) && (
              <TextField
                className="basicProfile-BirthDate"
                type="date"
                label={isMediumScreen ? "End Date" : ""}
                size="medium"
                sx={{ marginTop: "10px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => onEndDateChange(e, i)}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  const onOkayButtonClick = () => {
    window.location.href = "/";
  };

  const showTutorEnrolledPopup = () => {
    $("#TutorEnrollment").modal("show");
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center">
        <img src="/assest/images/TutorEnrolled.png" className="m-2 mb-5"></img>
        <h3>Thank you for submitting the application !</h3>
        <p style={{ fontSize: "18px" }}>
          Your interest in joining our team is greatly appreciated. Our team
          will carefully review your application, and we will be in touch
          shortly with the next steps in the hiring process.
        </p>
        <button
          className="CTA-btn mb-4"
          style={{
            fontWeight: "bold",
            color: "black",
            backgroundColor: "#FFD000",
            borderColor: "#FFD000",
            width: "200px",
            height: "50px",
            borderRadius: "10px",
          }}
          onClick={() => onOkayButtonClick()}
        >
          Okay
        </button>
      </div>
    );
  };

  return (
    <>
      <Header active="BecomeATutor"></Header>
      <img
        className="d-none d-md-block"
        style={{ width: "100%" }}
        src="../TutorEnrollmentBannerForWeb.png"
      ></img>
      <img
        className="d-block d-md-none"
        style={{ width: "100%" }}
        src="../TutorEnrollmentBannerForMobile.png"
      ></img>

      <div className="header-lbl TutorEnrollmentHeader">
        <label>Ready to Enroll?</label>
        <br />
        <label>Complete your profile now!</label>
      </div>

      {/* Basic Profile */}
      <div className="m-md-5 m-3 mt-md-4 mt-4 mb-md-0 mb-0">
        <div className="d-none d-md-block">
          <label className="header-lbl" style={{ fontWeight: "bold" }}>
            Basic Profile
          </label>
        </div>
        <div className="row mt-3 mb-2">
          <div className=" col-md-6 col-12">
            <label className="content-lbl d-block d-md-none fw-bold">
              First Name
            </label>
            <MyTextField
              id="Firstname"
              styles={textFieldStyles}
              placeholder={isMediumScreen ? "First Name" : ""}
              onChange={handleChange}
            ></MyTextField>
          </div>
          <div className="col-md-6 col-12">
            <label className="content-lbl d-block d-md-none mt-2 fw-bold">
              Last Name
            </label>
            <MyTextField
              id="Lastname"
              styles={textFieldStyles}
              placeholder={isMediumScreen ? "Last Name" : ""}
              onChange={handleChange}
            ></MyTextField>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-6 col-12">
            <label className="content-lbl d-block d-md-none mt-2 fw-bold">
              Country
            </label>
            <MyDropDown
              id="CountryData"
              styles={dropdownStyles}
              placeholder={isMediumScreen ? "Country" : " "}
              SelectedKey={props.CountryData.CountryID}
              options={CountryNameList}
              onChange={onChangeProps}
            ></MyDropDown>
          </div>
          <div className="col-md-6 col-12">
            <label className="content-lbl d-block d-md-none mt-2 fw-bold">
              Birthdate
            </label>
            <div className="tutorBirthdate">
              <TextField
                className="basicProfile-BirthDate"
                type="date"
                label={isMediumScreen ? "Birthday" : ""}
                size="medium"
                sx={{ marginTop: "10px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={onBirthdayChange}
              />
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="col-md-3 d-none d-md-block">
                <MyTextField
                  styles={textFieldStyles}
                  placeholder={isMediumScreen ? "Country Code" : ""}
                  value={
                    props.CountryData.CountryDialingCode +
                    "(" +
                    props.CountryData.CountryCode +
                    ")"
                  }
                  disabled={true}
                ></MyTextField>
              </div>
              <div className="col-md-9 col-12">
                <label className="content-lbl d-block d-md-none mt-2 fw-bold">
                  Mobile Number
                </label>
                <MyTextField
                  id="MobileNo"
                  styles={textFieldStyles}
                  placeholder={isMediumScreen ? "Mobile No." : ""}
                  onChange={onMobileNoChange}
                ></MyTextField>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <label className="content-lbl d-block d-md-none mt-2 fw-bold">
              Email ID
            </label>
            <MyTextField
              id="EmailID"
              styles={textFieldStyles}
              placeholder={isMediumScreen ? "Email ID" : ""}
              onChange={handleChange}
            ></MyTextField>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-6 col-12">
            <label className="content-lbl d-block d-md-none mt-2 fw-bold">
              Upload Resume
            </label>
            <input
              type="file"
              accept="*"
              onChange={handleSelectionOfResume}
              style={{ display: "none" }}
              id="fileInput"
            />
            <label htmlFor="fileInput" className="basicProfile_CustomInput">
              {resume ? resume.name : isMediumScreen ? "Upload Resume" : ""}
              <FontAwesomeIcon
                className="uploadResumeIcon"
                icon={faArrowUpFromBracket}
              ></FontAwesomeIcon>
            </label>
          </div>
          <div className="col-md-6 col-12 myComboBox">
            <label className="content-lbl d-block d-md-none mt-2 fw-bold">
              Subject Preference
            </label>
            <MyComboBox
              styles={comboBoxStyles}
              placeholder={isMediumScreen ? "Subject Preference" : " "}
              options={SubjectList}
              onChange={onSelectedSubjectChange}
            ></MyComboBox>
          </div>
        </div>
      </div>

      {/* Expected Remuneration */}
      <div className="m-md-5 m-3 mt-md-4 mt-4 mb-md-0 mb-0">
        <label className="header-lbl" style={{ fontWeight: "bold" }}>
          Expected Remuneration
        </label>
        <br />
        <div className="row">
          <div className="col-md-12 col-12 mt-3">
            <label className="content-lbl">
              {"Please specify your desired hourly compensation.While you have the flexibility to state your preferred amount,we recommend indicating a range between " +
                props.CountryData.CurrencyCode +
                " per hour. Its important to note that Learn From Native will deduct a 20% service fee from the amount you quote."}{" "}
            </label>
            <br />
            <div className="row mt-2 mb-2">
              <div className="col-md-1 col-2">
                <label
                  className="content-lbl"
                  style={{ fontWeight: "bold", marginTop: "20px" }}
                >
                  {props.CountryData.CurrencyCode}
                </label>
              </div>
              <div className="col-md-2 col-4">
                <MyTextField
                  id="PerHourCharge"
                  styles={textFieldStyles}
                  placeholder={isMediumScreen ? "Pay/hour" : ""}
                  onChange={onPerHourChargeChange}
                ></MyTextField>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Certificate Upload */}
      <div className="m-md-5 m-3 mt-md-4 mt-4 mb-md-0 mb-0">
        <label
          className="header-lbl"
          style={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          Upload your certificates
        </label>
        <br />
        <label className="content-lbl" style={{ marginBottom: "20px" }}>
          You can easily upload your foundational qualification and any other
          pertinent certificates related to your desired subject, such as
          TESOL/TEFL/CELTA for English, by following a few straightforward
          steps. Please ensure that the certificates are provided in either PDF
          or JPEG format.
        </label>
        <br />
        {addCertificateControl}
      </div>

      <div className="m-md-5 m-3 mt-md-2 mt-2 mb-md-0 mb-0">
        <div className="row">
          <div className="col-md-5 col-12">
            <div className="row">
              <div className="col-md-1 d-none d-md-block"></div>
              <div className="col-md-11 col-12">
                <label
                  className="content-lbl"
                  style={{ color: "#4E8B54", fontWeight: "bold" }}
                  onClick={onAddAnotherCertificateClick}
                >
                  Add another certificate
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center m-3">
        <PrimaryButton
          className="CTA-btn"
          style={{
            backgroundColor: "#FFBC10",
            width: "200px",
            borderRadius: "10px",
            border: "none",
          }}
          onClick={() => onSubmitButton()}
        >
          Submit
        </PrimaryButton>
      </div>

      <Dailogue
        header="false"
        id={"TutorEnrollment"}
        body={getDailogueBody}
        title=""
      />
    </>
  );
}
export default TutorEnrollment;

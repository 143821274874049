import { useEffect, useState } from 'react';
import { Execute } from '../Services/APIService';


var result;
function generateOTP() {
    var min = 100000;
    var max = 999999;
    result = Math.floor(min + Math.random() * (max - min));
    return result;
}

var sessionData = localStorage.getItem("items");
var sessionDetails = JSON.parse(sessionData)

if (sessionDetails != null || sessionDetails != undefined)
{
    var userID = sessionDetails.UserID
    var sessionID = sessionDetails.SessionID
}

const Timer = (props: any )=>
{
    if(props.result)
    {
        props.result(result)
    }
   
    const [counter, setCounter] = useState(props.maxRange);
    
    useEffect(() => {
        if (counter > 0) 
        {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        else {
            setCounter("Resend OTP")
        }
    }, [counter]);

    const OnSecondLabel = () => {
        if (counter > 0) {
            return <label> &nbsp;Sec</label>
        }
        else {
            return null;
        }
    }
    var EmailID = props.EmailID;

    const getOTP = () =>
    {
        if (counter > 0)
        {
            return null
        }
        else {

            generateOTP()
            var sSMSParameter =
            {
                Description: "ForSignIn",
                OTP: result
            }

            var sTemplateName = "Code No Share";
            var SMSMobileNo = props.MobileNo;
            var SMSParameterJSON = JSON.stringify(sSMSParameter);

            const oInsertSMSSentLog =
            {
                ActionName: "Core.GUtl_InsertSMSSentLog",
                ParameterJSON: "{\"TemplateName \":\"" + sTemplateName + "\",\"SMSMobileNo \":\"" + SMSMobileNo + "\",\"SMSParameterJSON  \":" + SMSParameterJSON + ",\"RowsAffecting\":\"1\"}",
                SessionDataJSON: "{\"UserID\":\"" + userID + "\",\"SessionID\":\"" + sessionID + "\",\"OnBehalfOfUserID\":\"0\",\"CompanyID\":\"100\",\"SubscriberID\":\"10000\",\"AppID\":\"learner.learnfromnative.com\",\"AppVersion\":\"1.0\",\"AppPlatform\":\"Web\",\"IsDeveloper\":\"0\",\"ServiceAccessToken\":\"BBADCACCBCCJDC - BBADCACCBCCJDC\"}",
                OperationName: "Command"
            };
            
            Execute(oInsertSMSSentLog).then(response => {
                if (!response.DataIsLoaded)
                    return <div><h1> Pleses wait some time....</h1> </div>;
                else {
                    if (response.message === 'Successfull')
                    {
                        setCounter(props.maxRange)                      
                    }
                }
            })
        }

    }
   
    return (<div style={{fontSize:"20px"}}>
        Didn't receive OTP? &nbsp;
        <u>
            <a onClick={getOTP}>{counter}</a>
            {OnSecondLabel()}
        </u>
        
    </div>)
}

export default Timer;
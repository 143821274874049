import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface IAppData {
  loginOptions: [];
}

const initialState: IAppData = {
  loginOptions: []
};

export const loginOptionsSlice = createSlice({
  name: "loginOptions",
  initialState,
  reducers: {
    setLoginOptions: (state, action) => 
    {
      
      

      return {
        loginOptions: action.payload.payload.loginOptions
      };
    },
  },
});

export const selectloginOptions = (state: RootState) =>
  state;

export const { setLoginOptions } = loginOptionsSlice.actions;
export default loginOptionsSlice.reducer;
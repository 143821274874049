function SocialMedia() {
  return (
    <div className="text-center findUsOn">
      <h4>Find Us On!</h4>
      <div className="row justify-content-center m-3">
        <div className="col-3 col-md-1 d-flex justify-content-center">
          <a
            className="btn text-white btn-floating m-1"
            target="_blank"
            href="https://www.instagram.com/learnfromnative/?igshid=YmMyMTA2M2Y%3D"
            role="button"
          >
            <img src="/assest/images/icons/instagram.png" alt="lfn-instagram" />
          </a>
        </div>
        <div className="col-3 col-md-1 d-flex justify-content-center">
          <a
            className="btn text-white btn-floating m-1"
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100070945157671&mibextid=LQQJ4d"
            role="button"
          >
            <img src="/assest/images/icons/facebook.png" alt="lfn-facebbok" />
          </a>
        </div>
        <div className="col-3 col-md-1 d-flex justify-content-center">
          <a
            className="btn text-white btn-floating m-1"
            target="_blank"
            href="https://youtube.com/@learnfromnative7575"
            role="button"
          >
            <img src="/assest/images/icons/youtube.png" alt="lfn-youtube" />
          </a>
        </div>
        <div className="col-3 col-md-1 d-flex justify-content-center">
          <a
            className="btn text-white btn-floating m-1"
            target="_blank"
            href="https://www.linkedin.com/company/learn-from-natives/"
            role="button"
          >
            <img src="/assest/images/icons/linkedin.png" alt="lfn-linkedin" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default SocialMedia;

import {
  IRatingStyleProps,
  IRatingStyles,
  IStyleFunctionOrObject,
  Rating,
  RatingSize,
} from "@fluentui/react";
import { Execute } from "../Services/APIService";
import { useEffect, useState } from "react";
import { colorCode } from "../utils/colors";
import OneOnOnePlanPricingTutorList from "../component/OneOnOnePlanPricingTutorList";
import Header from "../component/Header";
import { useLocation, useNavigate } from "react-router-dom";

function PlanPricingTutorList() {
  const location = useLocation();
  const selectedSubjectName = location.state.tutorSelectedSubjectName;

  return (
    <>
      <Header />
      <div className="m-md-5">
        <OneOnOnePlanPricingTutorList
          ShowAllTutorList
          selectedSubjectName={selectedSubjectName}
        />
      </div>
    </>
  );
}

export default PlanPricingTutorList;

function FrequentlyAskQuestion() {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  var faq = [];

  if (countryName == "India") {
    faq = [
      {
        id: "1",
        question: "How does Learn From Native Work?",
        answer:
          "Learn From Native is a unique platform, where you can explore a wide selection of AI-powered comprehensive English Language Courses that are both captivating and highly efficient. These tailored programs cater to learners of all ages, starting from 4 years old, and are accessible to individuals worldwide, regardless of their location.",
      },
      {
        id: "2",
        question: "Are the courses Free or Paid?",
        answer:
          "We provide learners with two options: a) Free courses, and b) Subscription-based courses. You have the flexibility to either sign up for a subscription plan or start with the free version, which can be upgraded at any time to unlock additional features and content. ",
      },
      {
        id: "3",
        question: "How is the curriculum personalized to my needs?",
        answer:
          "Our AI-powered system analyzes your language proficiency, learning style, and goals to create a customized curriculum. This ensures that the course materials and activities align with your specific needs, helping you make progress more efficiently. ",
      },
      {
        id: "4",
        question: "Are the tutors qualified and experienced?",
        answer:
          "Yes, our tutors are highly qualified and experienced in teaching English as a second language. They possess the necessary expertise and certifications like TESOL & TEFL to guide you through your English language-learning journey, provide constructive feedback, and address any challenges you may encounter. ",
      },
      {
        id: "5",
        question: "What is the class format?",
        answer:
          "We offer a variety of class formats, including one-to-one sessions, small group classes, and interactive online modules. You can choose the format that suits your preferences and schedule. ",
      },
      {
        id: "6",
        question: "Can I track my progress throughout the course?",
        answer:
          "Yes, we provide tools to track your progress, including regular assessments, performance evaluations, and progress reports. These allow you to monitor your improvement and identify areas that require further attention.",
      },
      {
        id: "7",
        question:
          "Is there any way that I can find out what Course suits me based on my current proficiency level?",
        answer:
          "Our Linguistic AI Assistant would help you identify the course that suits you. It would be at your service to answer any questions that you have in order to decide which course suits you the best. ",
      },
      {
        id: "8",
        question: "Is there ongoing support available?",
        answer:
          "Absolutely! We are committed to supporting your language learning journey. You can reach out to our dedicated support team for any questions, concerns, or additional guidance you may need during the course. ",
      },
      {
        id: "9",
        question: "How interactive are the classes?",
        answer:
          "Our classes are designed to be interactive and engaging. You will actively participate in discussions, role-plays, language exercises, and real-life simulations to practice and apply your English skills in practical situations. ",
      },
      {
        id: "10",
        question: "How do I enrol in the course?",
        answer:
          "Please visit the plans and pricing section of our website and book the classes in the subject that you need, with the teacher of your choice. Once you book the classes, our representative with reach out to you in a shortly to guide you through the entire process smoothly. ",
      },
    ];
    var faq1 = faq.slice(0, Math.ceil(faq.length / 2));
    var faq2 = faq.slice(Math.ceil(faq.length / 2));
  } else {
    faq = [
      {
        id: "1",
        question: "How does Learn From Native work?",
        answer:
          "Learn From Native is a unique platform, that works on the principle of High Impact Tutoring, where you can explore a wide selection of AI-powered comprehensive courses for various subjects. Our courses are both captivating and highly efficient. These tailored programs cater to learners of all ages, starting from 4 years old, and are accessible to individuals worldwide, regardless of their location. It starts with a Free Demo Class, that would assess the learner’s base proficiency level for a given subject.",
      },
      {
        id: "2",
        question: "How is the curriculum personalized to my needs?",
        answer:
          "Based on the outcome of the Free demo class and feedback from our expert tutor, our AI-powered system analyzes your subject proficiency, learning style, and goals to create a customized curriculum. This ensures that the course materials and activities align with your specific needs, helping you make progress more efficiently. ",
      },
      {
        id: "3",
        question: "Are the Tutors Qualified?",
        answer:
          "Yes, our tutors are highly qualified and experienced in teaching the subjects of their expertise. They possess the necessary certifications to guide you through your educational journey, provide constructive feedback, and address any challenges you may encounter.",
      },
      {
        id: "4",
        question: "What is the Class Format?",
        answer:
          "We offer a live, virtual, one-to-one sessions with highly interactive teaching-learning methodologies. The learner has a complete flexibility to schedule the classes as per his / her convenience.",
      },
      {
        id: "5",
        question: "Is there ongoing support available? ",
        answer:
          "Absolutely! We are committed to supporting your educational journey. You can reach out to our dedicated support team for any questions, concerns, or additional guidance you may need during the course.",
      },
      {
        id: "6",
        question: "How interactive are the classes? ",
        answer:
          "Our classes are designed to be interactive and engaging. You will actively participate in discussions, role-plays, assignments, exercises, quizzes and real-life simulations to practice and apply your subject skills in practical situations. ",
      },
      {
        id: "7",
        question: "How do I enrol in the course? ",
        answer:
          "Please visit the plans and pricing section of our website and book the classes in the subject that you need, with the teacher of your choice. Once you book the classes, our representative with reach out to you in a shortly to guide you through the entire process smoothly. ",
      },
    ];
    var faq1 = faq.slice(0, Math.ceil(faq.length / 2));
    var faq2 = faq.slice(Math.ceil(faq.length / 2));
  }

  $(document).ready(function () {
    // Initially hide all accordion items beyond the first two
    $("#accordionExample1 .accordion-item:gt(1)").hide();
    $("#accordionExample2 .accordion-item:gt(1)").hide();

    // Show all accordion items when "View all" is clicked
    $("#viewAllBtn").click(function () {
      $("#accordionExample1 .accordion-item:hidden").show();
      $("#accordionExample2 .accordion-item:hidden").show();
      $(this).hide(); // Hide the "View all" button after showing all items
    });
  });

  const FAQ = ({ faqData, id }) => {
    return (
      <div>
        <div className="accordion" id={id}>
          {faqData.map((obj, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${obj.id}`}>
                <button
                  className={`accordion-button ${
                    index === 0 ? "show" : "collapsed"
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${obj.id}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index}`}
                >
                  {obj.question}
                </button>
              </h2>
              <div
                id={`collapse${obj.id}`}
                className={`accordion-collapse collapse ${
                  index === 0 ? "show" : ""
                }`}
                aria-labelledby={`heading${obj.id}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>{obj.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <div className="faq">
          <div className="row">
            <div className="col-md-12">
              <h1 className="heading">FAQ</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-container m-4 m-md-5">
        <div className="row">
          <div className="col-md-6">
            <FAQ faqData={faq1} id="accordionExample1" />
          </div>
          <div className="col-md-6">
            <FAQ faqData={faq2} id="accordionExample2" />
          </div>
          <a className="hyperLinkText text-center" id="viewAllBtn">
            View all
          </a>
        </div>
      </div>
    </>
  );
}

export default FrequentlyAskQuestion;

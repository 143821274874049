function Installment(props)
{

  const handleClick = (selectedInstallmentJson) => {
    props.onChildClick(selectedInstallmentJson);
  };
  
  
  
  return(
        <div className="modal fade" id="installmentModalCenter"  role="dialog" aria-labelledby="installmentModuleCenterTitle" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 pt-0 pb-0">
                <h5 className="modal-title" id="exampleModalLongTitle">Installment</h5>
                <button type="button" className="close bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {
                  props.installmentJSON.map(obj=>
                  {
                    var installmentMonths=obj.InstallmentMonths
                    var installmentValueIncrementPercent=obj.InstallmentValueIncrementPercent
                    //var finalCostForIndividualPlan=totalCost-discount-text.DiscountCost //for AdminPortal Additional Discount (text.DiscountCost)

                    var finalCostForIndividualPlan=props.totalCost-props.discount-0 //Add (text.DiscountCost)
                    var installmentID=obj.InstallmentID

                    var finalCostForIndividualPlanWithInstallment  = finalCostForIndividualPlan + (finalCostForIndividualPlan * (installmentValueIncrementPercent / 100.00));
                    var installmentPerMonth = Math.floor((finalCostForIndividualPlanWithInstallment / installmentMonths) + 0.50);
                  
                    var finalCostWithoutInstallmentPerMonth =  finalCostForIndividualPlan;
                    var finalCostWithInstallmentPerMonth    =  (installmentPerMonth * installmentMonths);
                  
                    var  dConvinceFee = (finalCostWithInstallmentPerMonth - finalCostWithoutInstallmentPerMonth) / installmentMonths;
                    dConvinceFee=Math.round(dConvinceFee*100)/100

                    var selectedInstallmentJson={
                      installmentMonths:installmentMonths,
                      installmentPerMonth:installmentPerMonth,
                      finalCostWithoutInstallmentPerMonth:finalCostWithoutInstallmentPerMonth,
                      installmentID:installmentID

                    }

                    return<>
                    <div className="row shadow-sm p-3 bg-white rounded" onClick={()=>handleClick(selectedInstallmentJson)}>
                      <div className="col-md-12">
                        <p>Installment Months : {installmentMonths}</p>
                        <p>Installment amount : {installmentPerMonth}</p>
                        <div>
                        { 
                          (() => {
                            if(installmentValueIncrementPercent>0) 
                            {
                              return (
                                <p className='popupContent-lbl'>Including {dConvinceFee} convience fee</p>
                              );
                            }
                          
                          })()
                        }     
                        </div>
                        <p>Final Cost : {finalCostWithoutInstallmentPerMonth}</p>
                      </div>
                    </div>
                    </>
                  })
    
                }
              </div>
            </div>
          </div>
        </div>



    )
}

export default Installment
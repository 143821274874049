import { ComboBox, IComboBoxStyles } from '@fluentui/react';

function MyComboBox(props)
{
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };

    return (
     
        <div>
            <ComboBox
                defaultSelectedKey={props.defaultSelectedKey}
                selectedKey={props.selectedKey}
                onChange={props.onChange}
                multiSelect
                options={props.options}
                styles={props.styles?props.styles:comboBoxStyles}
                style={props.style}
                placeholder={props.placeholder}
            />
        </div>
    );
}
export default MyComboBox;
import React from 'react'
import {IDropdownStyles, IDropdownOption, Dropdown, Label, Callout, DirectionalHint, ResponsiveMode} from '@fluentui/react';
import { height } from '@mui/system';

function MyDropDown(props) 
{
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300, margin: "auto"},
        title : { fontSize:"20px" },
        dropdownOptionText:
        {
            fontSize:"20px",height:"40px",paddingTop:"10px",
            
        },
    };



    var hasValue=props.style
    return (
        <div>
            <Label>{props.CaptionText}</Label>
                <Dropdown
                    id={props.id}
                    placeholder={props.placeholder ? props.placeholder : "Select an option"}
                    options={props.options}
                    styles={props.styles ? props.styles : dropdownStyles}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    selectedKey={props.SelectedKey}
                    defaultSelectedKey={props.defaultSelectedKey}
                    responsiveMode={ResponsiveMode.large}
                />
        </div>
    );
}
export default MyDropDown;
function AIPOC()
{
    return(
        <div style={{ width: '100%', height: '100vh' }}>
        <iframe
          src="https://buildai.space/app/learn-from-native-ai-language-learning-plan" // Replace with your iframe URL
          title="description"
          style={{ width: '100%', height: '100%', border: 'none' }}
        ></iframe>
      </div>
    )
}
export default AIPOC
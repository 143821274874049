import { useEffect, useState } from "react";
import Header from "../component/Header";
import Login from "../component/Login";
import { useLocation, useNavigate } from "react-router-dom";
import RazorPay from "../Services/RazorPay";
import { Execute } from "../Services/APIService";
import ClassBooking from "../component/ClassBooking";
import { colorCode } from "../utils/colors";
import Dailogue from "../Controls/Modal";

function FreeBillingInvoice() {
  const [loginMode, setLoginMode] = useState("LoginWithOTP");

  const [classBookingDialogProp, setClassBookingDialogProp] = useState({
    ClassID: "",
    ClassName: "",
    PurchaseClassID: "",
    IsShow: false,
  });
  const openClassBookingDialog = (id) => {
    setClassBookingDialogProp({
      ClassID: id.ClassID,
      ClassName: id.ClassName,
      PurchaseClassID: id.PurchaseClassID,
      IsShow: true,
    });
  };
  const closeClassBookingDialog = () =>
    setClassBookingDialogProp({
      ClassID: "",
      ClassName: "",
      PurchaseClassID: "",
      IsShow: false,
    });

  const location = useLocation();
  const tutorProfileDetails = location.state.tutorProfileDetails;
  var finalcost = tutorProfileDetails.PlanDemoPricing;

  const userImgPath =
    "https://liveapi.learnfromnative.com/documents/User Photos/";
  const supportPhoneNumber = "+918058058089";
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const callOnSupportPhoneNo = () => {
    if (screenWidth < 768) {
      const telLink = `tel:${supportPhoneNumber}`;
      window.location.href = telLink;
    }
  };

  const sendMail = () => {
    const mailtoUrl = `mailto:${"connect@learnfromnative.com"}?subject=${encodeURIComponent(
      "Query About Class Booking"
    )}`;
    window.location.href = mailtoUrl;
  };

  const onOkayButtonClick = () => {
    if (webAppName === "LearnerPortal") {
      window.location.href = "/PlanPricing";
    } else {
      window.location.href = "/#planPricingSection";
    }
  };

  var webAppName = "WebsitePortal";
  var userID;
  var sessionID;
  var teachingLanguageID;
  var Latitude = null;
  var Longitude = null;
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryID = sessionDetails.CountryID;
    var countryName = sessionDetails.CountryName;
    if (webAppName === "LearnerPortal") {
      sessionID = sessionDetails.SessionID;
      teachingLanguageID = sessionDetails.TeachingLanguageID;
    } else {
      sessionID = 3;
      teachingLanguageID = 1;
    }
  }

  const navigate = useNavigate();
  const validateUser = () => {
    return new Promise<void>((resolve) => {
      var sessionData = localStorage.getItem("items");
      var sessionDetails = JSON.parse(sessionData);
      if (sessionDetails != null || sessionDetails != undefined) {
        if (finalcost > 0) {
          userID = sessionDetails.UserID;
        } else {
          var oGuestUserDetails = sessionDetails.GuestUserDetails;
          if (oGuestUserDetails != undefined) {
            userID = oGuestUserDetails.GuestUserID;
          }
        }
        if (userID == null || userID == "" || userID == undefined) {
          $("#loginModalCenter").modal("show");
          return;
        }
      } else {
        $("#loginModalCenter").modal("show");
        return;
      }
      resolve();
    });
  };
  const [freeDemoClassSlot, setFreeDemoClassSlot] = useState([]);

  useEffect(() => {
    if (finalcost > 0) {
      setLoginMode("LoginWithOTP");
    } else {
      setLoginMode("LoginWithoutOTP");
    }

    validateUser();
  }, []);

  const [installmentDetailsProps, setInstallmentDetailsProps] = useState({
    installmentMonths: "",
    installmentPerMonth: 0,
    finalCostWithoutInstallmentPerMonth: 0,
    installmentID: 0,
  });
  const onBookNow = (item) => {
    var noOfClasses = 1;
    var classDuration = tutorProfileDetails.PlanPerClassMinutes;
    var utilizeCoinsJson = [];
    var planPricingType = "Demo Class";
    var classTeachingID = tutorProfileDetails.TeacherID;
    validateUser().then(() => {
      if (finalcost > 0) {
        if (countryName === "India") {
          RazorPay(
            item,
            finalcost,
            noOfClasses,
            classDuration,
            installmentDetailsProps,
            utilizeCoinsJson,
            countryID,
            planPricingType
          );
        } else {
          var paymentDetailsString = "";
          var paymentDetailsParseData = [];

          var purchaseClassCount = noOfClasses === 0 ? "" : noOfClasses;
          var purchaseClassMinutes = classDuration === 0 ? "" : classDuration;

          var utilizeCoins = JSON.stringify(JSON.stringify(utilizeCoinsJson));

          //Remove this Logic
          if (item.PlanName != "One-on-One") {
            purchaseClassCount = "";
            purchaseClassMinutes = "";
          } else {
            if (noOfClasses === 0) {
              purchaseClassCount = "";
            } else if (classDuration == 0) {
              purchaseClassMinutes = "";
            }
          }

          var oInsertPurchaseAndMastersNew;

          var paymentMethod = "Stripe";
          var installmentID =
            installmentDetailsProps.installmentID > 0
              ? installmentDetailsProps.installmentID
              : null;
          var teacherID = item.TeacherID === undefined ? "" : item.TeacherID;

          if (installmentID === null) {
            oInsertPurchaseAndMastersNew = {
              ActionName: "LFN.GTra_InsertPurchaseAndMastersNew",
              ParameterJSON:
                '{"CountryID":"' +
                countryID +
                '","PlanID":"' +
                item.PlanID +
                '","Currency":"' +
                item.PlanPricingCurrency +
                '","TeacherID":"' +
                teacherID +
                '","PurchaseClassCount":"' +
                purchaseClassCount +
                '","PurchaseClassMinutes":"' +
                purchaseClassMinutes +
                '","FinalCost":"' +
                finalcost +
                '","TeachingLanguageID":"' +
                teachingLanguageID +
                '","UtilizeCoinsJson":' +
                utilizeCoins +
                ',"InstallmentAmount":"' +
                installmentDetailsProps.installmentPerMonth +
                '","PlanPricingType":"' +
                planPricingType +
                '","PaymentMethod":"' +
                paymentMethod +
                '","PlanPricingID":"' +
                item.PlanPricingID +
                '","RowsAffecting":"1"}',
              SessionDataJSON:
                '{"UserID":"' +
                userID +
                '","SessionID":"' +
                sessionID +
                '","OnBehalfOfUserID":"' +
                userID +
                '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
              OperationName: "Command",
            };
          } else {
            oInsertPurchaseAndMastersNew = {
              ActionName: "LFN.GTra_InsertPurchaseAndMastersNew",
              ParameterJSON:
                '{"CountryID":"' +
                countryID +
                '","PlanID":"' +
                item.PlanID +
                '","Currency":"' +
                item.PlanPricingCurrency +
                '","TeacherID":"' +
                teacherID +
                '","PurchaseClassCount":"' +
                purchaseClassCount +
                '","PurchaseClassMinutes":"' +
                purchaseClassMinutes +
                '","FinalCost":"' +
                finalcost +
                '","TeachingLanguageID":"' +
                teachingLanguageID +
                '","UtilizeCoinsJson":' +
                utilizeCoins +
                ',"InstallmentID":"' +
                installmentID +
                '","InstallmentAmount":"' +
                installmentDetailsProps.installmentPerMonth +
                '","PlanPricingType":"' +
                planPricingType +
                '","PaymentMethod":"' +
                paymentMethod +
                '","PlanPricingID":"' +
                item.PlanPricingID +
                '","RowsAffecting":"1"}',
              SessionDataJSON:
                '{"UserID":"' +
                userID +
                '","SessionID":"' +
                sessionID +
                '","OnBehalfOfUserID":"' +
                userID +
                '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
              OperationName: "Command",
            };
          }

          Execute(oInsertPurchaseAndMastersNew).then((response) => {
            if (!response.DataIsLoaded) {
              return (
                <div>
                  <h1>Please wait some time...</h1>
                </div>
              );
            } else {
              if (response.message === "Successfull") {
                paymentDetailsString = JSON.stringify(response.items);
                paymentDetailsParseData = JSON.parse(
                  JSON.parse(paymentDetailsString)
                );
                navigate("/Stripe", {
                  state: {
                    paymentDetailsParseData: paymentDetailsParseData,
                    item: item,
                    utilizeCoinsJson: utilizeCoinsJson,
                  },
                });
              }
            }
          });
        }
      } else {
        if (webAppName === "LearnerPortal") {
          InsertPurchaseAndMastersForFreeDemo();
        } else {
          InsertGuestUser(); //This will never execute as InsertGuestUser already added on Book As A Guest CTA
        }
      }
    });
  };

  const InsertPurchaseAndMastersForFreeDemo = () => {
    const oInsertPurchaseAndMastersForFreeDemoNew = {
      ActionName: "LFN.GTra_InsertPurchaseAndMastersForFreeDemoNew",
      ParameterJSON:
        '{"RowsAffecting":"4" ,"PlanPricingID":"' +
        tutorProfileDetails.PlanPricingID +
        '"}',
      SessionDataJSON:
        '{"UserID":"' +
        userID +
        '","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"' +
        userID +
        '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
    Execute(oInsertPurchaseAndMastersForFreeDemoNew).then((response) => {
      if (!response.DataIsLoaded) {
        return (
          <div>
            <h1>Please wait some time...</h1>
          </div>
        );
      } else {
        if (response.message === "Successfull") {
          $("#bookingConfirmationModalCenter").modal("show");
        } else {
          alert(response.message);
          onOkayButtonClick();
          return;
        }
      }
    });
  };

  const InsertGuestUser = () => {
    var MobileNo = sessionDetails.GuestUserDetails.GuestMobileNo;
    var CountryID = sessionDetails.GuestUserDetails.GuestCountryID;
    var TeacherID = tutorProfileDetails.PlanPricingTeacherID;

    const oInsertGuestUser = {
      ActionName: "LFN.GMst_InsertGuestUser",
      ParameterJSON:
        '{"MobileNo ":"' +
        MobileNo +
        '","CountryID ":"' +
        CountryID +
        '","Longitude":' +
        Longitude +
        ',"Latitude":' +
        Latitude +
        ',"TeacherID":' +
        TeacherID +
        ',"RowsAffecting":"1"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"' +
        sessionID +
        '","OnBehalfOfUserID":"0","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Command",
    };
    Execute(oInsertGuestUser).then((response) => {
      var guestUserDetails = JSON.parse(response.items);
      if (guestUserDetails) {
        var validationMessage = guestUserDetails[0].ValidationMessage;
        var guestUserID = guestUserDetails[0].GuestUserID;
      }

      const oGuestUserDetails = {
        GuestUserID: guestUserID,
        GuestMobileNo: MobileNo,
        GuestCountryID: CountryID,
      };

      const obj = {
        ...sessionDetails,
        GuestUserDetails: oGuestUserDetails,
      };
      localStorage.setItem("items", JSON.stringify({ ...obj }));

      if (response.message == "Successfull") {
        if (validationMessage != "" && validationMessage != undefined) {
          handleInsertGuestUserError(validationMessage);
        } else {
          handleInsertGuestUserSuccess();
        }
      } else {
        handleInsertGuestUserError(response.message);
      }
    });
  };

  const handleInsertGuestUserSuccess = () => {
    $("#bookingConfirmationModalCenter").modal("show");
    return;
  };

  const handleInsertGuestUserError = (message) => {
    $("#loginModalCenter").modal("hide");
    alert(message);
    onOkayButtonClick();
    return;
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center  bookingConfirmation p-2 pb-3 display">
        <p className="fw-bold mb-4 title">
          Thank you for Scheduling a Free Demo Class with Us!
        </p>
        <p className="fw-bold mb-5 content">
          Our Team will get back to you in next 6-12 Hours
        </p>
        <p className="fs-4 content mb-4">
          For any inquiries, reach out to us at{" "}
          <span onClick={() => sendMail()}>connect@learnfromnative.com</span> or
          call{" "}
          <span onClick={() => callOnSupportPhoneNo()}>
            {supportPhoneNumber}
          </span>
        </p>
        <button
          className="btn p-2  text-center justify-content-center w-100 fw-bold"
          onClick={() => onOkayButtonClick()}
        >
          Okay{" "}
        </button>
      </div>
    );
  };

  $("#bookingConfirmationModalCenter").on("hidden.bs.modal", function () {
    onOkayButtonClick();
  });

  return (
    <>
      {webAppName != "LearnerPortal" && <Header />}
      <div className="serviceTutorDetails billingInvoice m-4 m-md-5 ">
        <div className="row">
          <div className="col-md-4 serviceDetails mb-4">
            <h1 className="mb-2 tutorDetails d-none d-md-block">
              Tutor Details
            </h1>
            <div>
              <div className="tutorImage">
                <img
                  className="circle-image mt-2 mb-2 text-center"
                  src={`${userImgPath}${tutorProfileDetails.TeacherID}.png`}
                  style={{
                    borderRadius: "50%",
                    width: "180px",
                    height: "180px",
                    border: `10px solid ${
                      colorCode[Math.floor(Math.random() * colorCode.length)]
                    }`,
                  }}
                ></img>
              </div>

              <div className="row">
                <div className="col-5 col-md-5 tutorDetailsTitle">
                  <p className="mb-1">Tutor's Name</p>
                  <p className="mb-1">Experience</p>
                  <p className="mb-1">Rating </p>
                  <p className="mb-1">Nationality</p>
                  <p className="mb-1">Class Type</p>
                  <p className="mb-1">Class Duration</p>
                  <p className="mb-1">Subject</p>
                </div>
                <div className="col-7 col-md-7">
                  <p className="mb-1">- {tutorProfileDetails.TeacherName}</p>
                  <p className="mb-1">
                    - {tutorProfileDetails.Experience + "+ year's experience"}
                  </p>
                  <p className="mb-1 d-flex align-items-center">
                    - {tutorProfileDetails.TeacherRating}{" "}
                    <img
                      src="/core/star.png"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                      alt="Star"
                    />
                  </p>
                  <p className="mb-1">- {tutorProfileDetails.CountryName}</p>
                  <p className="mb-1">- {"Demo Class"}</p>
                  <p className="mb-1">
                    - {tutorProfileDetails.PlanPerClassMinutes}
                  </p>
                  <p className="mb-1">- {tutorProfileDetails.SubjectName}</p>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="col-md-4 offset-md-4 billingInvoice serviceBillingInvoice fixedContentToFooter">
            <hr className="d-block  d-md-none" />
            <h1 className="">Billing Invoice</h1>

            <div className="row">
              <div className="col-6">
                <p className="d-none d-md-block">Per Class Cost</p>
                <p className="d-block d-md-none tutorDetailsTitle">
                  Per Class Cost
                </p>

                <p className="d-none d-md-block">Tutor </p>
              </div>
              <div className="col-6">
                <p>
                  -{" "}
                  {(tutorProfileDetails.PlanPricingCurrency == "INR"
                    ? tutorProfileDetails.PlanPricingCurrency + " "
                    : "$") + tutorProfileDetails.PlanDemoPricing}
                </p>
                <p className="d-none d-md-block">
                  -
                  {tutorProfileDetails.CountryName == "India"
                    ? " Indian"
                    : " Native"}
                </p>
              </div>
            </div>
            <hr />
            <div className="row mb-2">
              <div className="col-6">
                <p>Total Cost </p>
              </div>
              <div className="col-6">
                <p>
                  -{" "}
                  {(tutorProfileDetails.PlanPricingCurrency == "INR"
                    ? tutorProfileDetails.PlanPricingCurrency + " "
                    : "$") + tutorProfileDetails.PlanDemoPricing}
                </p>
              </div>
            </div>

            <button
              className="btn btn-mayGreen btn-payNow p-2 d-none d-md-flex text-center justify-content-center w-100"
              onClick={() => onBookNow(tutorProfileDetails)}
            >
              {tutorProfileDetails.CardType == "Book Demo Class"
                ? tutorProfileDetails.PlanDemoPricing > 0
                  ? "Book a Trial Class"
                  : "Book a Free Demo Class"
                : "Pay Now"}{" "}
            </button>
          </div>
        </div>
      </div>
      <button
        className="btn btn-mayGreen btn-payNow w-100 d-block d-md-none p-2 fixed-bottom mb-0"
        onClick={() => onBookNow(tutorProfileDetails)}
      >
        {tutorProfileDetails.CardType == "Book Demo Class"
          ? tutorProfileDetails.PlanDemoPricing > 0
            ? "Book a Trial Class"
            : "Book a Free Demo Class"
          : "Pay Now"}{" "}
      </button>
      <Login
        LoginMode={loginMode}
        handleInsertGuestUserSuccess={handleInsertGuestUserSuccess}
        handleInsertGuestUserError={handleInsertGuestUserError}
        TeacherID={tutorProfileDetails.TeacherID}
      />
      <Dailogue
        id={"bookingConfirmationModalCenter"}
        body={getDailogueBody}
        title=""
        staticBackdrop
        isClose={false}
      />
      {classBookingDialogProp.IsShow == true && (
        <ClassBooking
          isOpen={classBookingDialogProp.IsShow}
          onDismiss={closeClassBookingDialog}
          ClassID={classBookingDialogProp.ClassID}
          ClassName={classBookingDialogProp.ClassName}
          PurchaseClassID={classBookingDialogProp.PurchaseClassID}
          onClose={closeClassBookingDialog}
        ></ClassBooking>
      )}
    </>
  );
}
export default FreeBillingInvoice;

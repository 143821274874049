import { useEffect, useState } from "react";

function DoMoreWithLFN() {
  const onHowLFNWorks = () => {
    window.location.href = "/Kids#howLearnFromNativeWorks";
  };

  const [activeTab, setActiveTab] = useState("ExpertTutors");
  const intervalDuration = 3000; // Change tab every 5 seconds
  const tabs = [
    "ExpertTutors",
    "FlexibleScheduling",
    "FreeLearningResources",
    "InteractiveClassroom",
  ];

  useEffect(() => {
    const content = document.querySelectorAll(".content");
    content.forEach((item: Element) => {
      if (item.id === activeTab) {
        (item as HTMLElement).style.display = "block";
      } else {
        (item as HTMLElement).style.display = "none";
      }
    });
  }, [activeTab]);

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  var doMoreWithLFNContent = "";
  if (countryName == "India") {
    doMoreWithLFNContent =
      "The English courses at Learn From Native are based on your learning path to ensure real value add to your English Journey. We follow a ‘Strategize-Implement- Assess’ outline to make sure you get the desired outcome taking English courses from us. ";
  } else {
    doMoreWithLFNContent =
      "The English, Math & Science courses at Learn From Native are based on your learning path to ensure real value add to your learning trip. We follow a ‘Strategize-Implement- Assess’ outline to make sure you get the desired outcome taking the courses from us. ";
  }
  useEffect(() => {
    let currentIndex = 0;

    const interval = setInterval(() => {
      const newActiveTab = tabs[currentIndex];

      setActiveTab(newActiveTab);
      currentIndex = (currentIndex + 1) % tabs.length;
    }, intervalDuration);

    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const handleClick = (target: any) => {
    setActiveTab(target);
  };

  return (
    <>
      <div className="doMoreWithLFN">
        <div className="doMoreWithLFN-content">
          <div className="row">
            <div className="col-md-12">
              <h1 className="heading text-100">Do more With LFN</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <p>{doMoreWithLFNContent}</p>
            </div>
          </div>
        </div>
        <div className="menu-items">
          <nav className="navbar navbar-expand mb-3">
            <ul className="navbar-nav w-100 d-flex justify-content-between flex-nowrap">
              <li
                className={
                  activeTab === "ExpertTutors" ? "nav-item active" : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  onClick={() => handleClick("ExpertTutors")}
                >
                  Expert Tutors
                </a>
              </li>
              <li
                className={
                  activeTab === "FlexibleScheduling"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  onClick={() => handleClick("FlexibleScheduling")}
                >
                  Flexible Scheduling
                </a>
              </li>
              <li
                className={
                  activeTab === "FreeLearningResources"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  onClick={() => handleClick("FreeLearningResources")}
                >
                  Free Learning Resources
                </a>
              </li>
              <li
                className={
                  activeTab === "InteractiveClassroom"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  className="nav-link"
                  onClick={() => handleClick("InteractiveClassroom")}
                >
                  Interactive Classroom
                </a>
              </li>
            </ul>
          </nav>
          <div className="">
            <div className="text-center">
              <div id="ExpertTutors" className="content">
                <div className="row" id="web-banner">
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/expertTutor-1.png"
                      alt="Expert Tutor 1"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/expertTutor-2.png"
                      alt="Expert Tutor 2"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/expertTutor-3.png"
                      alt="Expert Tutor 3"
                    ></img>
                  </div>
                </div>
                <div id="mob-banner" className="d-none">
                  <img
                    src="/assest/images/mobile/expertTutor-1.png"
                    alt="Mobile Expert Tutor 1"
                  ></img>
                  <img
                    src="/assest/images/mobile/expertTutor-2.png"
                    alt="Mobile Expert Tutor 2"
                  ></img>
                  <img
                    src="/assest/images/mobile/expertTutor-3.png"
                    alt="Mobile Expert Tutor 3"
                  ></img>
                </div>
              </div>
              <div id="FlexibleScheduling" className="content">
                <div className="row" id="web-banner">
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/flexibleScheduling-1.png"
                      alt="Flexible Scheduling 1"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/flexibleScheduling-2.png"
                      alt="Flexible Scheduling 2"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/flexibleScheduling-3.png"
                      alt="Flexible Scheduling 3"
                    ></img>
                  </div>
                </div>
                <div id="mob-banner" className="d-none">
                  <img
                    src="/assest/images/mobile/flexibleScheduling-1.png"
                    alt="Mobile Flexible Scheduling 1"
                  ></img>
                  <img
                    src="/assest/images/mobile/flexibleScheduling-2.png"
                    alt="Mobile Flexible Scheduling 2"
                  ></img>
                  <img
                    src="/assest/images/mobile/flexibleScheduling-3.png"
                    alt="Mobile Flexible Scheduling 3"
                  ></img>
                </div>
              </div>
              <div id="FreeLearningResources" className="content">
                <div className="row" id="web-banner">
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/freeLearningResources-1.png"
                      alt="Free Learning Resources 1"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/freeLearningResources-2.png"
                      alt="Free Learning Resources 2"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/freeLearningResources-3.png"
                      alt="Free Learning Resources 3"
                    ></img>
                  </div>
                </div>
                <div id="mob-banner" className="d-none">
                  <img
                    src="/assest/images/mobile/freeLearningResources-1.png"
                    alt="Mobile Free Learning Resources 1"
                  ></img>
                  <img
                    src="/assest/images/mobile/freeLearningResources-2.png"
                    alt="Mobile Free Learning Resources 2"
                  ></img>
                  <img
                    src="/assest/images/mobile/freeLearningResources-3.png"
                    alt="Mobile Free Learning Resources"
                  ></img>
                </div>
              </div>
              <div id="InteractiveClassroom" className="content">
                <div className="row" id="web-banner">
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/interactiveClassroom-1.png"
                      alt="Interactive Classroom 1"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/interactiveClassroom-2.png"
                      alt="Interactive Classroom 2"
                    ></img>
                  </div>
                  <div className="col-4 mb-4">
                    <img
                      className="img-responsive"
                      src="/assest/images/interactiveClassroom-3.png"
                      alt="Interactive Classroom 3"
                    ></img>
                  </div>
                </div>
                <div id="mob-banner" className="d-none">
                  <img
                    src="/assest/images/mobile/interactiveClassroom-1.png"
                    alt="Mobile Interactive Classroom 1"
                  ></img>
                  <img
                    src="/assest/images/mobile/interactiveClassroom-2.png"
                    alt="Mobile Interactive Classroom 2"
                  ></img>
                  <img
                    src="/assest/images/mobile/interactiveClassroom-3.png"
                    alt="Mobile Interactive Classroom 3"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <button className="btn-howLFNWorks m-2" onClick={onHowLFNWorks}>
                HOW LFN Works
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoMoreWithLFN;

import LoginPage from "./LoginPage";

function Login(props) {
  function handleModalClose() {
    if (props.handleClick) {
      props.handleClick();
    }
  }

  return (
    <div
      className="modal fade "
      id="loginModalCenter"
      role="dialog"
      aria-labelledby="loginModuleCenterTitle"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-dialog-centered custom-modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pt-0 pb-0">
            <h5 className="modal-title" id="exampleModalLongTitle"></h5>
            <button
              type="button"
              className="close bg-transparent border-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleModalClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            {
              <LoginPage
                LoginMode={props.LoginMode}
                InsertGuestUser={props.InsertGuestUser}
                handleInsertGuestUserSuccess={
                  props.handleInsertGuestUserSuccess
                }
                handleInsertGuestUserError={props.handleInsertGuestUserError}
                TeacherID={props.TeacherID}
              />
            }
          </div>
          {/* <div className="modal-footer border-0 justify-content-center">
                <p className="text-center">Already have and account yet?<br/>Please <a href="">Log In</a></p>
              </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;

import { useEffect, useState } from "react";

function WhatDoWeHaveToOffer()
{
    
    const [activeTab, setActiveTab] = useState('SpeakUpAndShine');
    // const intervalDuration = 3000; // Change tab every 5 seconds
    // const tabs = ['ExpertTutors', 'FlexibleScheduling', 'FreeLearningResources'];

    useEffect(() => 
    {
        const content = document.querySelectorAll('.content');
        content.forEach((item: Element) => 
        {
          if (item.id === activeTab) {
            (item as HTMLElement).style.display = 'block';
          } else {
            (item as HTMLElement).style.display = 'none';
          }
        });
    }, [activeTab]);
  
    const onBookAFreeDemo=()=>
    {
        window.location.href='/#planPricingSection'
    }

    // useEffect(() => 
    // {
    //     let currentIndex = 0;
    
    //     const interval = setInterval(() =>
    //     {
    //       const newActiveTab = tabs[currentIndex];
    
    //       setActiveTab(newActiveTab);
    //       currentIndex = (currentIndex + 1) % tabs.length;
    //     }, intervalDuration);
    
    //     return () => {
    //       clearInterval(interval);
    //     };
    // }, []); // Empty dependency array ensures the effect runs only once
    

      
    const handleClick = (target:any) => 
    {
        setActiveTab(target);
    }
    
    return<>
    <div className="LFNOffer doMoreWithLFN">
        <div className="doMoreWithLFN-content">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="heading">What do we have to offer?</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <p>Learn From Native provides you with a world of endless possibilities for your child's growth and development. Explore our wide range of courses, including Phonics, Creative Writing, and Public Speaking. With personalized learning options, tailor your child's education to their unique needs and preferences. Unleash their growth and development with us. </p>
                </div>
            </div>
        </div>
        <div className="menu-items">
            <div className="row g-2 g-md-3 d-flex justify-content-between flex-nowrap">
                <div className={`col-3 pt-4  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-SpeakUpAndShine ${activeTab === 'SpeakUpAndShine' ? 'active' : ''}`} onClick={() => handleClick('SpeakUpAndShine')}>
                   <img src="/assest/images/SpeakUpAndShine.png" alt="Icon" className="mb-5"></img>
                   <p className="text-light text-center">Speak Up and Shine: Public Speaking for Young Stars </p>
                </div>
                <div className={`col-3 pt-4  d-flex flex-column align-items-center justify-content-end courseOverView bg-DiscoverTheMagic ${activeTab === 'DiscoverTheMagic' ? 'active' : ''}`} onClick={() => handleClick('DiscoverTheMagic')}>
                    <img src="/assest/images/DiscoverTheMagic.png" alt="Icon" className="mb-5"></img>
                    <p className="text-light text-center mr-2 ml-2">Discover the Magic of Creative Writing</p>
                </div>
                <div  className={`col-3 pt-4  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-ReadWithPhonics ${activeTab === 'ReadWithPhonics' ? 'active' : ''}`} onClick={() => handleClick('ReadWithPhonics')}>
                   <img src="/assest/images/ReadWithPhonics.png" alt="Icon" className="mb-5"></img>
                   <p className="text-light text-center mr-2 ml-2">Read with Phonics</p>
                </div>
                <div  className={`col-3 pt-4  text-center  d-flex flex-column align-items-center justify-content-end courseOverView bg-GrammarMastery ${activeTab === 'GrammarMastery' ? 'active' : ''}`} onClick={() => handleClick('GrammarMastery')}>
                    <img src="/assest/images/GrammarMastery.png" alt="Icon" className="mb-5"></img>
                    <p className="text-light text-center mr-2 ml-2">Grammar Mastery</p> 
                </div>
            </div>
            <br/>
        </div>
        <div id="web-banner" className="m-4 m-md-5">
            <div id="SpeakUpAndShine" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'SpeakUpAndShine' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our public speaking course for kids is designed to empower young learners to develop confidence, articulate speech, and impactful presentation techniques through engaging activities and real-world examples.  </p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>The Art of Storytelling</li>
                                <li>Channel your Inner YouTuber</li>
                                <li>Impromptu Speaking</li>
                                <li>Vlogging Adventures</li>
                                <li>TED Talk Style Oration and much more...</li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>Students will gain confidence in public speaking, demonstrate articulate speech, showcase storytelling abilities, create engaging videos, and deliver persuasive presentations.</p>
                    </div>
                </div>
            </div>
            <div id="DiscoverTheMagic" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'DiscoverTheMagic' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our Creative Writing Course for Kids is designed to unleash the imagination and storytelling abilities of young writers. Through interactive and engaging lessons, we aim to inspire creativity and develop essential writing skills.</p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Writing Prompts</li>
                                <li>Story Development</li>
                                <li>Blog Writing</li>
                                <li>Imaginative Writing</li>
                                <li>Publishing Opportunities and much more....</li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>With this course, students will have developed their imagination, storytelling skills, and proficiency in various writing styles. They will be equipped with the tools to express their creativity effectively with rich lexicon.</p>
                    </div>
                </div>
            </div>
            <div id="ReadWithPhonics" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'ReadWithPhonics' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our engaging and comprehensive Phonics Course is designed to foster strong reading and language skills, offering a structured and systematic approach to phonics instruction.</p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Essential Building Blocks of phonics – Sounds</li>
                                <li>Blends</li>
                                <li>Family Words</li>
                                <li>Silent Words</li>
                                <li>Vowel & Consonants Graphemes and more...</li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>The learners will have acquired a solid foundation in phonics, including essential sound recognition, blending, and decoding skills. They will be able to read and pronounce words accurately, identify word families, and recognize common vowel and consonant graphemes. The course will equip them with the necessary tools to become confident readers and develop strong language skills. </p>
                    </div>
                </div>
            </div>
            <div id="GrammarMastery" className={`tab-content whatdowehavetooffer-tab-content ${activeTab === 'GrammarMastery' ? 'd-block' : 'd-none'}`}>
                <div className="row m-4 m-md-5 pb-4">
                    <div className="col-12" >
                        <h2 className="heading mb-3">Course Overview:</h2>
                        <p>Our English Grammar Course offers a comprehensive and structured approach to mastering the intricacies of the English language. Through engaging lessons and interactive activities, students will gain a deep understanding of essential grammar rules and sentence construction, strengthening their language fundamentals and achieving fluency.</p>
                        <h2 className="heading mb-3">Course Outline:</h2>
                        <p>
                            <ul>
                                <li>Parts of Speech</li>
                                <li>Figures of Speech</li>
                                <li>Types of Sentences</li>
                                <li>Subject-Verb-Agreement</li>
                                <li>Phrases & Clauses and more...</li>
                            </ul>
                        </p>
                        <h2 className="heading mb-3">Expected Outcome: </h2>
                        <p>After completing the English Grammar Course, students will possess a strong understanding of grammar rules, including parts of speech, figures of speech, subject-verb agreement, and phrases. They will have improved language fundamentals and enhance fluency in English communication.</p>
                    </div>
                </div>
            </div>
        </div>

        <div id="mob-banner" className="d-none m-4 shadow-sm rounded">
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-pills  nav-fill  mb-3 custom-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Overview</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Outline</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Outcome</button>
                        </li>
                    </ul>
                    <div className="tab-content p-4 whatdowehavetooffer-tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className={`${activeTab === 'SpeakUpAndShine' ? 'd-block' : 'd-none'}`}>
                                    <p>Our public speaking course for kids is designed to empower young learners to develop confidence, articulate speech, and impactful presentation techniques through engaging activities and real-world examples.</p>
                                </div>
                                <div className={`${activeTab === 'DiscoverTheMagic' ? 'd-block' : 'd-none'}`}>
                                    <p>Our Creative Writing Course for Kids is designed to unleash the imagination and storytelling abilities of young writers. Through interactive and engaging lessons, we aim to inspire creativity and develop essential writing skills.</p>
                                </div>
                                <div className={`${activeTab === 'ReadWithPhonics' ? 'd-block' : 'd-none'}`}>
                                    <p>Our engaging and comprehensive Phonics Course is designed to foster strong reading and language skills, offering a structured and systematic approach to phonics instruction.</p>
                                </div>
                                <div className={`${activeTab === 'GrammarMastery' ? 'd-block' : 'd-none'}`}>
                                    <p>Our English Grammar Course offers a comprehensive and structured approach to mastering the intricacies of the English language. Through engaging lessons and interactive activities, students will gain a deep understanding of essential grammar rules and sentence construction, strengthening their language fundamentals and achieving fluency.</p>
                                </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className={`${activeTab === 'SpeakUpAndShine' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>The Art of Storytelling</li>
                                        <li>Channel your Inner YouTuber</li>
                                        <li>Impromptu Speaking</li>
                                        <li>Vlogging Adventures</li>
                                        <li>TED Talk Style Oration and much more...</li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'DiscoverTheMagic' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Writing Prompts</li>
                                        <li>Story Development</li>
                                        <li>Blog Writing</li>
                                        <li>Imaginative Writing</li>
                                        <li>Publishing Opportunities and much more....</li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'ReadWithPhonics' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Essential Building Blocks of phonics – Sounds</li>
                                        <li>Blends</li>
                                        <li>Family Words</li>
                                        <li>Silent Words</li>
                                        <li>Vowel & Consonants Graphemes and more...</li>
                                    </ul>
                                </p>
                            </div>
                            <div className={`${activeTab === 'GrammarMastery' ? 'd-block' : 'd-none'}`}>
                                <p>
                                    <ul>
                                        <li>Parts of Speech</li>
                                        <li>Figures of Speech</li>
                                        <li>Types of Sentences</li>
                                        <li>Subject-Verb-Agreement</li>
                                        <li>Phrases & Clauses and more...</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className={`${activeTab === 'SpeakUpAndShine' ? 'd-block' : 'd-none'}`}>
                                <p>Students will gain confidence in public speaking, demonstrate articulate speech, showcase storytelling abilities, create engaging videos, and deliver persuasive presentations. </p>
                            </div>
                            <div className={`${activeTab === 'DiscoverTheMagic' ? 'd-block' : 'd-none'}`}>
                                <p>With this course, students will have developed their imagination, storytelling skills, and proficiency in various writing styles. They will be equipped with the tools to express their creativity effectively with rich lexicon.  </p>
                            </div>
                            <div className={`${activeTab === 'ReadWithPhonics' ? 'd-block' : 'd-none'}`}>
                                <p>The learners will have acquired a solid foundation in phonics, including essential sound recognition, blending, and decoding skills. They will be able to read and pronounce words accurately, identify word families, and recognize common vowel and consonant graphemes. The course will equip them with the necessary tools to become confident readers and develop strong language skills. </p>
                            </div>
                            <div className={`${activeTab === 'GrammarMastery' ? 'd-block' : 'd-none'}`}>
                                <p>After completing the English Grammar Course, students will possess a strong understanding of grammar rules, including parts of speech, figures of speech, subject-verb agreement, and phrases. They will have improved language fundamentals and enhance fluency in English communication.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
        <div className="row mb-4">
            <div className="col-12 col-md-3 d-flex justify-content-center align-items-center mx-auto">
                <button className="btn btn-jordyBlue btn-payNow p-2 text-center w-100 mx-5 mx-md-0" onClick={() => onBookAFreeDemo()}>
                Book a Free Demo
                </button>
            </div>
        </div>



    </div>
    </>

}

export default WhatDoWeHaveToOffer
function BookAFreeDemo(props) {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  const onBookAFreeDemo = () => {
    window.location.href = "/#planPricingSection";
  };

  const bannerContent = () => {
    if (props.Route == "HomePage") {
      if (countryName == "India") {
        return (
          <>
            <div className="mb-md-4">
              <h4>
                Learn English with Confidence: Click to Begin Your Mastery
                Journey
              </h4>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="mb-md-4">
              <h4>
                AI-Powered High Impact Tutoring : Your Pathway to Excellence,
                Just a Click Away!
              </h4>
            </div>
          </>
        );
      }
    } else {
      if (countryName == "India") {
        return (
          <>
            <div className="mb-md-4">
              <h4>
                Building Little Linguists: Click for Kids' English Success{" "}
              </h4>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="mb-md-4">
              <h4>
                AI-Powered High Impact Tutoring : Your Pathway to Excellence,
                Just a Click Away!
              </h4>
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="banner-card mb-5">
        <div id="web-banner">
          <img
            src="/assest/images/bookAFreeDemo-bg-2.png"
            className="w-100"
          ></img>
          <div className="banner-text mr-5">
            {bannerContent()}
            <button className="btn btn-bookAFreeDemo" onClick={onBookAFreeDemo}>
              {" "}
              Book A Free Demo Now!
            </button>
          </div>
        </div>
        <div id="mob-banner" className="d-none">
          <img
            src="/assest/images/mobile/bookAFreeDemo-bg.png"
            className="w-100"
          ></img>
          <div className="banner-text">
            {bannerContent()}
            <button
              className="btn btn-bookAFreeDemo mt-2"
              onClick={onBookAFreeDemo}
            >
              {" "}
              Book A Free Demo Now!
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookAFreeDemo;

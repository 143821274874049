import React, { useEffect, useState } from "react"
import KidsCountController from "./KidsCountController"

function KidsIndiaContent()
{
    const [squareOrder, setSquareOrder] = useState([1, 2, 3, 4]);
    const [activeSquare, setActiveSquare] = useState(1);
   
    
    
    const [squares,setSquares] = useState
    ([
        { id: 1, title: 'Soft Skills Development', content: 'Our courses go beyond language proficiency, fostering communication, critical thinking, creativity, and collaboration skills in children.' ,img:'/assest/images/SoftSkillDevelopment-top.png'},
        { id: 2, title: 'Gamified Engaging Chapters', content: 'Interactive adventures with games, quizzes, and exercises capture children\'s interest and maintain their motivation to explore new topics.' ,img:'/assest/images/GamifiedEngagingChapters.png'},
        { id: 3, title: 'Progress Tracking & Assessments:', content: 'Regular assessments and progress tracking enable parents to monitor their child\'s growth, ensuring personalized attention and tailored instruction.' ,img:'/assest/images/ProgressTrackingAndAssessments.png'},
        { id: 4, title: 'Play-way Method', content: 'Learning through play and hands-on activities keeps kids actively engaged, making language learning a joyful experience.',img:'/assest/images/PlayWayMethod.png' },
    ]);

    useEffect(() => 
    {
      const interval = setInterval(() => {
        rotateClassNames();
      }, 2000);

      return () => clearInterval(interval);
    }, []);

    
    
    
    const rotateClassNames = () => 
    {
        setSquares((prevSquares) => {
            const newSquares = [...prevSquares];
            const lastSquare = newSquares.pop();
            newSquares.unshift(lastSquare);
            
            return newSquares;
        });

    };

    const onBookAFreeDemo=()=>
    {
        window.location.href='/#planPricingSection'
    }
    
    
    return(
        <div className="kids">
        <div className="kids-banner-card mb-5 best-tutor-banner-card">
            <div id="web-banner">
                <img src="/assest/images/kids-banner.png" className="w-100 "></img>
                <div className="best-tutor-banner-text">
                    <div className="kids-text">
                        <h4>AI-Powered English Learning</h4>
                        <h4>for Kids with Global Teachers</h4>
                    </div>
                    <button className="btn btn-bestTutor w-75" onClick={onBookAFreeDemo}>Join the Free Demo</button>
                </div>
            </div>
            
            <div id="mob-banner" className="d-none">
                <div className="row mb-3">
                    <img src="/assest/images/mobile/kids-banner-mobile.png" className="w-100"></img>
                </div>
                <div className="row text-center mb-2">
                    <h1>AI-Powered English Learning</h1>
                    <h1>for Kids with Global Teachers</h1>
                </div>
                <div className="d-flex justify-content-center">
                <div className="">
                    <button className="btn btn-bestTutor" onClick={onBookAFreeDemo}>Join the Free Demo</button>
                </div>
            </div>
            </div>
         </div>
        <KidsCountController/>
        
        
        <div className="kids-offer ">
                <div className="kids-offer-content row ">
                    <div className="col-12">
                        <h1 className="heading">Why Learn From Native?</h1>
                        <p>At Learn From Native, our child-centered approach to English language courses for kids ensures an engaging and personalized learning experience. Here are the key features that make our courses stand out: </p>
                    </div>
                </div>
                
                {/* <div className="kids-offer-content m-0">
                    <div className="row">
                        <div className="col-4 mx-auto">
                            <div className={`howLearnFromNativeWorks-item ${currentIndex === 3 ? "active" : ""}`}>
                                <div className="circle circle-4">4</div>
                                <h2>Soft Skills Development</h2>
                                <p>Our courses go beyond language proficiency, fostering communication, critical thinking, creativity, and collaboration skills in children. </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className={`howLearnFromNativeWorks-item ${currentIndex === 3 ? "active" : ""}`}>
                                <div className="circle circle-4">4</div>
                                <h2>Play-way Method</h2>
                                <p>Learning through play and hands-on activities keeps kids actively engaged, making language learning a joyful experience. </p>
                            </div>
                        </div>
                        <div className="col-4">
                            
                        </div>
                        <div className="col-4">
                            <div className={`howLearnFromNativeWorks-item ${currentIndex === 3 ? "active" : ""}`}>
                                <div className="circle circle-4">4</div>
                                <h2>Gamified Engaging Chapters</h2>
                                <p>Interactive adventures with games, quizzes, and exercises capture children's interest and maintain their motivation to explore new topics. </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 mx-auto">
                            <div className={`howLearnFromNativeWorks-item ${currentIndex === 3 ? "active" : ""}`}>
                                <div className="circle circle-4">4</div>
                                <h2>Progress Tracking & Assessments:</h2>
                                <p>Regular assessments and progress tracking enable parents to monitor their child's growth, ensuring personalized attention and tailored instruction.</p>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div> */}

                

            <div className="circle-container" id='web-banner'>
            {squareOrder.map((className) => (
                <div key={className} className={`square square-${className} text-center ${className === activeSquare ? 'animate' : ''}`}>
                <div className="circular-image-container">
                    <img src={squares[className - 1].img} alt={`Icon ${className}`} />
                </div>
                <h3 className="heading">{squares[className - 1].title}</h3>
                <p>{squares[className - 1].content}</p>
                </div>
            ))}
            </div>

            <div id='mob-banner' className="d-none">
                {squares.map((square, index) => (
                <React.Fragment key={index}>
                <div className="row">
                    <div className="col-10 mx-auto">
                    <div className="square text-center">
                        <div className="circular-image-container">
                        <img src={square.img} alt={`Icon ${index + 1}`} />
                        </div>
                        <h3 className="heading">{square.title}</h3>
                        <p>{square.content}</p>
                    </div>
                    </div>
                </div>
                {index !== squares.length - 1 && ( // Add the dotted line between rows except for the last row
                    <div className="row">
                    <div className="col-10 mx-auto d-flex align-items-center justify-content-center">
                        <div className="dotted-line"></div> {/* Dotted line between rows */}
                    </div>
                    </div>
                )}
                </React.Fragment>
            ))}
            </div>
        </div>
    </div>

    )
}

export default KidsIndiaContent
import { useEffect, useState } from "react";
import { Execute } from "../Services/APIService";
import {
  IRatingStyleProps,
  IRatingStyles,
  IStyleFunctionOrObject,
  Rating,
  RatingSize,
} from "@fluentui/react";
import { colorCode } from "../utils/colors";
import { useNavigate } from "react-router-dom";
import OneOnOnePlanPricingTutorList from "./OneOnOnePlanPricingTutorList";
import Dailogue from "../Controls/Modal";
import { useSelector } from "react-redux";
import { selectloginOptions } from "../features/appData/loginOptionsSlice";
import { selectCountryOptions } from "../features/appData/countryOptionsSlice";
import LanguageOffered from "./LanguageOffered";

function PlanPricing(props) {
  const [isMobile, setIsMobile] = useState(false);
  var learnItems = [];

  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  var countryID;
  var url = "https://learnfromnative.com"; //window.location.href

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);
  var countryName = sessionDetails.CountryName;

  if (countryName == "India") {
    learnItems = [{ name: "English" }, { name: "French" }, { name: "Hindi" }];
  } else {
    learnItems = [
      { name: "Chemistry" },
      { name: "Math" },
      { name: "Biology" },
      { name: "English" },
      { name: "Physics" },
    ];
  }

  if (sessionDetails != null || sessionDetails != undefined) {
    countryID = sessionDetails.CountryID;
  }

  const userImgPath =
    "https://liveapi.learnfromnative.com/documents/User Photos/";
  const navigate = useNavigate();

  const [oneOnOnePlanPricingItems, setOneOnOnePlanPricingItems] = useState([]);
  const [groupPlanPricingItems, setGroupPlanPricingItems] = useState([]);
  const [groupPlanOfferingItems, setGroupPlanOfferingItems] = useState([]); //Make this one
  const [planOfferingItems, setPlanOfferingItems] = useState([]); //Make this one
  const [selectedPlanDetails, setSelectedPlanDetails] = useState([]);
  const [classMode, setClassMode] = useState("One-on-One");
  const [selectedSubjectName, setSelectedSubjectName] = useState(null);
  const [countryWiseSubjects, setCountryWiseSubjects] = useState(null);

  const PlanOffering = planOfferingItems.map((obj) => {
    if (planOfferingItems.length > 0) {
      return <li>{obj.OfferingDescription}</li>;
    }
  });

  // if (props.subjectName) {
  //   setSelectedSubjectName(props.selectedSubjectName);
  // }

  //ClassMode-OneOnOne
  const getOneOnOnePlan = () => {
    document.getElementById("groupPlan").style.display = "none";
    document.getElementById("oneOnOnePlan").style.display = "block";

    if (screenWidth < 768) {
      const mobTeacherPlanPricingList = document.getElementsByClassName(
        "mob-teacherPlanPricingList"
      )[0] as HTMLElement;
      mobTeacherPlanPricingList.style.display = "none";
    } else {
      document.getElementById("groupPlan").style.display = "none";
    }

    setClassMode("One-on-One");
  };

  //ClassMode-Group
  const getGroupPlan = () => {
    document.getElementById("oneOnOnePlan").style.display = "none";

    if (screenWidth < 768) {
      const mobTeacherPlanPricingList = document.getElementsByClassName(
        "mob-teacherPlanPricingList"
      )[0] as HTMLElement;
      const webTeacherPlanPricingList = document.getElementsByClassName(
        "web-teacherPlanPricingList"
      )[0] as HTMLElement;

      mobTeacherPlanPricingList.style.display = "block";
      webTeacherPlanPricingList.style.display = "none";
    } else {
      document.getElementById("groupPlan").style.display = "block";
    }

    setClassMode("Group");
  };

  //Curriculum
  const [planCurriculumItems, setPlanCurriculumItems] = useState([]);
  const getCurriculum = (selectedPlanDetails) => {
    const oSelectFewFromClassAndPlanClassesWherePlanIDAndNonDeleted = {
      ActionName:
        "LFN.GMst_SelectFewFromClassAndPlanClassesWherePlanIDAndNonDeleted",
      ParameterJSON:
        '{"TeachingLanguageID  ":"' +
        1 +
        '","PlanID ":"' +
        selectedPlanDetails.PlanID +
        '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":"web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromClassAndPlanClassesWherePlanIDAndNonDeleted).then(
      (response) => {
        if (!response.DataIsLoaded)
          return (
            <div>
              <h1>Please wait some time....</h1>
            </div>
          );
        else {
          setPlanCurriculumItems(response.items);
        }
      }
    );
  };

  const getCertificate = (planID) => {
    const oSelectFewFromClassAndPlanClassesWherePlanIDAndNonDeleted = {
      ActionName:
        "LFN.GMst_SelectFewFromClassAndPlanClassesWherePlanIDAndNonDeleted",
      ParameterJSON:
        '{"TeachingLanguageID  ":"' + 1 + '","PlanID ":"' + planID + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":"web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromClassAndPlanClassesWherePlanIDAndNonDeleted).then(
      (response) => {
        if (!response.DataIsLoaded)
          return (
            <div>
              <h1> Please wait some time....</h1>
            </div>
          );
        else {
          setPlanCurriculumItems(response.items);
        }
      }
    );
  };

  useEffect(() => {
    //Group Plan
    const oSelectFewFromPlanAndMastersWherePlanTypeIsGroup = {
      ActionName: "LFN.GMst_SelectFewFromPlanAndMastersWherePlanTypeIsGroup",
      ParameterJSON:
        '{"CountryID ":"' + countryID + '","TeachingLanguageID ":"' + 1 + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":" Web ","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromPlanAndMastersWherePlanTypeIsGroup).then(
      (response) => {
        if (!response.DataIsLoaded)
          return (
            <div>
              <h1> Please wait some time.... </h1>{" "}
            </div>
          );
        else {
          setGroupPlanPricingItems(response.items);
          setSelectedPlanDetails(response.items[1]); //ByDefaultSetForCurriculumPopup
          getPlanOffering(response.items[1].PlanID); //ByDefaultSetForPlanOffering
        }
      }
    );

    //oneOneOne Plan
    const oSelectFewFromPlanAndMastersWherePlanTypeIsIndividualNew = {
      ActionName:
        "LFN.GMst_SelectFewFromPlanAndMastersWherePlanTypeIsIndividualNew",
      ParameterJSON:
        '{"CountryID ":"' + countryID + '","TeachingLanguageID ":"' + 1 + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":" Web ","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromPlanAndMastersWherePlanTypeIsIndividualNew).then(
      (response) => {
        if (response.DataIsLoaded) {
          setOneOnOnePlanPricingItems(response.items);
        }
      }
    );
  }, []);

  var topThreePlanPricing = oneOnOnePlanPricingItems.slice(0, 3);

  //PlanOffring-Group and One-on-One
  const getPlanOffering = async (PlanID) => {
    const oSelectFewFromPlanOfferingsWherePlanIDAndNonDeleted = {
      ActionName: "LFN.GMst_SelectFewFromPlanOfferingsWherePlanIDAndNonDeleted",
      ParameterJSON: '{"PlanID ":"' + PlanID + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    const response = await Execute(
      oSelectFewFromPlanOfferingsWherePlanIDAndNonDeleted
    );
    if (!response.DataIsLoaded) {
      return (
        <div>
          <h1>Please wait some time....</h1>
        </div>
      );
    } else {
      setPlanOfferingItems(response.items);
      return response.items; //NewValue
    }
  };

  function handlePlanSelection(event, obj) {
    setSelectedPlanDetails(obj);
    // get all div elements with the class name "groupPlanPricingCard"
    const divs = document.querySelectorAll(".groupPlanPricingCard");

    // remove the "bordered" class from all divs
    divs.forEach((div) => {
      div.classList.remove("highlightedPlan");
      (div as HTMLDivElement).style.border = ""; //removed by default Intermediate Plan border
    });

    // add the "bordered" class to the clicked div
    event.currentTarget.classList.add("highlightedPlan");
    getPlanOffering(obj.PlanID);
  }

  const getTutorProfile = async (obj) => {
    const planOfferingDetails = await getPlanOffering(obj.PlanID);
    navigate("/TutorProfile", {
      state: {
        tutorProfileDetails: obj,
        planOfferingItems: planOfferingDetails,
      },
    });
  };

  const handleClick = () => {
    window.location.href = "/PlanPricingTutorList";
  };

  const handleGroupPayNow = (obj) => {
    navigate("/BillingInvoice", { state: { tutorProfileDetails: obj } });
  };

  const getLanguage = (subjectName) => {
    // if (subjectName == "Hindi") {
    //   $("#languageOfferedModalCenter").modal("show");
    //   return;
    // }
    //$('#languageOfferedModalCenter').modal('show')
    //setSelectedSubjectName(subjectName);
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content">
        <img src="/assest/images/lfn-logo.png" className="m-5"></img>
        <h3 className="mb-5">
          Currently, we are offering English Language Courses.
        </h3>
        <h3>
          French, Spanish, German, Hindi, IELTS Training, <br />
          and much more
          <br /> coming soon!
        </h3>
      </div>
    );
  };
  const [currentIndex, setcurrentIndex] = useState(1);

  const handlePreviousClick = (event, obj) => {
    setcurrentIndex(
      currentIndex === 0 ? groupPlanPricingItems.length - 1 : currentIndex - 1
    );
    handlePlanSelection(event, groupPlanPricingItems[currentIndex - 1]);
  };

  const handleNextClick = (event, obj) => {
    setcurrentIndex(
      currentIndex === groupPlanPricingItems.length - 1 ? 0 : currentIndex + 1
    );
    handlePlanSelection(event, groupPlanPricingItems[currentIndex + 1]);
  };

  return (
    <>
      <LanguageOffered
        showSubjectList={true}
        sendSelectedSubjectDataToChild={setSelectedSubjectName}
      />
      <div className="planPricing p-1 p-md-5" id="planPricingSection">
        <div className="planPricing-content">
          <OneOnOnePlanPricingTutorList
            CountryID={countryID}
            selectedSubjectName={selectedSubjectName}
            filterData
          />

          <div
            className="web-teacherPlanPricingList teacherPlanPricingList"
            id="groupPlan"
          >
            <div className="teacherPlanPricingList-content">
              <div className="row text-center">
                {groupPlanPricingItems.map((obj, index) => (
                  <div
                    className="col-md-4 groupPlanPricingCard "
                    style={index === 1 ? { border: "3px solid #FF5858" } : {}}
                    onClick={(event) => handlePlanSelection(event, obj)}
                  >
                    <p id="planMonth">{obj.PlanDuration}</p>
                    <p id="planName">{obj.PlanName}</p>
                    <div>
                      {(() => {
                        if (obj.PlanOfferPricing === obj.PlanOriginalPricing) {
                          return (
                            <>
                              <p id="planOrignalPricing">
                                {obj.PlanPricingCurrency +
                                  " " +
                                  obj.PlanOriginalPricing}
                              </p>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <p id="planOrignalPricing">
                                {obj.PlanPricingCurrency +
                                  " " +
                                  obj.PlanOriginalPricing}
                              </p>
                              <p id="planOfferPricing">
                                {obj.PlanPricingCurrency +
                                  " " +
                                  obj.PlanOfferPricing}
                              </p>
                            </>
                          );
                        }
                      })()}
                    </div>

                    <div>
                      {(() => {
                        if (
                          obj.PlanOfferingPricingPerClass ===
                          obj.PlanOriginalPricingPerClass
                        ) {
                          return (
                            <>
                              <p id="planPerClassPricing">
                                {obj.PlanPricingCurrency +
                                  " " +
                                  obj.PlanOriginalPricingPerClass +
                                  "/class"}
                              </p>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <p id="planPerClassPricing">
                                {obj.PlanPricingCurrency +
                                  " " +
                                  obj.PlanOfferingPricingPerClass +
                                  "/class"}
                              </p>
                            </>
                          );
                        }
                      })()}
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-12 planPricingOffering">
                  <ol>
                    {PlanOffering}
                    <li>
                      Click here for the{" "}
                      <span
                        onClick={() => getCurriculum(selectedPlanDetails)}
                        data-toggle="modal"
                        data-target="#curriculumModalCenter"
                      >
                        Curriculum
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={getCertificate}
                        data-toggle="modal"
                        data-target="#certificateModalCenter"
                      >
                        Certificated & Badges
                      </span>{" "}
                      after Course Completion
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <button
              className="btn btn-tomato w-100 btn-PlanPricing"
              onClick={() => handleGroupPayNow(selectedPlanDetails)}
            >
              Pay Now
            </button>
          </div>

          <div
            className="mob-teacherPlanPricingList teacherPlanPricingList"
            id="groupPlan"
          >
            <div className="teacherPlanPricingList-content">
              <div className="row text-center">
                {groupPlanPricingItems.map((obj, index) =>
                  index == currentIndex ? (
                    <>
                      <div className="pricing-container">
                        <div className="row g-0">
                          <div className="col-2">
                            <button
                              onClick={(event) =>
                                handlePreviousClick(event, obj)
                              }
                              disabled={currentIndex === 0}
                              style={{ height: "100%" }}
                            >
                              <i className="bi bi-chevron-left"></i>
                            </button>
                          </div>
                          <div className="col-8">
                            <div
                              className="groupPlanPricingCard "
                              style={
                                index === currentIndex
                                  ? { border: "3px solid #FF5858" }
                                  : {}
                              }
                              onClick={(event) =>
                                handlePlanSelection(event, obj)
                              }
                            >
                              <p id="planMonth">{obj.PlanDuration}</p>
                              <p id="planName">{obj.PlanName}</p>
                              <div>
                                {(() => {
                                  if (
                                    obj.PlanOfferPricing ===
                                    obj.PlanOriginalPricing
                                  ) {
                                    return (
                                      <>
                                        <p id="planOrignalPricing">
                                          {obj.PlanPricingCurrency +
                                            " " +
                                            obj.PlanOriginalPricing}
                                        </p>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <p id="planOrignalPricing">
                                          {obj.PlanPricingCurrency +
                                            " " +
                                            obj.PlanOriginalPricing}
                                        </p>
                                        <p id="planOfferPricing">
                                          {obj.PlanPricingCurrency +
                                            " " +
                                            obj.PlanOfferPricing}
                                        </p>
                                      </>
                                    );
                                  }
                                })()}
                              </div>

                              <div>
                                {(() => {
                                  if (
                                    obj.PlanOfferingPricingPerClass ===
                                    obj.PlanOriginalPricingPerClass
                                  ) {
                                    return (
                                      <>
                                        <p id="planPerClassPricing">
                                          {obj.PlanPricingCurrency +
                                            " " +
                                            obj.PlanOriginalPricingPerClass +
                                            "/class"}
                                        </p>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <p id="planPerClassPricing">
                                          {obj.PlanPricingCurrency +
                                            " " +
                                            obj.PlanOfferingPricingPerClass +
                                            "/class"}
                                        </p>
                                      </>
                                    );
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <button
                              onClick={(event) => handleNextClick(event, obj)}
                              disabled={
                                currentIndex ===
                                groupPlanPricingItems.length - 1
                              }
                              style={{ height: "100%" }}
                            >
                              <i className="bi bi-chevron-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null
                )}
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-12 planPricingOffering">
                  <ol>
                    {PlanOffering}
                    <li>
                      Click here for the{" "}
                      <span
                        onClick={() => getCurriculum(selectedPlanDetails)}
                        data-toggle="modal"
                        data-target="#curriculumModalCenter"
                      >
                        Curriculum
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={getCertificate}
                        data-toggle="modal"
                        data-target="#certificateModalCenter"
                      >
                        Certificated & Badges
                      </span>{" "}
                      after Course Completion
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <button
              className="btn btn-tomato w-100 btn-PlanPricing"
              onClick={() => handleGroupPayNow(selectedPlanDetails)}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>

      {/* Curriculum Popup */}
      <div
        className="modal fade"
        id="curriculumModalCenter"
        role="dialog"
        aria-labelledby="curriculumModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Plan Curriculum
              </h5>
              <button
                type="button"
                className="close border-0 bg-transparent"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {planCurriculumItems?.map((obj) => {
                if (planCurriculumItems.length > 0) {
                  return <li className="popupContent-lbl">{obj.ClassName}</li>;
                }
              })}
            </div>
          </div>
        </div>
      </div>

      {/*Certificate Popup */}
      <div
        className="modal fade"
        id="certificateModalCenter"
        role="dialog"
        aria-labelledby="certificateModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Plan Certificate & Badges
              </h5>
              <button
                type="button"
                className="close border-0 bg-transparent"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {planOfferingItems.length > 0 ? (
                <img
                  src={planOfferingItems[0].StudentCertificatePreviewURL}
                  style={{ height: "100%", width: "100%" }}
                />
              ) : (
                <span>No image available</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dailogue
        id={"languageOfferedModalCenter"}
        body={getDailogueBody}
        title=""
      />
    </>
  );
}

export default PlanPricing;

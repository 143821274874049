import { useEffect, useState } from "react";
import Header from "../component/Header";
// import Login from "../component/Login"
import { useLocation, useNavigate } from "react-router-dom";
import { Execute } from "../Services/APIService";
import Dailogue from "../Controls/Modal";
import Login from "../component/Login";

function FreeDemoClassBokkingConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();

  var slotFromOnTime = location.state.SlotFromOnTime;
  var slotToOnTime = location.state.SlotToOnTime;
  var ClassDate = location.state.ClassDate;
  var classDuration = location.state.ClassDuration;
  var classSlotID = location.state.ClassSlotID;
  var items = location.state.obj;

  var webAppName = "WebsitePortal";
  var userID;
  var sessionID;

  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    userID = sessionDetails.UserID;
    if (webAppName === "LearnerPortal") {
      sessionID = sessionDetails.SessionID;
    } else {
      sessionID = 3;
    }
  }

  const validateUser = () => {
    return new Promise<void>((resolve) => {
      var sessionData = localStorage.getItem("items");
      var sessionDetails = JSON.parse(sessionData);
      if (sessionDetails != null || sessionDetails != undefined) {
        userID = sessionDetails.UserID;
        if (userID == null || userID == "" || userID == undefined) {
          $("#loginModalCenter").modal("show");
          return;
        }
      } else {
        $("#loginModalCenter").modal("show");
        return;
      }
      resolve();
    });
  };

  // useEffect(() =>
  // {
  //   validateUser()
  // },[]);

  const onBookNow = () => {
    validateUser().then(() => {
      navigate("/FreeDemoClassBookingCalendarView", {
        state: { items: items },
      });

      // const oInsertPurchaseAndMastersForFreeDemo = {
      //   ActionName: "LFN.GTra_InsertPurchaseAndMastersForFreeDemo",
      //   ParameterJSON:
      //     '{"ClassSlotID":"' + classSlotID + '","RowsAffecting":"4"}',
      //   SessionDataJSON:
      //     '{"UserID":"' +
      //     userID +
      //     '","SessionID":"' +
      //     sessionID +
      //     '","OnBehalfOfUserID":"' +
      //     userID +
      //     '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      //   OperationName: "Command",
      // };
      // Execute(oInsertPurchaseAndMastersForFreeDemo).then((response) => {
      //   if (!response.DataIsLoaded) {
      //     return (
      //       <div>
      //         <h1>Please wait some time...</h1>
      //       </div>
      //     );
      //   } else {
      //     if (response.message === "Successfull") {
      //       var responseData = JSON.parse(response.items);
      //       var PurchaseClassID = responseData[0].PurchaseClassID;

      //       if (PurchaseClassID != undefined || PurchaseClassID != "") {
      //         const oUpdatePurchaseClassesForClassSlotBooking = {
      //           ActionName: "LFN.GTra_UpdatePurchaseClassesForClassSlotBooking",
      //           ParameterJSON:
      //             '{"ClassSlotID":"' +
      //             classSlotID +
      //             '","PurchaseClassID":"' +
      //             PurchaseClassID +
      //             '","RowsAffecting":"1"}',
      //           SessionDataJSON:
      //             '{"UserID":"' +
      //             userID +
      //             '","SessionID":"' +
      //             sessionID +
      //             '","OnBehalfOfUserID":"' +
      //             userID +
      //             '","CompanyID":"100","SubscriberID":"10000","AppID":"learner.learnfromnative.com","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      //           OperationName: "Command",
      //         };
      //         Execute(oUpdatePurchaseClassesForClassSlotBooking).then(
      //           (response) => {
      //             if (!response.DataIsLoaded) {
      //               return (
      //                 <div>
      //                   <h1>Please wait some time...</h1>
      //                 </div>
      //               );
      //             } else {
      //               if (response.message === "Successfull") {
      //                 $("#bookingConfirmationModalCenter").modal("show");
      //               } else {
      //                 alert(response.message);
      //               }
      //             }
      //           }
      //         );
      //       }
      //     } else {
      //       alert(response.message);
      //     }
      //   }
      // });
    });
  };

  const onOkayButtonClick = () => {
    if (webAppName === "LearnerPortal") {
      window.location.href = "/PlanPricing";
    } else {
      window.location.href = "/#planPricingSection";
    }
  };

  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const sendMail = () => {
    const mailtoUrl = `mailto:${"connect@learnfromnative.com"}?subject=${encodeURIComponent(
      "Query About Class Booking"
    )}`;
    window.location.href = mailtoUrl;
  };

  const supportPhoneNumber = "+918058058089";
  const callOnSupportPhoneNo = () => {
    const telLink = `tel:${supportPhoneNumber}`;
    window.location.href = telLink;
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content bookingConfirmation">
        <p>
          Thank you for scheduling a Free Demo Class with us!
          <br />
          Your class is booked for
          <br />
          <b>{ClassDate}</b> at <b>{slotFromOnTime}</b>.
          <br />
          For any inquiries, reach out to us at{" "}
          <span
            style={{ color: "#0C9AD8", textDecoration: "underline" }}
            onClick={() => sendMail()}
          >
            connect@learnfromnative.com
          </span>{" "}
          or call
          {screenWidth < 768 ? (
            <span
              style={{ color: "#0C9AD8" }}
              onClick={() => callOnSupportPhoneNo()}
            >
              {" "}
              +918058058089
            </span>
          ) : (
            <span> +918058058089</span>
          )}
        </p>
        <button
          className="CTA-btn"
          style={{
            color: "white",
            backgroundColor: "#117800",
            borderColor: "#117800",
            width: "100px",
            height: "50px",
          }}
          onClick={() => onOkayButtonClick()}
        >
          Okay
        </button>
      </div>
    );
  };

  const day = new Date(ClassDate).getDay();
  const alldays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const classDay = alldays[day];

  const onModalClose = () => {
    //Do something
    window.location.href = "/#planPricingSection";
  };

  return (
    <>
      {webAppName != "LearnerPortal" && <Header />}
      <div className="billingInvoice m-4 m-md-5">
        <div className="text-center">
          <h2 style={{ fontWeight: "bold" }}>Booking Confirmation</h2>
        </div>
        <br />
        <div className="row">
          <div className="col-md-5 serviceDetails mb-5">
            <h2 style={{ color: "#878787" }}>Class Details</h2>

            <div className="row">
              <div className="col-5 col-md-5">
                {/* <p>Class Duration</p>
                <p>Class Start Time</p>
                <p>Class End Time</p>
                <p>Class Date</p> */}
              </div>
              <div className="col-7 col-md-7">
                {/* <p>- {classDuration} mins class</p>
                <p>- {slotFromOnTime}</p>
                <p>- {slotToOnTime}</p>
                <p>- {classDay}, {ClassDate}</p>    */}
              </div>
            </div>
          </div>
          <div className="col-md-4 offset-md-3 billingInvoice fixedContentToFooter">
            <h2 style={{ fontWeight: "bold" }}>Billing Invoice</h2>
            <div className="row">
              <div className="col-6">
                <p>Per Class Cost</p>
                <p>Tutor</p>
              </div>
              <div className="col-6">
                <p>
                  - {items.PlanPricingCurrency + " " + items.PlanDemoPricing}
                </p>
                <p> - {items.TeacherName}</p>
              </div>
            </div>
            <hr className="mt-0" />
            <div className="row">
              <div className="col-6">
                <p>Total Cost</p>
              </div>
              <div className="col-6">
                <p>
                  - {items.PlanPricingCurrency + " " + items.PlanDemoPricing}
                </p>
              </div>
            </div>

            <button
              className="btn btn-tomato w-100 d-none d-md-block"
              onClick={() => onBookNow()}
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
      <button
        className="btn btn-tomato w-100 d-md-none p-2 fixed-bottom mb-0"
        onClick={() => onBookNow()}
      >
        Book Now
      </button>

      <Login handleClick={onModalClose} />
      <Dailogue
        id={"bookingConfirmationModalCenter"}
        body={getDailogueBody}
        title=""
      />
    </>
  );
}
export default FreeDemoClassBokkingConfirmation;

import { Modal } from "@fluentui/react";
import Dailogue from "../Controls/Modal";
import { useEffect, useState } from "react";
import { Execute } from "../Services/APIService";

function LanguageOffered(props) {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);
  var languageOfferedItems = [];
  var moblanguageOfferedItems = [];
  var offeredOptionHeadline = "";
  var sliceItems;
  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
    var countryID = sessionDetails.CountryID;
  }
  const [countryWiseSubjects, setCountryWiseSubjects] = useState(null);
  const [selectedSubjectName, setSelectedSubjectName] = useState(null);

  useEffect(() => {
    //subject
    const oSelectFewFromSubjectAndMasterWhereCountryID = {
      ActionName: "LFN.GMst_SelectFewFromSubjectAndMasterWhereCountryID",
      ParameterJSON: '{"CountryID ":"' + countryID + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":"Web","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromSubjectAndMasterWhereCountryID).then((response) => {
      if (response.DataIsLoaded) {
        setCountryWiseSubjects(response.items);
      }
    });
    if (countryName == "India") {
      setSelectedSubjectName("English");
    } else {
      setSelectedSubjectName("Physics");
    }
  }, []);

  if (props.sendSelectedSubjectDataToChild) {
    props.sendSelectedSubjectDataToChild(selectedSubjectName);
  }

  if (countryName == "India") {
    if (props.showSubjectList == true) {
      offeredOptionHeadline = "Subject Offered";
      sliceItems = 3;
      languageOfferedItems = [
        {
          SubjectName: "English",
          src: "/assest/images/languages/English.png",
        },

        {
          SubjectName: "French",
          src: "/assest/images/languages/French.png",
        },
        {
          SubjectName: "Hindi",
          src: "/assest/images/languages/Hindi.png",
        },

        //Added same image again, for infinite auto scroll and smooth transition
        {
          SubjectName: "English",
          src: "/assest/images/languages/English.png",
        },

        {
          SubjectName: "French",
          src: "/assest/images/languages/French.png",
        },
        {
          SubjectName: "Hindi",
          src: "/assest/images/languages/Hindi.png",
        },
      ];
    } else {
      offeredOptionHeadline = "Languages Offered";
      sliceItems = 6;
      languageOfferedItems = [
        {
          SubjectName: "English",
          src: "/assest/images/languages/English.png",
        },
        {
          SubjectName: "Spanish",
          src: "/assest/images/languages/Spanish.png",
        },
        {
          SubjectName: "French",
          src: "/assest/images/languages/French.png",
        },
        {
          SubjectName: "Hindi",
          src: "/assest/images/languages/Hindi.png",
        },
        {
          SubjectName: "German",
          src: "/assest/images/languages/German.png",
        },
        {
          SubjectName: "Italian",
          src: "/assest/images/languages/Italian.png",
        },
        //Added same image again, for infinite auto scroll and smooth transition
        {
          SubjectName: "English",
          src: "/assest/images/languages/English.png",
        },
        {
          SubjectName: "Spanish",
          src: "/assest/images/languages/Spanish.png",
        },
        {
          SubjectName: "French",
          src: "/assest/images/languages/French.png",
        },
        {
          SubjectName: "Hindi",
          src: "/assest/images/languages/Hindi.png",
        },
        {
          SubjectName: "German",
          src: "/assest/images/languages/German.png",
        },
        {
          SubjectName: "Italian",
          src: "/assest/images/languages/Italian.png",
        },
      ];
    }
  } else {
    offeredOptionHeadline = "Subjects Offered";

    sliceItems = 5;
    languageOfferedItems = [
      {
        SubjectName: "Physics",
        src: "/assest/images/languages/Physics.png",
      },
      {
        SubjectName: "Chemistry",
        src: "/assest/images/languages/Chemistry.png",
      },
      {
        SubjectName: "Math",
        src: "/assest/images/languages/Mathematics.png",
      },
      {
        SubjectName: "Biology",
        src: "/assest/images/languages/Biology.png",
      },
      {
        SubjectName: "English",
        src: "/assest/images/languages/EnglishLiteraryArts.png",
      },

      //Added same image again, for infinite auto scroll and smooth transition

      {
        SubjectName: "Physics",
        src: "/assest/images/languages/Physics.png",
      },
      {
        SubjectName: "Chemistry",
        src: "/assest/images/languages/Chemistry.png",
      },
      {
        SubjectName: "Math",
        src: "/assest/images/languages/Mathematics.png",
      },
      {
        SubjectName: "Biology",
        src: "/assest/images/languages/Biology.png",
      },
      {
        SubjectName: "English",
        src: "/assest/images/languages/EnglishLiteraryArts.png",
      },
    ];
  }

  const getLanguage = () => {
    $("#languageOfferedModalCenter").modal("show");
  };

  const getDailogueBody = () => {
    return (
      <div className="text-center languageModal-Content">
        <img src="/assest/images/lfn-logo.png" className="m-5"></img>
        <h3 className="mb-5">
          We currently provide English language courses, and we have exciting
          plans to expand our offerings to include French, Hindi, Spanish, and
          more in the near future.{" "}
        </h3>
      </div>
    );
  };

  const handleSelectedSubjectName = (subjectName) => {
    setSelectedSubjectName(subjectName);
  };

  function getLanguageOffered() {
    return (
      <>
        <div className="languagesOffered m-4 m-md-5">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="heading text-100">{offeredOptionHeadline}</h1>
            </div>
          </div>
          <br></br>
          <div className="languageOffered-content d-flex" id="web-banner">
            {languageOfferedItems.slice(0, sliceItems).map((obj) => (
              <div className="languages">
                <>
                  <a>
                    <img
                      src={obj.src}
                      alt={obj.SubjectName}
                      onClick={() => handleSelectedSubjectName(obj.SubjectName)}
                      className={
                        selectedSubjectName === obj.SubjectName
                          ? "subject-active-image cursor-pointer p-2"
                          : "cursor-pointer"
                      }
                    />
                  </a>
                  <span style={{ marginTop: "8px" }} className="fs-4">
                    <b>{obj.SubjectName}</b>
                  </span>
                </>
              </div>
            ))}
          </div>
          <div
            id="mob-banner"
            className="offeredOption-container languagesOffered d-none"
          >
            <div className="offeredOption">
              {languageOfferedItems.map((obj, index) => (
                <div className="languages-image-container">
                  {
                    <div key={index}>
                      <a>
                        <img
                          src={obj.src}
                          alt={obj.SubjectName}
                          onClick={() =>
                            handleSelectedSubjectName(obj.SubjectName)
                          }
                          className={
                            selectedSubjectName === obj.SubjectName
                              ? "subject-active-image cursor-pointer p-2"
                              : "cursor-pointer"
                          }
                        />
                      </a>
                      <span style={{ marginTop: "8px" }}>
                        <b>{obj.SubjectName}</b>
                      </span>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>

        <Dailogue
          id={"languageOfferedModalCenter"}
          body={getDailogueBody}
          title=""
        />
      </>
    );
  }

  return <>{getLanguageOffered()}</>;
}
export default LanguageOffered;

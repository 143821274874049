function BestTutors() {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  const onBookAFreeDemo = () => {
    window.location.href = "/#planPricingSection";
  };

  return (
    <>
      <div className="best-tutor-banner-card">
        <div id="web-banner">
          <img src="/assest/images/redCurve-2.png" className="w-100"></img>
          <div className="best-tutor-banner-text">
            {countryName == "India" ? (
              <>
                <h4>Revolutionize Your English</h4>
                <h4>Learning Experience with</h4>
                <h4>Cutting-Edge AI Technology!</h4>
                <br></br>
              </>
            ) : (
              <>
                <h4>Accelerate Learning: AI Powered</h4>
                <h4>High Impact Tutoring in</h4>
                <h4>English, Math & Science </h4>
                <br></br>
              </>
            )}

            <button className="btn btn-bestTutor" onClick={onBookAFreeDemo}>
              Book a Free Demo
            </button>
          </div>
        </div>
        <div id="mob-banner" className="d-none">
          <div className="row mb-3">
            <img
              src="/assest/images/mobile/home-banner-mobile.png"
              className="w-100"
            ></img>
          </div>
          <div className="row text-center m-2 ">
            {countryName == "India" ? (
              <>
                <h1>Revolutionize Your English</h1>
                <h1>Learning Experience with</h1>
                <h1>Cutting-Edge AI Technology!</h1>
                <br></br>
              </>
            ) : (
              <>
                <h1>
                  Accelerate Learning: AI Powered High Impact Tutoring in
                  English, Math & Science{" "}
                </h1>
                <br></br>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div className="">
              <button className="btn btn-bestTutor" onClick={onBookAFreeDemo}>
                Book a Free Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BestTutors;

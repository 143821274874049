import { useEffect, useState } from "react";
import { Execute } from "../Services/APIService";
import { colorCode } from "../utils/colors";
import { tr } from "date-fns/locale";

function StudentTestimonial() {
  const [learningExperience, setLearningExperience] = useState([]);
  const userImgPath =
    "https://liveapi.learnfromnative.com/documents/User Photos/";
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  useEffect(() => {
    //oneOneOne Plan
    const oSelectFewFromMultipleTablesForLearningExperience = {
      ActionName: "LFN.GMst_SelectFewFromMultipleTablesForLearningExperience",
      ParameterJSON: '{"TeachingLanguageID ":"' + 1 + '"}',
      SessionDataJSON:
        '{"UserID":"","SessionID":"3","OnBehalfOfUserID":"0","CompanyID":"-1","SubscriberID":"-1","AppID":"PackageName","AppVersion":"1.0","AppPlatform":" Web ","IsDeveloper":"0","ServiceAccessToken":"BBADCACCBCCJDC - BBADCACCBCCJDC"}',
      OperationName: "Query",
    };

    Execute(oSelectFewFromMultipleTablesForLearningExperience).then(
      (response) => {
        const filteredStudentDetails = response.items.filter(
          (obj) => obj.DataTableName == "Student Details"
        );
        if (response.DataIsLoaded) {
          setLearningExperience(filteredStudentDetails);
        }
      }
    );
  }, []);

  const [showFullText, setShowFullText] = useState({});

  const getFullTestimonial = (index) => {
    setShowFullText((prevShowFullText) => ({
      ...prevShowFullText,
      [index]: !prevShowFullText[index],
    }));
  };

  return (
    <>
      <div className="studentTestimonial m-5">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading">Student's testimonial</h1>
            <br></br>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              id="testimonial-carousel"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {learningExperience.map((obj, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <div className="testimonial w-100">
                      <div className="row d-flex align-items-center">
                        <div className="col-md-3 text-center mb-md-0 mb-3">
                          <img
                            src={userImgPath + obj.StudentID + ".png"}
                            style={{
                              width: "200px",
                              borderRadius: "50%",
                              border: `10px solid ${
                                colorCode[index % colorCode.length]
                              }`,
                              height: "200px",
                            }}
                          ></img>
                        </div>
                        <div className="col-12 col-md-9 student-testimonial-content">
                          <h3 className="mb-3">{obj.UserName}</h3>
                          {screenWidth < 768 ? (
                            showFullText[index] ? (
                              <p>{obj.Testimonial}</p>
                            ) : (
                              <p>
                                {obj.Testimonial.substring(0, 100) + "...  "}
                                <a
                                  className="readMore"
                                  onClick={() => getFullTestimonial(index)}
                                >
                                  Read More
                                </a>
                              </p>
                            )
                          ) : (
                            <p>{obj.Testimonial}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <br></br>
              <br></br>
              <ol className="carousel-indicators ">
                {learningExperience.map((obj, index) => (
                  <li
                    data-bs-target="#testimonial-carousel"
                    data-bs-slide-to={index}
                    className={`${index === 0 ? "active" : ""}`}
                    key={index}
                  ></li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentTestimonial;

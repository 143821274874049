import { Modal } from "@fluentui/react";
import Dailogue from "../Controls/Modal";
import { useRef } from "react";

function HighImpactTutoring(props) {
  const rowBackgroundColors = [
    "#FFB802",
    "#FF8B00",
    "#117800",
    "#8AA4FF",
    "#FF5858",
  ];

  var highImpactTutoringItems = [];

  highImpactTutoringItems = [
    {
      elementOfHITTitle: "Frequent Sessions",
      elementOFHITContent:
        "Our program offers three or more weekly sessions with a teaching time of a minimum of 90 minutes each week for effective learning.",
    },
    {
      elementOfHITTitle: "Individualized Attention",
      elementOFHITContent:
        "We maintain a 1:1 tutor-student ratio for personalized support.",
    },
    {
      elementOfHITTitle: "Experienced Tutors",
      elementOFHITContent:
        "Our diverse tutors are well-trained to meet each student's needs.",
    },
    {
      elementOfHITTitle: "Focus",
      elementOFHITContent:
        "We specialize in K-8 group to have their foundation strong in English, Math & Science",
    },
    {
      elementOfHITTitle: "Data-Driven Approach",
      elementOFHITContent:
        "We use data to track progress and improve teaching methods.",
    },
    {
      elementOfHITTitle: "Building Relationships",
      elementOFHITContent:
        "Consistent tutor partnerships create a supportive environment.",
    },
    {
      elementOfHITTitle: "Curriculum Alignment",
      elementOFHITContent:
        "Our curriculum is customized, uses high quality teaching material complements classroom learning",
    },
    {
      elementOfHITTitle: "Flexible Virtual Tutoring",
      elementOFHITContent:
        "We offer digital options to suit diverse student needs.",
    },
  ];

  return (
    <>
      <div className="highTutoringImpact" id="highTutoringImpact">
        <div className="highTutoringImpact-content">
          <div className="row">
            <div className="col-12">
              <h1 className="mb-4">High Impact Tutoring</h1>
              <p>
                Our high-impact tutoring program is designed around a set of key
                principles that have been proven to maximize learning outcomes
                for students. By adhering to these principles, we ensure a
                highly effective and tailored learning experience.
              </p>
            </div>
          </div>
        </div>
        <div className="elementOfHIT">
          <h2 className="mt-4 mb-4 fw-bold">
            The Element of High-Impact Tutoring
          </h2>
          {highImpactTutoringItems.map((obj, index) => (
            <>
              <div className="row mt-3 mb-3">
                <div
                  className="elementOfHIT-Title col-12 col-md-3 d-flex justify-content-center align-items-center p-4"
                  style={{
                    backgroundColor:
                      rowBackgroundColors[index % rowBackgroundColors.length],
                  }}
                >
                  <h4 className="fw-bold">{obj.elementOfHITTitle}</h4>
                </div>
                <div className="col-12 col-md-1 d-flex flex-column align-items-center">
                  <div className="mt-5 d-none d-md-block">
                    <img
                      src="/assest/images/icons/rightcurvearrow.png"
                      className="img-fluid"
                      alt="Right Arrow"
                    />
                  </div>
                  <div className="d-md-none d-block">
                    <img
                      src="/assest/images/icons/downcurvearrow.png"
                      className="img-fluid"
                      alt="Right Arrow"
                    />
                  </div>
                </div>
                <div className="elementOfHIT-Title-Content col-12 col-md-8 d-flex  align-items-center p-md-4 p-3">
                  <p>{obj.elementOFHITContent}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
export default HighImpactTutoring;

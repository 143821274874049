import { useEffect, useState } from "react";
import SocialMedia from "./SocialMedia";

function Footers() {
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const isTop = scrollTop < 300;
      setShowElement(!isTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="footer" id="AboutUs">
        <div className="text-lg-start footer-about">
          <div className="p-4 text-center">
            <h3>About</h3>
          </div>
          <div className="">
            <div className="text-left text-md-start m-3">
              <div className="row mt-3">
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">
                    Subscribe to Our Blogs
                  </h6>
                  <input
                    type="text"
                    className="form-control subscribeToOurBlogs"
                    placeholder="Enter your email"
                  />
                  <br />
                  <button className="btn btn-subscribeToOurBlogs-Submit">
                    Submit
                  </button>
                </div>

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">Support</h6>
                  <p>
                    <a>India- +91 8058058089</a>
                  </p>
                  <p>
                    <a>US - +1 (865) 978-73133</a>
                  </p>
                  <p>
                    <a href="mailto:connect@learnfromnative.com">
                      connect@learnfromnative.com
                    </a>
                  </p>
                  <p>
                    <a>Mon to Sat, 10AM - 7PM</a>
                  </p>
                </div>

                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">About Us</h6>
                  <p>
                    <a href="https://info.learnfromnative.com/paymentpolicy/">
                      Cancellation
                    </a>
                  </p>
                  <p>
                    <a href="https://info.learnfromnative.com/tutortncpolicy/">
                      Terms and Condition
                    </a>
                  </p>
                  <p>
                    <a href="https://info.learnfromnative.com/tncpolicy/">
                      Privacy Policy
                    </a>
                  </p>
                  <p>
                    <a href="https://info.learnfromnative.com/paymentpolicy/">
                      Refund
                    </a>
                  </p>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-4 mb-4">
                  <h6 className="text-uppercase fw-bold mb-4">Address</h6>
                  <p>
                    US Headquarters - 1200 Western Avenue, Seattle, WA 98101
                  </p>
                  <p>
                    India Corporate - #250, 2nd Floor, Vision Flora, Pimple
                    Saudagar, Pune, MH
                  </p>
                  <p>
                    India Tech Center - Office #1, 1st Floor, CIII Tower, Inside
                    SMJC Campus, Hadapsar, Pune 411 028, MH
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialMedia />
        {showElement && (
          <div className="downloadAppCard d-md-none fixed-bottom mb-0">
            <a href="/FreeDemoClassBooking">Book a Free Demo Class Now!</a>
          </div>
        )}
      </div>
    </>
  );
}

export default Footers;

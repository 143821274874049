// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCe2hLMq9zqUr-92WoHhUHPCDbvPw26jrg",
  authDomain: "lfn---app.firebaseapp.com",
  projectId: "lfn---app",
  storageBucket: "lfn---app.appspot.com",
  messagingSenderId: "780899848872",
  appId: "1:780899848872:web:15282180cbd8d86d26e89d",
  measurementId: "G-T6J8WSVMKE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

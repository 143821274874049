import { useEffect, useState } from "react";
import BookAFreeDemo from "../component/BookAFreeDemo";
import Footer from "../component/Footer";
import Footers from "../component/Footer";
import FrequentlyAskQuestion from "../component/FrequentlyAskQuestion";
import Header from "../component/Header";
import HowLearnFromNativeWorksUSA from "../component/HowLearnFromNativeWorksUSA";
import LearnFromNativeUnique from "../component/WhatMakesLearnFromNativeUnique";
import PlanPricing from "../component/PlanPricing";
import SocialMedia from "../component/SocialMedia";
import StudentTestimonial from "../component/StudentTestimonial";
import React from "react";
import WhatDoWeHaveToOfferUSA from "../component/WhatDoWeHaveToOfferUSA";
import HowLearnFromNativeWorks from "../component/HowLearnFromNativeWorksNew";
import KidsCountController from "../component/KidsCountController";
import KidsUSAContent from "../component/KidsUSAContent";
import KidsIndiaContent from "../component/KidsIndiaContent";
import WhatDoWeHaveToOffer from "../component/WhatDoWeHaveToOffer";
import HighImpactTutoring from "../component/HighImpactTutoring";

function Kids() {
  var sessionData = localStorage.getItem("items");
  var sessionDetails = JSON.parse(sessionData);

  if (sessionDetails != null || sessionDetails != undefined) {
    var countryName = sessionDetails.CountryName;
  }

  return (
    <>
      <Header active="Kids" />
      {countryName == "India" ? <KidsIndiaContent /> : <KidsUSAContent />}
      {countryName == "India" ? (
        <WhatDoWeHaveToOffer />
      ) : (
        <WhatDoWeHaveToOfferUSA />
      )}
      <PlanPricing />
      <HowLearnFromNativeWorks />
      <br />
      {countryName != "India" && <HighImpactTutoring />}
      <BookAFreeDemo />
      <FrequentlyAskQuestion />
      <Footer />
    </>
  );
}
export default Kids;

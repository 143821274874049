import React, { useEffect, useState } from "react"
import KidsCountController from "./KidsCountController"

function KidsUSAContent()
{
    const [squareOrder, setSquareOrder] = useState([1, 2, 3, 4]);
    const [activeSquare, setActiveSquare] = useState(1);
    
    const [squares,setSquares] = useState
    ([
        { id: 1, title: 'Soft Skills Development', content: "Our courses go beyond subject proficiency, fostering communication, critical thinking, creativity, and collaboration skills in children. " ,img:'/assest/images/SoftSkillDevelopment-top.png'},
        { id: 2, title: 'Gamified Engaging Chapters', content: "Interactive adventures with games, quizzes, and exercises capture children's interest and maintain their motivation to explore new topics." ,img:'/assest/images/GamifiedEngagingChapters.png'},
        { id: 3, title: 'Progress Tracking & Assessments:', content: "Regular assessments and progress tracking enable parents to monitor their child's growth, ensuring personalized attention and tailored instruction." ,img:'/assest/images/ProgressTrackingAndAssessments.png'},
        { id: 4, title: 'Play-way Method', content: 'Learning through play and hands-on activities keeps kids actively engaged, making education a joyful experience.',img:'/assest/images/PlayWayMethod.png' },
    ]);

    useEffect(() => 
    {
      const interval = setInterval(() => {
        rotateClassNames();
      }, 2000);

      return () => clearInterval(interval);
    }, []);

    const rotateClassNames = () => 
    {
        setSquares((prevSquares) => {
            const newSquares = [...prevSquares];
            const lastSquare = newSquares.pop();
            newSquares.unshift(lastSquare);
            
            return newSquares;
        });

    };

    const onBookAFreeDemo=()=>
    {
        window.location.href='/#planPricingSection'
    }
    
    
    return(
    <div className="kids">
            <div className="UsKids kids-banner-card mb-5 best-tutor-banner-card">
                <div id="web-banner">
                    <img src="/assest/images/kids-banner.png" className="w-100 h-100"></img>
                    <div className="best-tutor-banner-text">
                        <div className="kids-text">
                            <h4>High Impact Tutoring:</h4>
                            <h4>Building  AI Powered Brilliance </h4>
                            <h4>in English, Math & Science</h4>
                        </div>
                        <button className="btn btn-bestTutor w-75" onClick={onBookAFreeDemo}>Book a Free Demo</button>
                    </div>
                </div>
                
                <div id="mob-banner" className="d-none">
                    <div className="row mb-3">
                        <img src="/assest/images/mobile/kids-banner-mobile.png" className="w-100"></img>
                    </div>
                    <div className="row text-center mb-2">
                            <h1>High Impact Tutoring:</h1>
                            <h1>Building  AI Powered Brilliance</h1>
                            <h1>in English, Math & Science</h1>
                    </div>
                    <div className="d-flex justify-content-center">
                    <div className="">
                        <button className="btn btn-bestTutor" onClick={onBookAFreeDemo}>Book a Free Demo</button>
                    </div>
                </div>
                </div>
             </div>
            
           
            <KidsCountController/>

            {/* <div className="kids-offer">
                    <div className="kids-offer-content row mb-md-5">
                        <div className="col-12">
                            <h1 className="heading">What do we have to offer?</h1>
                            <p>Our comprehensive range of courses are designed to elevate your overall reading comprehension skills, leading to improvement in your lexical scores. Whether you choose individual courses or a tailored combination, our flexible options allow you to meet your unique needs and aspirations.</p>
                        </div>
                    </div>
                   
               
                    <div className="kids-offer-content">
                    <div className="row">
                        <div className="col-12 col-md-5 d-flex justify-content-center order-1 order-md-2">
                                
                            <img src="/assest/images/kids-us-offer-1.png" id="web-banner"></img>
                            <img src="/assest/images/mobile/kids-us-offer-1.png" id="mob-banner" className="d-none"></img>
    
                         </div>
                        <div className="col-12 col-md-7 order-2 order-md-1">
                            <h2 className="heading">Vocabulary Builder:</h2>
                            <p>
                            Expand your word bank, enhance your language proficiency, and strengthen your lexical scores through our engaging vocabulary course. Our course includes:
                                <br/>
                               
                                <ul className="kids-offer-list">
                                    <li>Context Clues</li>
                                    <li>Fictional & Non-Fictional Texts</li>
                                    <li>Fables</li>
                                    <li>Fun Stories and more...</li>
                                    
                                </ul>
                            </p>
                        </div>
                        
                        
                    </div>
                    <br/>
                   
                    <div className="row">
                        <div className="col-12 col-md-5 d-flex justify-content-center order-1 order-md-1">
                                
                            <img src="/assest/images/kids-us-offer-2.png" id="web-banner"></img>
                            <img src="/assest/images/mobile/kids-us-offer-2.png" id="mob-banner" className="d-none"></img>
            
                        </div>
                        <div className="col-12 col-md-7 order-2 order-md-2">
                            <h2 className="heading">Pronunciation Pro</h2>
                            <p>
                            Develop critical thinking, enhance digital literacy, and sharpen your understanding of media with our media literacy course.
                                <ul className="kids-offer-list">
                                    <li>Poems</li>
                                    <li>Tongue Twisters</li>
                                    <li>Phonics and Word Recognition</li>
                                    <li>Intonation, Rhythm and more...</li>
                                </ul>
                            </p>
                        </div>
                        
                    </div>
                    <br/>
                  
                    <div className="row">
                            <div className="col-12 col-md-5 d-flex justify-content-center order-1 order-md-2">
                                
                                <img src="/assest/images/kids-us-offer-3.png" id="web-banner"></img>
                                <img src="/assest/images/mobile/kids-offer-3.png" id="mob-banner" className="d-none"></img>
                
                            </div>
                        <div className="col-12 col-md-7 order-2 order-md-1">

                            <h2 className="heading">Media Literacy</h2>
                            <p>
                                Develop critical thinking, enhance digital literacy, and sharpen your understanding of media with our media literacy course.
                                <ul className="kids-offer-list">
                                    <li>Advertisements</li>
                                    <li>Newspaper Articles</li>
                                    <li>Billboards</li>
                                    <li>Flyers & Brochures, and more...</li>
                                </ul>
                            </p>
                        </div>
                        
                        
                        
                    </div>
                    <br/>
                   
                    <div className="row">
                        <div className="col-12 col-md-5 d-flex justify-content-center order-1 order-md-1">
                                
                                <img src="/assest/images/kids-us-offer-4.png" id="web-banner"></img>
                                <img src="/assest/images/mobile/kids-offer-4.png" id="mob-banner" className="d-none"></img>
                
                        </div>
                        <div className="col-12 col-md-7 order-2 order-md-2">
                            <h2 className="heading">Text Structure Unveiled</h2>
                            <p>
                            Dive into the world of multiple genre and structure of text, unravel the secrets of plot, characters, settings, and enhance your reading comprehension with our captivating story elements course.
                                <ul className="kids-offer-list">
                                    <li>Expository Texts</li>
                                    <li>Comparative Passages</li>
                                    <li>Descriptive Passages</li>
                                    <li>Problem Solution Passages and more...</li>
                                </ul>
                            </p>
                        </div>
                    </div>
              
                    <div className="row">
                    <div className="col-12 col-md-5 d-flex justify-content-center order-1 order-md-2">
                                
                                <img src="/assest/images/kids-us-offer-5.png" id="web-banner"></img>
                                <img src="/assest/images/mobile/kids-us-offer-5.png" id="mob-banner" className="d-none"></img>
                
                        </div>
                        <div className="col-12 col-md-7 order-2 order-md-1">
                            <h2 className="heading">Lexical Score Booster</h2>
                            <p>
                                Experience a holistic approach to improving your lexile score, focusing on all aspects of reading, comprehension, vocabulary, pronunciation, and more in our comprehensive lexical score improvement course.
                                <ul className="kids-offer-list">
                                    <li>Literary Texts</li>
                                    <li>Historical Passages</li>
                                    <li>Academic Articles</li>
                                    <li>Critical Analysis and Opinion Pieces</li>
                                </ul>
                            </p>
                        </div>
                        
                        
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                            <button className="btn btn-bestTutor" onClick={onBookAFreeDemo}>Book a Free Demo</button>
                    </div>
                    </div>
                    
                    
            </div> */}
            <div className="kids-offer ">
                    <div className="kids-offer-content row ">
                        <div className="col-12">
                            <h1 className="heading">Why Learn From Native? </h1>
                            <p>At Learn From Native, our child-centered approach to courses in various subjects for kids ensures an engaging and personalized learning experience. Here are the key features that make our courses stand out:</p>
                        </div>
                    </div>
                <div className="circle-container" id='web-banner'>
                {squareOrder.map((className) => (
                    <div key={className} className={`square square-${className} text-center ${className === activeSquare ? 'animate' : ''}`}>
                    <div className="circular-image-container">
                        <img src={squares[className - 1].img} alt={`Icon ${className}`} />
                    </div>
                    <h3 className="heading">{squares[className - 1].title}</h3>
                    <p>{squares[className - 1].content}</p>
                    </div>
                ))}
                </div>

                <div id='mob-banner' className="d-none">
                    {squares.map((square, index) => (
                    <React.Fragment key={index}>
                    <div className="row">
                        <div className="col-10 mx-auto">
                        <div className="square text-center">
                            <div className="circular-image-container">
                            <img src={square.img} alt={`Icon ${index + 1}`} />
                            </div>
                            <h3 className="heading">{square.title}</h3>
                            <p>{square.content}</p>
                        </div>
                        </div>
                    </div>
                    {index !== squares.length - 1 && ( // Add the dotted line between rows except for the last row
                        <div className="row">
                        <div className="col-10 mx-auto d-flex align-items-center justify-content-center">
                            <div className="dotted-line"></div> {/* Dotted line between rows */}
                        </div>
                        </div>
                    )}
                    </React.Fragment>
                ))}
                </div>
            </div>


            
        </div>

    )
}

export default KidsUSAContent